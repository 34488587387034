/* tslint:disable */
/* eslint-disable */
/**
 * Atlantis API
 * Atlantis Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcknowledgeSectionStepRequest
 */
export interface AcknowledgeSectionStepRequest {
    /**
     * 
     * @type {string}
     * @memberof AcknowledgeSectionStepRequest
     */
    'stepId': string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': AddressCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrProvince': AddressStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipOrPostalCode': string;
}

export const AddressCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AddressCountryEnum = typeof AddressCountryEnum[keyof typeof AddressCountryEnum];
export const AddressStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AddressStateOrProvinceEnum = typeof AddressStateOrProvinceEnum[keyof typeof AddressStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AddressAnswer
 */
export interface AddressAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {Address}
     * @memberof AddressAnswer
     */
    'answer': Address;
}
/**
 * 
 * @export
 * @interface AddressAnswerAllOf
 */
export interface AddressAnswerAllOf {
    /**
     * 
     * @type {Address}
     * @memberof AddressAnswerAllOf
     */
    'answer'?: Address;
}
/**
 * 
 * @export
 * @interface AnswerApplicationQuestionRequest
 */
export interface AnswerApplicationQuestionRequest {
    /**
     * 
     * @type {string}
     * @memberof AnswerApplicationQuestionRequest
     */
    'questionId': string;
    /**
     * 
     * @type {Array<AnswerApplicationQuestionRequestAnswersInner>}
     * @memberof AnswerApplicationQuestionRequest
     */
    'answers': Array<AnswerApplicationQuestionRequestAnswersInner>;
}
/**
 * @type AnswerApplicationQuestionRequestAnswersInner
 * @export
 */
export type AnswerApplicationQuestionRequestAnswersInner = AddressAnswer | BooleanAnswer | DateAnswer | EmailAnswer | FieldArrayAnswer | ListAnswer | MoneyAnswer | NumberAnswer | PhoneAnswer | StringAnswer | UuidAnswer;

/**
 * 
 * @export
 * @interface ApplicationProgressResponse
 */
export interface ApplicationProgressResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProgressResponse
     */
    'borrowerId'?: string;
    /**
     * 
     * @type {Array<SectionProgressDto>}
     * @memberof ApplicationProgressResponse
     */
    'sectionProgresses'?: Array<SectionProgressDto>;
}
/**
 * 
 * @export
 * @interface ApplicationSectionDto
 */
export interface ApplicationSectionDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSectionDto
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationStep>}
     * @memberof ApplicationSectionDto
     */
    'questionSteps': Array<ApplicationStep>;
}
/**
 * 
 * @export
 * @interface ApplicationStep
 */
export interface ApplicationStep {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationStep
     */
    'skippable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationStep
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStep
     */
    'sectionName'?: string;
    /**
     * 
     * @type {ChecklistTaskResponseContent}
     * @memberof ApplicationStep
     */
    'content'?: ChecklistTaskResponseContent;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStep
     */
    'checklistItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStep
     */
    'assignee'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationStep
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplyLoan
 */
export interface ApplyLoan {
    /**
     * 
     * @type {string}
     * @memberof ApplyLoan
     * @deprecated
     */
    'borrowerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyLoan
     */
    'loanType': ApplyLoanLoanTypeEnum;
}

export const ApplyLoanLoanTypeEnum = {
    Purchase: 'PURCHASE',
    Refinance: 'REFINANCE'
} as const;

export type ApplyLoanLoanTypeEnum = typeof ApplyLoanLoanTypeEnum[keyof typeof ApplyLoanLoanTypeEnum];

/**
 * 
 * @export
 * @interface Assignor
 */
export interface Assignor {
    /**
     * 
     * @type {string}
     * @memberof Assignor
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignor
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignor
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizedPersonDetailDto
 */
export interface AuthorizedPersonDetailDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'type': AuthorizedPersonDetailDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'nmlsNumber'?: string;
    /**
     * 
     * @type {Array<LicenseDto>}
     * @memberof AuthorizedPersonDetailDto
     */
    'licenses'?: Array<LicenseDto>;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizedPersonDetailDto
     */
    'defaultOfficer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDetailDto
     */
    'avatar'?: string;
}

export const AuthorizedPersonDetailDtoTypeEnum = {
    LoanOfficer: 'LOAN_OFFICER',
    LoanOfficerAssistant: 'LOAN_OFFICER_ASSISTANT',
    LoanProcessor: 'LOAN_PROCESSOR',
    RealOriginateLoanOfficer: 'REAL_ORIGINATE_LOAN_OFFICER'
} as const;

export type AuthorizedPersonDetailDtoTypeEnum = typeof AuthorizedPersonDetailDtoTypeEnum[keyof typeof AuthorizedPersonDetailDtoTypeEnum];

/**
 * 
 * @export
 * @interface AuthorizedPersonDto
 */
export interface AuthorizedPersonDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'type': AuthorizedPersonDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizedPersonDto
     */
    'title'?: string;
}

export const AuthorizedPersonDtoTypeEnum = {
    LoanOfficer: 'LOAN_OFFICER',
    LoanOfficerAssistant: 'LOAN_OFFICER_ASSISTANT',
    LoanProcessor: 'LOAN_PROCESSOR',
    RealOriginateLoanOfficer: 'REAL_ORIGINATE_LOAN_OFFICER'
} as const;

export type AuthorizedPersonDtoTypeEnum = typeof AuthorizedPersonDtoTypeEnum[keyof typeof AuthorizedPersonDtoTypeEnum];

/**
 * 
 * @export
 * @interface AuthorizedPersonIdRequest
 */
export interface AuthorizedPersonIdRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorizedPersonIdRequest
     */
    'authorizedPersonIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BankCustomerAccount
 */
export interface BankCustomerAccount {
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'realAccountNumberLast4'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'institutionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'balanceDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'createdDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'lastUpdatedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'marketSegment'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankCustomerAccount
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'lastTransactionDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'oldestTransactionDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankCustomerAccount
     */
    'institutionLoginId'?: number;
}
/**
 * 
 * @export
 * @interface BankCustomerAccounts
 */
export interface BankCustomerAccounts {
    /**
     * 
     * @type {Array<BankCustomerAccount>}
     * @memberof BankCustomerAccounts
     */
    'accounts'?: Array<BankCustomerAccount>;
}
/**
 * 
 * @export
 * @interface BankProfileAddress
 */
export interface BankProfileAddress {
    /**
     * 
     * @type {string}
     * @memberof BankProfileAddress
     */
    'country': BankProfileAddressCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof BankProfileAddress
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileAddress
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileAddress
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileAddress
     */
    'stateOrProvince': BankProfileAddressStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof BankProfileAddress
     */
    'zipOrPostalCode': string;
}

export const BankProfileAddressCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type BankProfileAddressCountryEnum = typeof BankProfileAddressCountryEnum[keyof typeof BankProfileAddressCountryEnum];
export const BankProfileAddressStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type BankProfileAddressStateOrProvinceEnum = typeof BankProfileAddressStateOrProvinceEnum[keyof typeof BankProfileAddressStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface BankProfileDto
 */
export interface BankProfileDto {
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'consumerId': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'borrowerId': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'type': BankProfileDtoTypeEnum;
    /**
     * 
     * @type {EmailAddress}
     * @memberof BankProfileDto
     */
    'emailAddress': EmailAddress;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof BankProfileDto
     */
    'phoneNumber': PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof BankProfileDto
     */
    'status': BankProfileDtoStatusEnum;
    /**
     * 
     * @type {BankProfileAddress}
     * @memberof BankProfileDto
     */
    'address': BankProfileAddress;
}

export const BankProfileDtoTypeEnum = {
    Active: 'ACTIVE',
    Testing: 'TESTING'
} as const;

export type BankProfileDtoTypeEnum = typeof BankProfileDtoTypeEnum[keyof typeof BankProfileDtoTypeEnum];
export const BankProfileDtoStatusEnum = {
    Active: 'ACTIVE',
    Deleted: 'DELETED'
} as const;

export type BankProfileDtoStatusEnum = typeof BankProfileDtoStatusEnum[keyof typeof BankProfileDtoStatusEnum];

/**
 * 
 * @export
 * @interface BankReportDto
 */
export interface BankReportDto {
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'type': BankReportDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'status': BankReportDtoStatusEnum;
    /**
     * 
     * @type {BankProfileDto}
     * @memberof BankReportDto
     */
    'profile': BankProfileDto;
    /**
     * 
     * @type {BorrowerDto}
     * @memberof BankReportDto
     */
    'borrowerDto': BorrowerDto;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'jsonReportLocation': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'pdfReportLocation': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'signedJsonReportLocation': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'signedPdfReportLocation': string;
    /**
     * 
     * @type {number}
     * @memberof BankReportDto
     */
    'createdAt': number;
    /**
     * 
     * @type {string}
     * @memberof BankReportDto
     */
    'createdBy': string;
}

export const BankReportDtoTypeEnum = {
    Voa: 'VOA',
    Voai: 'VOAI',
    VoieTx: 'VOIE_TX'
} as const;

export type BankReportDtoTypeEnum = typeof BankReportDtoTypeEnum[keyof typeof BankReportDtoTypeEnum];
export const BankReportDtoStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Failure: 'FAILURE',
    Success: 'SUCCESS'
} as const;

export type BankReportDtoStatusEnum = typeof BankReportDtoStatusEnum[keyof typeof BankReportDtoStatusEnum];

/**
 * 
 * @export
 * @interface BankReportId
 */
export interface BankReportId {
    /**
     * 
     * @type {string}
     * @memberof BankReportId
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface BankReportSummariesDto
 */
export interface BankReportSummariesDto {
    /**
     * 
     * @type {Array<BankReportSummaryDto>}
     * @memberof BankReportSummariesDto
     */
    'list'?: Array<BankReportSummaryDto>;
}
/**
 * 
 * @export
 * @interface BankReportSummaryDto
 */
export interface BankReportSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof BankReportSummaryDto
     */
    'reportId': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportSummaryDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportSummaryDto
     */
    'consumerId': string;
    /**
     * 
     * @type {string}
     * @memberof BankReportSummaryDto
     */
    'status': BankReportSummaryDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BankReportSummaryDto
     */
    'type': BankReportSummaryDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BankReportSummaryDto
     */
    'createdAt': number;
}

export const BankReportSummaryDtoStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Failure: 'FAILURE',
    Success: 'SUCCESS'
} as const;

export type BankReportSummaryDtoStatusEnum = typeof BankReportSummaryDtoStatusEnum[keyof typeof BankReportSummaryDtoStatusEnum];
export const BankReportSummaryDtoTypeEnum = {
    Voa: 'VOA',
    Voai: 'VOAI',
    VoieTx: 'VOIE_TX'
} as const;

export type BankReportSummaryDtoTypeEnum = typeof BankReportSummaryDtoTypeEnum[keyof typeof BankReportSummaryDtoTypeEnum];

/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BooleanAnswer
 */
export interface BooleanAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanAnswer
     */
    'answer': boolean;
}
/**
 * 
 * @export
 * @interface BooleanAnswerAllOf
 */
export interface BooleanAnswerAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanAnswerAllOf
     */
    'answer'?: boolean;
}
/**
 * 
 * @export
 * @interface BorrowerApplicationDto
 */
export interface BorrowerApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof BorrowerApplicationDto
     */
    'fullName': string;
    /**
     * 
     * @type {boolean}
     * @memberof BorrowerApplicationDto
     */
    'isPrimary'?: boolean;
    /**
     * 
     * @type {Array<ApplicationSectionDto>}
     * @memberof BorrowerApplicationDto
     */
    'sections': Array<ApplicationSectionDto>;
}
/**
 * 
 * @export
 * @interface BorrowerDto
 */
export interface BorrowerDto {
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'loanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'loanNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'loanOfficerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'loanOfficerUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'type': BorrowerDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'status': BorrowerDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'dropboxEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'checklistId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'roadToSuccessId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BorrowerDto
     */
    'preApprovalFileId'?: string;
}

export const BorrowerDtoTypeEnum = {
    Primary: 'PRIMARY',
    CoBorrower: 'CO_BORROWER'
} as const;

export type BorrowerDtoTypeEnum = typeof BorrowerDtoTypeEnum[keyof typeof BorrowerDtoTypeEnum];
export const BorrowerDtoStatusEnum = {
    AccountCreated: 'ACCOUNT_CREATED',
    ApplicationInProgress: 'APPLICATION_IN_PROGRESS',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    LoanPreApproved: 'LOAN_PRE_APPROVED',
    SubmittedToLender: 'SUBMITTED_TO_LENDER',
    Underwriting: 'UNDERWRITING',
    ConditionalApproval: 'CONDITIONAL_APPROVAL',
    ClearToClose: 'CLEAR_TO_CLOSE',
    Closing: 'CLOSING',
    Funding: 'FUNDING',
    Terminated: 'TERMINATED'
} as const;

export type BorrowerDtoStatusEnum = typeof BorrowerDtoStatusEnum[keyof typeof BorrowerDtoStatusEnum];

/**
 * 
 * @export
 * @interface BorrowerForCommentDto
 */
export interface BorrowerForCommentDto {
    /**
     * 
     * @type {BorrowerDto}
     * @memberof BorrowerForCommentDto
     */
    'borrower': BorrowerDto;
    /**
     * 
     * @type {LoanOfficerDto}
     * @memberof BorrowerForCommentDto
     */
    'loanOfficer': LoanOfficerDto;
}
/**
 * 
 * @export
 * @interface ButtonField
 */
export interface ButtonField {
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'id'?: string;
    /**
     * 
     * @type {Array<Validation>}
     * @memberof ButtonField
     */
    'validations'?: Array<Validation>;
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'type'?: ButtonFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ButtonField
     */
    'required'?: boolean;
    /**
     * 
     * @type {Help}
     * @memberof ButtonField
     */
    'help'?: Help;
    /**
     * 
     * @type {FieldStyle}
     * @memberof ButtonField
     */
    'fieldStyle'?: FieldStyle;
    /**
     * 
     * @type {boolean}
     * @memberof ButtonField
     */
    'answer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'preset'?: string;
    /**
     * 
     * @type {ConditionObject}
     * @memberof ButtonField
     */
    'condition'?: ConditionObject;
    /**
     * 
     * @type {boolean}
     * @memberof ButtonField
     */
    'encrypted'?: boolean;
    /**
     * 
     * @type {EncryptedBoolean}
     * @memberof ButtonField
     */
    'encryptedAnswer'?: EncryptedBoolean;
    /**
     * 
     * @type {number}
     * @memberof ButtonField
     */
    'answerVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'dataKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'alias'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ButtonField
     */
    'presetAnswer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ButtonField
     */
    'answerType'?: string;
}

export const ButtonFieldTypeEnum = {
    Binary: 'BINARY',
    Toggle: 'TOGGLE',
    Text: 'TEXT',
    Phone: 'PHONE',
    Email: 'EMAIL',
    Date: 'DATE',
    Address: 'ADDRESS',
    Money: 'MONEY',
    Button: 'BUTTON',
    SingleSelect: 'SINGLE_SELECT',
    MultiSelect: 'MULTI_SELECT',
    SelectPicker: 'SELECT_PICKER',
    FieldArray: 'FIELD_ARRAY',
    FileRequest: 'FILE_REQUEST',
    Number: 'NUMBER',
    UrlRequest: 'URL_REQUEST',
    SignatureRequest: 'SIGNATURE_REQUEST',
    StateOrProvinceRequest: 'STATE_OR_PROVINCE_REQUEST'
} as const;

export type ButtonFieldTypeEnum = typeof ButtonFieldTypeEnum[keyof typeof ButtonFieldTypeEnum];

/**
 * 
 * @export
 * @interface ChecklistTaskResponse
 */
export interface ChecklistTaskResponse {
    /**
     * 
     * @type {string}
     * @memberof ChecklistTaskResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistTaskResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistTaskResponse
     */
    'description'?: string;
    /**
     * 
     * @type {ChecklistTaskResponseContent}
     * @memberof ChecklistTaskResponse
     */
    'content'?: ChecklistTaskResponseContent;
    /**
     * 
     * @type {string}
     * @memberof ChecklistTaskResponse
     */
    'status'?: ChecklistTaskResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ChecklistTaskResponse
     */
    'completedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistTaskResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChecklistTaskResponse
     */
    'assignee'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistTaskResponse
     */
    'assignedAt'?: number;
    /**
     * 
     * @type {Assignor}
     * @memberof ChecklistTaskResponse
     */
    'assignor'?: Assignor;
    /**
     * 
     * @type {string}
     * @memberof ChecklistTaskResponse
     */
    'dueDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistTaskResponse
     */
    'required'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistTaskResponse
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {FileReferences}
     * @memberof ChecklistTaskResponse
     */
    'fileReferences'?: FileReferences;
}

export const ChecklistTaskResponseStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Pending: 'PENDING',
    WaitingForReview: 'WAITING_FOR_REVIEW',
    InReview: 'IN_REVIEW',
    RevisionRequested: 'REVISION_REQUESTED',
    Done: 'DONE',
    Accepted: 'ACCEPTED'
} as const;

export type ChecklistTaskResponseStatusEnum = typeof ChecklistTaskResponseStatusEnum[keyof typeof ChecklistTaskResponseStatusEnum];

/**
 * @type ChecklistTaskResponseContent
 * @export
 */
export type ChecklistTaskResponseContent = Question | SectionIntro | SectionOutro;

/**
 * 
 * @export
 * @interface ConditionObject
 */
export interface ConditionObject {
    /**
     * 
     * @type {string}
     * @memberof ConditionObject
     */
    'fieldId': string;
    /**
     * 
     * @type {any}
     * @memberof ConditionObject
     */
    'fieldAnswer'?: any;
    /**
     * 
     * @type {string}
     * @memberof ConditionObject
     */
    'answerType': string;
}
/**
 * 
 * @export
 * @interface ConsentDto
 */
export interface ConsentDto {
    /**
     * 
     * @type {string}
     * @memberof ConsentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConsentDto
     */
    'type': ConsentDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ConsentDto
     */
    'consentedAt': number;
    /**
     * 
     * @type {BorrowerDto}
     * @memberof ConsentDto
     */
    'borrowerDto': BorrowerDto;
    /**
     * 
     * @type {LoanDto}
     * @memberof ConsentDto
     */
    'loanDto': LoanDto;
}

export const ConsentDtoTypeEnum = {
    TermsConditions: 'TERMS_CONDITIONS',
    CreditIncomeEmpVerification: 'CREDIT_INCOME_EMP_VERIFICATION'
} as const;

export type ConsentDtoTypeEnum = typeof ConsentDtoTypeEnum[keyof typeof ConsentDtoTypeEnum];

/**
 * 
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'contentType'?: ContentContentTypeEnum;
}

export const ContentContentTypeEnum = {
    Question: 'QUESTION',
    SectionIntro: 'SECTION_INTRO',
    SectionOutro: 'SECTION_OUTRO'
} as const;

export type ContentContentTypeEnum = typeof ContentContentTypeEnum[keyof typeof ContentContentTypeEnum];

/**
 * 
 * @export
 * @interface CreateChecklistFileUploadQuestion
 */
export interface CreateChecklistFileUploadQuestion {
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistFileUploadQuestion
     */
    'borrowerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistFileUploadQuestion
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistFileUploadQuestion
     */
    'subtitle': string;
    /**
     * 
     * @type {QuestionAssignee}
     * @memberof CreateChecklistFileUploadQuestion
     */
    'assignee'?: QuestionAssignee;
}
/**
 * 
 * @export
 * @interface CreateLead
 */
export interface CreateLead {
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'state': CreateLeadStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'loanOfficerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLead
     */
    'agentIsMlor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLead
     */
    'source'?: string;
}

export const CreateLeadStateEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type CreateLeadStateEnum = typeof CreateLeadStateEnum[keyof typeof CreateLeadStateEnum];

/**
 * 
 * @export
 * @interface DateAnswer
 */
export interface DateAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {string}
     * @memberof DateAnswer
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface DateAnswerAllOf
 */
export interface DateAnswerAllOf {
    /**
     * 
     * @type {string}
     * @memberof DateAnswerAllOf
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface DefineChecklistFromFileRequest
 */
export interface DefineChecklistFromFileRequest {
    /**
     * 
     * @type {File}
     * @memberof DefineChecklistFromFileRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface EmailAnswer
 */
export interface EmailAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {EmailAddress}
     * @memberof EmailAnswer
     */
    'answer': EmailAddress;
}
/**
 * 
 * @export
 * @interface EmailAnswerAllOf
 */
export interface EmailAnswerAllOf {
    /**
     * 
     * @type {EmailAddress}
     * @memberof EmailAnswerAllOf
     */
    'answer'?: EmailAddress;
}
/**
 * 
 * @export
 * @interface EncryptedBoolean
 */
export interface EncryptedBoolean {
    /**
     * 
     * @type {Array<string>}
     * @memberof EncryptedBoolean
     */
    'content'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EncryptedObject
 */
export interface EncryptedObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof EncryptedObject
     */
    'content'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

export const FailedMessageClientTypeEnum = {
    Producer: 'PRODUCER',
    Consumer: 'CONSUMER'
} as const;

export type FailedMessageClientTypeEnum = typeof FailedMessageClientTypeEnum[keyof typeof FailedMessageClientTypeEnum];
export const FailedMessageStatusEnum = {
    Blocked: 'BLOCKED',
    Unprocessed: 'UNPROCESSED',
    Processed: 'PROCESSED',
    Failed: 'FAILED',
    ManualOverride: 'MANUAL_OVERRIDE',
    NoActionRequired: 'NO_ACTION_REQUIRED'
} as const;

export type FailedMessageStatusEnum = typeof FailedMessageStatusEnum[keyof typeof FailedMessageStatusEnum];

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

export const FailedMessageUpdateStatusRequestStatusEnum = {
    Blocked: 'BLOCKED',
    Unprocessed: 'UNPROCESSED',
    Processed: 'PROCESSED',
    Failed: 'FAILED',
    ManualOverride: 'MANUAL_OVERRIDE',
    NoActionRequired: 'NO_ACTION_REQUIRED'
} as const;

export type FailedMessageUpdateStatusRequestStatusEnum = typeof FailedMessageUpdateStatusRequestStatusEnum[keyof typeof FailedMessageUpdateStatusRequestStatusEnum];

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface FieldAnswerObject
 */
export interface FieldAnswerObject {
    /**
     * 
     * @type {string}
     * @memberof FieldAnswerObject
     */
    'fieldId': string;
    /**
     * 
     * @type {any}
     * @memberof FieldAnswerObject
     */
    'answer': any;
    /**
     * 
     * @type {string}
     * @memberof FieldAnswerObject
     */
    'answerType': string;
}
/**
 * 
 * @export
 * @interface FieldArrayAnswer
 */
export interface FieldArrayAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {Array<Array<FieldAnswerObject>>}
     * @memberof FieldArrayAnswer
     */
    'answer': Array<Array<FieldAnswerObject>>;
}
/**
 * 
 * @export
 * @interface FieldArrayAnswerAllOf
 */
export interface FieldArrayAnswerAllOf {
    /**
     * 
     * @type {Array<Array<FieldAnswerObject>>}
     * @memberof FieldArrayAnswerAllOf
     */
    'answer'?: Array<Array<FieldAnswerObject>>;
}
/**
 * 
 * @export
 * @interface FieldObject
 */
export interface FieldObject {
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'id'?: string;
    /**
     * 
     * @type {Array<Validation>}
     * @memberof FieldObject
     */
    'validations'?: Array<Validation>;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'type'?: FieldObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'label'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldObject
     */
    'required'?: boolean;
    /**
     * 
     * @type {Help}
     * @memberof FieldObject
     */
    'help'?: Help;
    /**
     * 
     * @type {FieldStyle}
     * @memberof FieldObject
     */
    'fieldStyle'?: FieldStyle;
    /**
     * 
     * @type {any}
     * @memberof FieldObject
     */
    'answer'?: any;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'preset'?: string;
    /**
     * 
     * @type {ConditionObject}
     * @memberof FieldObject
     */
    'condition'?: ConditionObject;
    /**
     * 
     * @type {boolean}
     * @memberof FieldObject
     */
    'encrypted'?: boolean;
    /**
     * 
     * @type {EncryptedObject}
     * @memberof FieldObject
     */
    'encryptedAnswer'?: EncryptedObject;
    /**
     * 
     * @type {number}
     * @memberof FieldObject
     */
    'answerVersion'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'dataKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    'answerType'?: string;
    /**
     * 
     * @type {any}
     * @memberof FieldObject
     */
    'presetAnswer'?: any;
    /**
     * 
     * @type {string}
     * @memberof FieldObject
     */
    '_fieldClass': string;
}

export const FieldObjectTypeEnum = {
    Binary: 'BINARY',
    Toggle: 'TOGGLE',
    Text: 'TEXT',
    Phone: 'PHONE',
    Email: 'EMAIL',
    Date: 'DATE',
    Address: 'ADDRESS',
    Money: 'MONEY',
    Button: 'BUTTON',
    SingleSelect: 'SINGLE_SELECT',
    MultiSelect: 'MULTI_SELECT',
    SelectPicker: 'SELECT_PICKER',
    FieldArray: 'FIELD_ARRAY',
    FileRequest: 'FILE_REQUEST',
    Number: 'NUMBER',
    UrlRequest: 'URL_REQUEST',
    SignatureRequest: 'SIGNATURE_REQUEST',
    StateOrProvinceRequest: 'STATE_OR_PROVINCE_REQUEST'
} as const;

export type FieldObjectTypeEnum = typeof FieldObjectTypeEnum[keyof typeof FieldObjectTypeEnum];

/**
 * 
 * @export
 * @interface FieldStyle
 */
export interface FieldStyle {
    /**
     * 
     * @type {string}
     * @memberof FieldStyle
     */
    'displayType'?: string;
}
/**
 * 
 * @export
 * @interface FileReference
 */
export interface FileReference {
    /**
     * 
     * @type {string}
     * @memberof FileReference
     */
    'fileId'?: string;
}
/**
 * 
 * @export
 * @interface FileReferences
 */
export interface FileReferences {
    /**
     * 
     * @type {Array<FileReference>}
     * @memberof FileReferences
     */
    'references'?: Array<FileReference>;
}
/**
 * 
 * @export
 * @interface GenerateBankReportRequest
 */
export interface GenerateBankReportRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateBankReportRequest
     */
    'bankReportType': GenerateBankReportRequestBankReportTypeEnum;
}

export const GenerateBankReportRequestBankReportTypeEnum = {
    Voa: 'VOA',
    Voai: 'VOAI',
    VoieTx: 'VOIE_TX'
} as const;

export type GenerateBankReportRequestBankReportTypeEnum = typeof GenerateBankReportRequestBankReportTypeEnum[keyof typeof GenerateBankReportRequestBankReportTypeEnum];

/**
 * 
 * @export
 * @interface GenerateBankReportResponse
 */
export interface GenerateBankReportResponse {
    /**
     * 
     * @type {BankReportId}
     * @memberof GenerateBankReportResponse
     */
    'id': BankReportId;
    /**
     * 
     * @type {string}
     * @memberof GenerateBankReportResponse
     */
    'reportType': GenerateBankReportResponseReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GenerateBankReportResponse
     */
    'status': GenerateBankReportResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GenerateBankReportResponse
     */
    'createdAt': number;
}

export const GenerateBankReportResponseReportTypeEnum = {
    Voa: 'VOA',
    Voai: 'VOAI',
    VoieTx: 'VOIE_TX'
} as const;

export type GenerateBankReportResponseReportTypeEnum = typeof GenerateBankReportResponseReportTypeEnum[keyof typeof GenerateBankReportResponseReportTypeEnum];
export const GenerateBankReportResponseStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Failure: 'FAILURE',
    Success: 'SUCCESS'
} as const;

export type GenerateBankReportResponseStatusEnum = typeof GenerateBankReportResponseStatusEnum[keyof typeof GenerateBankReportResponseStatusEnum];

/**
 * 
 * @export
 * @interface GenericSearchResponseConsentDto
 */
export interface GenericSearchResponseConsentDto {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConsentDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConsentDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseConsentDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConsentDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseConsentDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseConsentDto
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseConsentDto
     */
    'sortDirection'?: GenericSearchResponseConsentDtoSortDirectionEnum;
    /**
     * 
     * @type {Array<ConsentDto>}
     * @memberof GenericSearchResponseConsentDto
     */
    'results'?: Array<ConsentDto>;
}

export const GenericSearchResponseConsentDtoSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GenericSearchResponseConsentDtoSortDirectionEnum = typeof GenericSearchResponseConsentDtoSortDirectionEnum[keyof typeof GenericSearchResponseConsentDtoSortDirectionEnum];

/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

export const GenericSearchResponseFailedMessageSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GenericSearchResponseFailedMessageSortDirectionEnum = typeof GenericSearchResponseFailedMessageSortDirectionEnum[keyof typeof GenericSearchResponseFailedMessageSortDirectionEnum];

/**
 * 
 * @export
 * @interface Help
 */
export interface Help {
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Help
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface LeadDto
 */
export interface LeadDto {
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'state': LeadDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'loanOfficerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'realOriginateLoanOfficerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    'source'?: string;
}

export const LeadDtoStateEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type LeadDtoStateEnum = typeof LeadDtoStateEnum[keyof typeof LeadDtoStateEnum];

/**
 * 
 * @export
 * @interface LeadLoanDto
 */
export interface LeadLoanDto {
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadLoanDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadLoanDto
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'loanNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'clientName'?: string;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof LeadLoanDto
     */
    'phoneNumber'?: PhoneNumber;
    /**
     * 
     * @type {EmailAddress}
     * @memberof LeadLoanDto
     */
    'email'?: EmailAddress;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'agentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLoanDto
     */
    'status'?: LeadLoanDtoStatusEnum;
    /**
     * 
     * @type {AuthorizedPersonDto}
     * @memberof LeadLoanDto
     */
    'loanOfficer'?: AuthorizedPersonDto;
    /**
     * 
     * @type {AuthorizedPersonDto}
     * @memberof LeadLoanDto
     */
    'loanProcessor'?: AuthorizedPersonDto;
    /**
     * 
     * @type {AuthorizedPersonDto}
     * @memberof LeadLoanDto
     */
    'realOriginateLoanOfficer'?: AuthorizedPersonDto;
    /**
     * 
     * @type {AuthorizedPersonDto}
     * @memberof LeadLoanDto
     */
    'loanOfficerAssistant'?: AuthorizedPersonDto;
}

export const LeadLoanDtoStatusEnum = {
    Created: 'CREATED',
    InvitationSent: 'INVITATION_SENT',
    AccountCreated: 'ACCOUNT_CREATED',
    ApplicationInProgress: 'APPLICATION_IN_PROGRESS',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    LoanPreApproved: 'LOAN_PRE_APPROVED',
    SubmittedToLender: 'SUBMITTED_TO_LENDER',
    Underwriting: 'UNDERWRITING',
    ConditionalApproval: 'CONDITIONAL_APPROVAL',
    ClearToClose: 'CLEAR_TO_CLOSE',
    Closing: 'CLOSING',
    Funding: 'FUNDING'
} as const;

export type LeadLoanDtoStatusEnum = typeof LeadLoanDtoStatusEnum[keyof typeof LeadLoanDtoStatusEnum];

/**
 * 
 * @export
 * @interface LicenseDto
 */
export interface LicenseDto {
    /**
     * 
     * @type {string}
     * @memberof LicenseDto
     */
    'state': LicenseDtoStateEnum;
}

export const LicenseDtoStateEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type LicenseDtoStateEnum = typeof LicenseDtoStateEnum[keyof typeof LicenseDtoStateEnum];

/**
 * 
 * @export
 * @interface ListAnswer
 */
export interface ListAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {Array<any>}
     * @memberof ListAnswer
     */
    'answer': Array<any>;
}
/**
 * 
 * @export
 * @interface ListAnswerAllOf
 */
export interface ListAnswerAllOf {
    /**
     * 
     * @type {Array<any>}
     * @memberof ListAnswerAllOf
     */
    'answer'?: Array<any>;
}
/**
 * 
 * @export
 * @interface LoanDataDto
 */
export interface LoanDataDto {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof LoanDataDto
     */
    'data'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface LoanDto
 */
export interface LoanDto {
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'loanNumber': string;
    /**
     * 
     * @type {Array<BorrowerDto>}
     * @memberof LoanDto
     */
    'borrowers': Array<BorrowerDto>;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'type': LoanDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'loanType': LoanDtoLoanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'status': LoanDtoStatusEnum;
    /**
     * 
     * @type {UserDto}
     * @memberof LoanDto
     */
    'agent'?: UserDto;
    /**
     * 
     * @type {LoanOfficerDto}
     * @memberof LoanDto
     */
    'loanOfficer'?: LoanOfficerDto;
    /**
     * 
     * @type {Array<AuthorizedPersonDto>}
     * @memberof LoanDto
     */
    'authorizedPersons'?: Array<AuthorizedPersonDto>;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'preApprovalUploadedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    'preApprovalUploadedAt'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanDto
     */
    'nextValidStates'?: Array<LoanDtoNextValidStatesEnum>;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    'propertyAddress'?: string;
}

export const LoanDtoTypeEnum = {
    Conventional: 'CONVENTIONAL',
    Fha: 'FHA'
} as const;

export type LoanDtoTypeEnum = typeof LoanDtoTypeEnum[keyof typeof LoanDtoTypeEnum];
export const LoanDtoLoanTypeEnum = {
    Purchase: 'PURCHASE',
    Refinance: 'REFINANCE'
} as const;

export type LoanDtoLoanTypeEnum = typeof LoanDtoLoanTypeEnum[keyof typeof LoanDtoLoanTypeEnum];
export const LoanDtoStatusEnum = {
    AccountCreated: 'ACCOUNT_CREATED',
    ApplicationInProgress: 'APPLICATION_IN_PROGRESS',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    LoanPreApproved: 'LOAN_PRE_APPROVED',
    SubmittedToLender: 'SUBMITTED_TO_LENDER',
    Underwriting: 'UNDERWRITING',
    ConditionalApproval: 'CONDITIONAL_APPROVAL',
    ClearToClose: 'CLEAR_TO_CLOSE',
    Closing: 'CLOSING',
    Funding: 'FUNDING',
    Terminated: 'TERMINATED'
} as const;

export type LoanDtoStatusEnum = typeof LoanDtoStatusEnum[keyof typeof LoanDtoStatusEnum];
export const LoanDtoNextValidStatesEnum = {
    AccountCreated: 'ACCOUNT_CREATED',
    ApplicationInProgress: 'APPLICATION_IN_PROGRESS',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    LoanPreApproved: 'LOAN_PRE_APPROVED',
    SubmittedToLender: 'SUBMITTED_TO_LENDER',
    Underwriting: 'UNDERWRITING',
    ConditionalApproval: 'CONDITIONAL_APPROVAL',
    ClearToClose: 'CLEAR_TO_CLOSE',
    Closing: 'CLOSING',
    Funding: 'FUNDING',
    Terminated: 'TERMINATED'
} as const;

export type LoanDtoNextValidStatesEnum = typeof LoanDtoNextValidStatesEnum[keyof typeof LoanDtoNextValidStatesEnum];

/**
 * 
 * @export
 * @interface LoanOfficerDetailDto
 */
export interface LoanOfficerDetailDto {
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'nmlsNumber': string;
    /**
     * 
     * @type {Array<LicenseDto>}
     * @memberof LoanOfficerDetailDto
     */
    'licenses': Array<LicenseDto>;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanOfficerDetailDto
     */
    'defaultOfficer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDetailDto
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface LoanOfficerDto
 */
export interface LoanOfficerDto {
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'nmlsNumber': string;
    /**
     * 
     * @type {Array<LicenseDto>}
     * @memberof LoanOfficerDto
     */
    'licenses': Array<LicenseDto>;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanOfficerDto
     */
    'defaultOfficer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOfficerDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface LoanParticipantDto
 */
export interface LoanParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof LoanParticipantDto
     */
    'type': LoanParticipantDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanParticipantDto
     */
    'userId': string;
    /**
     * 
     * @type {Array<LoanDto>}
     * @memberof LoanParticipantDto
     */
    'loans'?: Array<LoanDto>;
    /**
     * 
     * @type {Array<BorrowerDto>}
     * @memberof LoanParticipantDto
     */
    'borrowers'?: Array<BorrowerDto>;
}

export const LoanParticipantDtoTypeEnum = {
    Agent: 'AGENT',
    Applicant: 'APPLICANT',
    Borrower: 'BORROWER',
    Buyer: 'BUYER',
    LoanOfficer: 'LOAN_OFFICER',
    LoanOfficerAssistant: 'LOAN_OFFICER_ASSISTANT',
    LoanProcessor: 'LOAN_PROCESSOR',
    RealOriginateLoanOfficer: 'REAL_ORIGINATE_LOAN_OFFICER',
    TransactionCoordinator: 'TRANSACTION_COORDINATOR'
} as const;

export type LoanParticipantDtoTypeEnum = typeof LoanParticipantDtoTypeEnum[keyof typeof LoanParticipantDtoTypeEnum];

/**
 * 
 * @export
 * @interface MoneyAnswer
 */
export interface MoneyAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {MoneyValue}
     * @memberof MoneyAnswer
     */
    'answer': MoneyValue;
}
/**
 * 
 * @export
 * @interface MoneyAnswerAllOf
 */
export interface MoneyAnswerAllOf {
    /**
     * 
     * @type {MoneyValue}
     * @memberof MoneyAnswerAllOf
     */
    'answer'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    'currency'?: MoneyValueCurrencyEnum;
}

export const MoneyValueCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type MoneyValueCurrencyEnum = typeof MoneyValueCurrencyEnum[keyof typeof MoneyValueCurrencyEnum];

/**
 * 
 * @export
 * @interface MortgageServiceAvailabilityByStateOrProvince
 */
export interface MortgageServiceAvailabilityByStateOrProvince {
    /**
     * 
     * @type {string}
     * @memberof MortgageServiceAvailabilityByStateOrProvince
     */
    'stateOrProvince': MortgageServiceAvailabilityByStateOrProvinceStateOrProvinceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageServiceAvailabilityByStateOrProvince
     */
    'available'?: boolean;
}

export const MortgageServiceAvailabilityByStateOrProvinceStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type MortgageServiceAvailabilityByStateOrProvinceStateOrProvinceEnum = typeof MortgageServiceAvailabilityByStateOrProvinceStateOrProvinceEnum[keyof typeof MortgageServiceAvailabilityByStateOrProvinceStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface MortgageServiceAvailabilityDto
 */
export interface MortgageServiceAvailabilityDto {
    /**
     * 
     * @type {Array<MortgageServiceAvailabilityByStateOrProvince>}
     * @memberof MortgageServiceAvailabilityDto
     */
    'serviceAvailabilities'?: Array<MortgageServiceAvailabilityByStateOrProvince>;
}
/**
 * 
 * @export
 * @interface NumberAnswer
 */
export interface NumberAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {number}
     * @memberof NumberAnswer
     */
    'answer': number;
}
/**
 * 
 * @export
 * @interface NumberAnswerAllOf
 */
export interface NumberAnswerAllOf {
    /**
     * 
     * @type {number}
     * @memberof NumberAnswerAllOf
     */
    'answer'?: number;
}
/**
 * 
 * @export
 * @interface PageLeadLoanDto
 */
export interface PageLeadLoanDto {
    /**
     * 
     * @type {number}
     * @memberof PageLeadLoanDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLeadLoanDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLeadLoanDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<LeadLoanDto>}
     * @memberof PageLeadLoanDto
     */
    'content'?: Array<LeadLoanDto>;
    /**
     * 
     * @type {number}
     * @memberof PageLeadLoanDto
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLeadLoanDto
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageLeadLoanDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLeadLoanDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLeadLoanDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLeadLoanDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLeadLoanDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchAuthorizedPersonRequest
 */
export interface PatchAuthorizedPersonRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchAuthorizedPersonRequest
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchAuthorizedPersonRequest
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchAuthorizedPersonRequest
     */
    'licensedInStates'?: Array<PatchAuthorizedPersonRequestLicensedInStatesEnum>;
}

export const PatchAuthorizedPersonRequestLicensedInStatesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type PatchAuthorizedPersonRequestLicensedInStatesEnum = typeof PatchAuthorizedPersonRequestLicensedInStatesEnum[keyof typeof PatchAuthorizedPersonRequestLicensedInStatesEnum];

/**
 * 
 * @export
 * @interface PatchLoanOfficerRequest
 */
export interface PatchLoanOfficerRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchLoanOfficerRequest
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchLoanOfficerRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface PhoneAnswer
 */
export interface PhoneAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {PhoneNumber}
     * @memberof PhoneAnswer
     */
    'answer': PhoneNumber;
}
/**
 * 
 * @export
 * @interface PhoneAnswerAllOf
 */
export interface PhoneAnswerAllOf {
    /**
     * 
     * @type {PhoneNumber}
     * @memberof PhoneAnswerAllOf
     */
    'answer'?: PhoneNumber;
}
/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question extends Content {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'subtitle'?: string;
    /**
     * 
     * @type {Style}
     * @memberof Question
     */
    'style'?: Style;
    /**
     * 
     * @type {Array<FieldObject>}
     * @memberof Question
     */
    'fields'?: Array<FieldObject>;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof Question
     */
    'segments'?: Array<Segment>;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'answered'?: boolean;
}


/**
 * 
 * @export
 * @interface QuestionAllOf
 */
export interface QuestionAllOf {
    /**
     * 
     * @type {string}
     * @memberof QuestionAllOf
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionAllOf
     */
    'subtitle'?: string;
    /**
     * 
     * @type {Style}
     * @memberof QuestionAllOf
     */
    'style'?: Style;
    /**
     * 
     * @type {Array<FieldObject>}
     * @memberof QuestionAllOf
     */
    'fields'?: Array<FieldObject>;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof QuestionAllOf
     */
    'segments'?: Array<Segment>;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionAllOf
     */
    'answered'?: boolean;
}
/**
 * 
 * @export
 * @interface QuestionAssignee
 */
export interface QuestionAssignee {
    /**
     * 
     * @type {string}
     * @memberof QuestionAssignee
     */
    'assigneeType': QuestionAssigneeAssigneeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionAssignee
     */
    'assigneeId': string;
}

export const QuestionAssigneeAssigneeTypeEnum = {
    Borrower: 'BORROWER'
} as const;

export type QuestionAssigneeAssigneeTypeEnum = typeof QuestionAssigneeAssigneeTypeEnum[keyof typeof QuestionAssigneeAssigneeTypeEnum];

/**
 * 
 * @export
 * @interface QuestionProgressDto
 */
export interface QuestionProgressDto {
    /**
     * 
     * @type {string}
     * @memberof QuestionProgressDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionProgressDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionProgressDto
     */
    'status': QuestionProgressDtoStatusEnum;
}

export const QuestionProgressDtoStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Skipped: 'SKIPPED',
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED'
} as const;

export type QuestionProgressDtoStatusEnum = typeof QuestionProgressDtoStatusEnum[keyof typeof QuestionProgressDtoStatusEnum];

/**
 * 
 * @export
 * @interface RegisterAuthorizedPerson
 */
export interface RegisterAuthorizedPerson {
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'type': RegisterAuthorizedPersonTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegisterAuthorizedPerson
     */
    'licensedInStates'?: Array<RegisterAuthorizedPersonLicensedInStatesEnum>;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'nmlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAuthorizedPerson
     */
    'title'?: string;
}

export const RegisterAuthorizedPersonTypeEnum = {
    LoanOfficer: 'LOAN_OFFICER',
    LoanOfficerAssistant: 'LOAN_OFFICER_ASSISTANT',
    LoanProcessor: 'LOAN_PROCESSOR',
    RealOriginateLoanOfficer: 'REAL_ORIGINATE_LOAN_OFFICER'
} as const;

export type RegisterAuthorizedPersonTypeEnum = typeof RegisterAuthorizedPersonTypeEnum[keyof typeof RegisterAuthorizedPersonTypeEnum];
export const RegisterAuthorizedPersonLicensedInStatesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type RegisterAuthorizedPersonLicensedInStatesEnum = typeof RegisterAuthorizedPersonLicensedInStatesEnum[keyof typeof RegisterAuthorizedPersonLicensedInStatesEnum];

/**
 * 
 * @export
 * @interface RegisterBorrower
 */
export interface RegisterBorrower {
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'loanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'leadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'loanOfficerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterBorrower
     */
    'inviterAuthorizedPersonId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterBorrower
     */
    'consentedToTerms'?: boolean;
}
/**
 * 
 * @export
 * @interface RoadToSuccessDefs
 */
export interface RoadToSuccessDefs {
    /**
     * 
     * @type {string}
     * @memberof RoadToSuccessDefs
     */
    'borrowerRtsDefId': string;
    /**
     * 
     * @type {string}
     * @memberof RoadToSuccessDefs
     */
    'coBorrowerRtsDefId': string;
}
/**
 * 
 * @export
 * @interface SectionIntro
 */
export interface SectionIntro extends Content {
    /**
     * 
     * @type {string}
     * @memberof SectionIntro
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionIntro
     */
    'subtitle'?: string;
    /**
     * 
     * @type {Style}
     * @memberof SectionIntro
     */
    'style'?: Style;
    /**
     * 
     * @type {ButtonField}
     * @memberof SectionIntro
     */
    'cta'?: ButtonField;
}


/**
 * 
 * @export
 * @interface SectionIntroAllOf
 */
export interface SectionIntroAllOf {
    /**
     * 
     * @type {string}
     * @memberof SectionIntroAllOf
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionIntroAllOf
     */
    'subtitle'?: string;
    /**
     * 
     * @type {Style}
     * @memberof SectionIntroAllOf
     */
    'style'?: Style;
    /**
     * 
     * @type {ButtonField}
     * @memberof SectionIntroAllOf
     */
    'cta'?: ButtonField;
}
/**
 * 
 * @export
 * @interface SectionOutro
 */
export interface SectionOutro extends Content {
    /**
     * 
     * @type {string}
     * @memberof SectionOutro
     */
    'sectionName'?: string;
    /**
     * 
     * @type {Style}
     * @memberof SectionOutro
     */
    'style'?: Style;
}


/**
 * 
 * @export
 * @interface SectionOutroAllOf
 */
export interface SectionOutroAllOf {
    /**
     * 
     * @type {string}
     * @memberof SectionOutroAllOf
     */
    'sectionName'?: string;
    /**
     * 
     * @type {Style}
     * @memberof SectionOutroAllOf
     */
    'style'?: Style;
}
/**
 * 
 * @export
 * @interface SectionProgressDto
 */
export interface SectionProgressDto {
    /**
     * 
     * @type {string}
     * @memberof SectionProgressDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SectionProgressDto
     */
    'status': SectionProgressDtoStatusEnum;
    /**
     * 
     * @type {Array<QuestionProgressDto>}
     * @memberof SectionProgressDto
     */
    'questionProgresses': Array<QuestionProgressDto>;
}

export const SectionProgressDtoStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Skipped: 'SKIPPED',
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED'
} as const;

export type SectionProgressDtoStatusEnum = typeof SectionProgressDtoStatusEnum[keyof typeof SectionProgressDtoStatusEnum];

/**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    'name': string;
    /**
     * 
     * @type {Array<FieldObject>}
     * @memberof Segment
     */
    'fields': Array<FieldObject>;
}
/**
 * 
 * @export
 * @interface SignUpBorrower
 */
export interface SignUpBorrower {
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'loanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'leadId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpBorrower
     */
    'loanOfficerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpBorrower
     */
    'consentedToTerms'?: boolean;
}
/**
 * 
 * @export
 * @interface SignUpLoanOfficer
 */
export interface SignUpLoanOfficer {
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'nmlsNumber': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SignUpLoanOfficer
     */
    'licensedInStates': Array<SignUpLoanOfficerLicensedInStatesEnum>;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLoanOfficer
     */
    'title'?: string;
}

export const SignUpLoanOfficerLicensedInStatesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type SignUpLoanOfficerLicensedInStatesEnum = typeof SignUpLoanOfficerLicensedInStatesEnum[keyof typeof SignUpLoanOfficerLicensedInStatesEnum];

/**
 * 
 * @export
 * @interface SkipQuestionStepRequest
 */
export interface SkipQuestionStepRequest {
    /**
     * 
     * @type {string}
     * @memberof SkipQuestionStepRequest
     */
    'questionId': string;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface StringAnswer
 */
export interface StringAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {string}
     * @memberof StringAnswer
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface StringAnswerAllOf
 */
export interface StringAnswerAllOf {
    /**
     * 
     * @type {string}
     * @memberof StringAnswerAllOf
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface Style
 */
export interface Style {
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    'template'?: StyleTemplateEnum;
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    'backgroundImageMobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Style
     */
    'backgroundImageDesktop'?: string;
}

export const StyleTemplateEnum = {
    _1: 'TEMPLATE_1',
    _2: 'TEMPLATE_2'
} as const;

export type StyleTemplateEnum = typeof StyleTemplateEnum[keyof typeof StyleTemplateEnum];

/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

export const UpdateFailedMessageStatusBatchStatusEnum = {
    Blocked: 'BLOCKED',
    Unprocessed: 'UNPROCESSED',
    Processed: 'PROCESSED',
    Failed: 'FAILED',
    ManualOverride: 'MANUAL_OVERRIDE',
    NoActionRequired: 'NO_ACTION_REQUIRED'
} as const;

export type UpdateFailedMessageStatusBatchStatusEnum = typeof UpdateFailedMessageStatusBatchStatusEnum[keyof typeof UpdateFailedMessageStatusBatchStatusEnum];

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {EmailAddress}
     * @memberof UserDto
     */
    'emailAddress'?: EmailAddress;
    /**
     * 
     * @type {PhoneNumber}
     * @memberof UserDto
     */
    'phoneNumber'?: PhoneNumber;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'stateOrProvince'?: UserDtoStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'slug'?: string;
}

export const UserDtoStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type UserDtoStateOrProvinceEnum = typeof UserDtoStateOrProvinceEnum[keyof typeof UserDtoStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface UserIds
 */
export interface UserIds {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserIds
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface UuidAnswer
 */
export interface UuidAnswer extends FieldAnswerObject {
    /**
     * 
     * @type {string}
     * @memberof UuidAnswer
     */
    'answer': string;
}
/**
 * 
 * @export
 * @interface UuidAnswerAllOf
 */
export interface UuidAnswerAllOf {
    /**
     * 
     * @type {string}
     * @memberof UuidAnswerAllOf
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface Validation
 */
export interface Validation {
    /**
     * 
     * @type {string}
     * @memberof Validation
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acknowledge a section milestone
         * @param {string} borrowerId 
         * @param {AcknowledgeSectionStepRequest} acknowledgeSectionStepRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeSection: async (borrowerId: string, acknowledgeSectionStepRequest: AcknowledgeSectionStepRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('acknowledgeSection', 'borrowerId', borrowerId)
            // verify required parameter 'acknowledgeSectionStepRequest' is not null or undefined
            assertParamExists('acknowledgeSection', 'acknowledgeSectionStepRequest', acknowledgeSectionStepRequest)
            const localVarPath = `/api/v1/applications/{borrowerId}/sections/acknowledge`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acknowledgeSectionStepRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Answer a question
         * @param {string} borrowerId 
         * @param {AnswerApplicationQuestionRequest} answerApplicationQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerQuestion: async (borrowerId: string, answerApplicationQuestionRequest: AnswerApplicationQuestionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('answerQuestion', 'borrowerId', borrowerId)
            // verify required parameter 'answerApplicationQuestionRequest' is not null or undefined
            assertParamExists('answerQuestion', 'answerApplicationQuestionRequest', answerApplicationQuestionRequest)
            const localVarPath = `/api/v1/applications/{borrowerId}/questions/answer`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answerApplicationQuestionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all questions for borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBorrowerQuestions: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getAllBorrowerQuestions', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/applications/{borrowerId}/questions`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all question steps for section
         * @param {string} borrowerId 
         * @param {string} sectionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllQuestionStepsBySection: async (borrowerId: string, sectionName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getAllQuestionStepsBySection', 'borrowerId', borrowerId)
            // verify required parameter 'sectionName' is not null or undefined
            assertParamExists('getAllQuestionStepsBySection', 'sectionName', sectionName)
            const localVarPath = `/api/v1/applications/{borrowerId}/questions/by-section/{sectionName}`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)))
                .replace(`{${"sectionName"}}`, encodeURIComponent(String(sectionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get answered application question by questionId
         * @param {string} borrowerId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnsweredApplicationStep: async (borrowerId: string, contentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getAnsweredApplicationStep', 'borrowerId', borrowerId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getAnsweredApplicationStep', 'contentId', contentId)
            const localVarPath = `/api/v1/applications/{borrowerId}/questions/answered/{contentId}`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get next application question
         * @param {string} borrowerId 
         * @param {boolean} [includeSkipped] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextApplicationStep: async (borrowerId: string, includeSkipped?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getNextApplicationStep', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/applications/{borrowerId}/questions/next`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeSkipped !== undefined) {
                localVarQueryParameter['includeSkipped'] = includeSkipped;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get application progress summary
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressSummary: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getProgressSummary', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/applications/{borrowerId}/progress`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Skip a question
         * @param {string} borrowerId 
         * @param {SkipQuestionStepRequest} skipQuestionStepRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipQuestion: async (borrowerId: string, skipQuestionStepRequest: SkipQuestionStepRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('skipQuestion', 'borrowerId', borrowerId)
            // verify required parameter 'skipQuestionStepRequest' is not null or undefined
            assertParamExists('skipQuestion', 'skipQuestionStepRequest', skipQuestionStepRequest)
            const localVarPath = `/api/v1/applications/{borrowerId}/questions/skip`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skipQuestionStepRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit application as a borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplication: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('submitApplication', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/applications/{borrowerId}/submit`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Acknowledge a section milestone
         * @param {string} borrowerId 
         * @param {AcknowledgeSectionStepRequest} acknowledgeSectionStepRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acknowledgeSection(borrowerId: string, acknowledgeSectionStepRequest: AcknowledgeSectionStepRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeSection(borrowerId, acknowledgeSectionStepRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Answer a question
         * @param {string} borrowerId 
         * @param {AnswerApplicationQuestionRequest} answerApplicationQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerQuestion(borrowerId: string, answerApplicationQuestionRequest: AnswerApplicationQuestionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answerQuestion(borrowerId, answerApplicationQuestionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all questions for borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBorrowerQuestions(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BorrowerApplicationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBorrowerQuestions(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all question steps for section
         * @param {string} borrowerId 
         * @param {string} sectionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllQuestionStepsBySection(borrowerId: string, sectionName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllQuestionStepsBySection(borrowerId, sectionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get answered application question by questionId
         * @param {string} borrowerId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnsweredApplicationStep(borrowerId: string, contentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnsweredApplicationStep(borrowerId, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get next application question
         * @param {string} borrowerId 
         * @param {boolean} [includeSkipped] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextApplicationStep(borrowerId: string, includeSkipped?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextApplicationStep(borrowerId, includeSkipped, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get application progress summary
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgressSummary(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProgressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgressSummary(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Skip a question
         * @param {string} borrowerId 
         * @param {SkipQuestionStepRequest} skipQuestionStepRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipQuestion(borrowerId: string, skipQuestionStepRequest: SkipQuestionStepRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skipQuestion(borrowerId, skipQuestionStepRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit application as a borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitApplication(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitApplication(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @summary Acknowledge a section milestone
         * @param {string} borrowerId 
         * @param {AcknowledgeSectionStepRequest} acknowledgeSectionStepRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeSection(borrowerId: string, acknowledgeSectionStepRequest: AcknowledgeSectionStepRequest, options?: any): AxiosPromise<void> {
            return localVarFp.acknowledgeSection(borrowerId, acknowledgeSectionStepRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Answer a question
         * @param {string} borrowerId 
         * @param {AnswerApplicationQuestionRequest} answerApplicationQuestionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerQuestion(borrowerId: string, answerApplicationQuestionRequest: AnswerApplicationQuestionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.answerQuestion(borrowerId, answerApplicationQuestionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all questions for borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBorrowerQuestions(borrowerId: string, options?: any): AxiosPromise<BorrowerApplicationDto> {
            return localVarFp.getAllBorrowerQuestions(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all question steps for section
         * @param {string} borrowerId 
         * @param {string} sectionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllQuestionStepsBySection(borrowerId: string, sectionName: string, options?: any): AxiosPromise<Array<ApplicationStep>> {
            return localVarFp.getAllQuestionStepsBySection(borrowerId, sectionName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get answered application question by questionId
         * @param {string} borrowerId 
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnsweredApplicationStep(borrowerId: string, contentId: string, options?: any): AxiosPromise<ApplicationStep> {
            return localVarFp.getAnsweredApplicationStep(borrowerId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get next application question
         * @param {string} borrowerId 
         * @param {boolean} [includeSkipped] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextApplicationStep(borrowerId: string, includeSkipped?: boolean, options?: any): AxiosPromise<ApplicationStep> {
            return localVarFp.getNextApplicationStep(borrowerId, includeSkipped, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get application progress summary
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressSummary(borrowerId: string, options?: any): AxiosPromise<ApplicationProgressResponse> {
            return localVarFp.getProgressSummary(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Skip a question
         * @param {string} borrowerId 
         * @param {SkipQuestionStepRequest} skipQuestionStepRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipQuestion(borrowerId: string, skipQuestionStepRequest: SkipQuestionStepRequest, options?: any): AxiosPromise<void> {
            return localVarFp.skipQuestion(borrowerId, skipQuestionStepRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit application as a borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplication(borrowerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.submitApplication(borrowerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Acknowledge a section milestone
     * @param {string} borrowerId 
     * @param {AcknowledgeSectionStepRequest} acknowledgeSectionStepRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public acknowledgeSection(borrowerId: string, acknowledgeSectionStepRequest: AcknowledgeSectionStepRequest, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).acknowledgeSection(borrowerId, acknowledgeSectionStepRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Answer a question
     * @param {string} borrowerId 
     * @param {AnswerApplicationQuestionRequest} answerApplicationQuestionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public answerQuestion(borrowerId: string, answerApplicationQuestionRequest: AnswerApplicationQuestionRequest, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).answerQuestion(borrowerId, answerApplicationQuestionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all questions for borrower
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAllBorrowerQuestions(borrowerId: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getAllBorrowerQuestions(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all question steps for section
     * @param {string} borrowerId 
     * @param {string} sectionName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAllQuestionStepsBySection(borrowerId: string, sectionName: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getAllQuestionStepsBySection(borrowerId, sectionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get answered application question by questionId
     * @param {string} borrowerId 
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAnsweredApplicationStep(borrowerId: string, contentId: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getAnsweredApplicationStep(borrowerId, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get next application question
     * @param {string} borrowerId 
     * @param {boolean} [includeSkipped] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getNextApplicationStep(borrowerId: string, includeSkipped?: boolean, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getNextApplicationStep(borrowerId, includeSkipped, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get application progress summary
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getProgressSummary(borrowerId: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getProgressSummary(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Skip a question
     * @param {string} borrowerId 
     * @param {SkipQuestionStepRequest} skipQuestionStepRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public skipQuestion(borrowerId: string, skipQuestionStepRequest: SkipQuestionStepRequest, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).skipQuestion(borrowerId, skipQuestionStepRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit application as a borrower
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public submitApplication(borrowerId: string, options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).submitApplication(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BankingApi - axios parameter creator
 * @export
 */
export const BankingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate bank report
         * @param {string} borrowerId 
         * @param {GenerateBankReportRequest} generateBankReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBankReport: async (borrowerId: string, generateBankReportRequest: GenerateBankReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('generateBankReport', 'borrowerId', borrowerId)
            // verify required parameter 'generateBankReportRequest' is not null or undefined
            assertParamExists('generateBankReport', 'generateBankReportRequest', generateBankReportRequest)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/reports`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateBankReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank customer accounts for the logged-in borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankCustomerAccounts: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getBankCustomerAccounts', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/customer-accounts`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank report by the bank\'s report id
         * @param {string} borrowerId 
         * @param {string} bankReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankReport: async (borrowerId: string, bankReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getBankReport', 'borrowerId', borrowerId)
            // verify required parameter 'bankReportId' is not null or undefined
            assertParamExists('getBankReport', 'bankReportId', bankReportId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/reports/{bankReportId}`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)))
                .replace(`{${"bankReportId"}}`, encodeURIComponent(String(bankReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank report summaries for a given borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankReportSummaries: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getBankReportSummaries', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/report-summaries`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank Connect URL for the logged-in user
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/borrowers/banking/connect-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank Connect URL for the provided borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrlForBorrower: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getGeneratedBankConnectUrlForBorrower', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/connect-url`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank Connect URL for the provided borrower with the specified report type
         * @param {string} borrowerId 
         * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrlForBorrowerAndReportType: async (borrowerId: string, reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getGeneratedBankConnectUrlForBorrowerAndReportType', 'borrowerId', borrowerId)
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getGeneratedBankConnectUrlForBorrowerAndReportType', 'reportType', reportType)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/connect-url/report-type/{reportType}`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)))
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank Connect URL for the logged-in borrower with the specified report type
         * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrlWithReportType: async (reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getGeneratedBankConnectUrlWithReportType', 'reportType', reportType)
            const localVarPath = `/api/v1/borrowers/banking/connect-url/report-type/{reportType}`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh and get bank customer accounts for the logged-in borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBankCustomerAccounts: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('refreshBankCustomerAccounts', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/banking/customer-accounts/refresh`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankingApi - functional programming interface
 * @export
 */
export const BankingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate bank report
         * @param {string} borrowerId 
         * @param {GenerateBankReportRequest} generateBankReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBankReport(borrowerId: string, generateBankReportRequest: GenerateBankReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateBankReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateBankReport(borrowerId, generateBankReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank customer accounts for the logged-in borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankCustomerAccounts(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankCustomerAccounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankCustomerAccounts(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank report by the bank\'s report id
         * @param {string} borrowerId 
         * @param {string} bankReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankReport(borrowerId: string, bankReportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankReport(borrowerId, bankReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank report summaries for a given borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankReportSummaries(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankReportSummariesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankReportSummaries(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank Connect URL for the logged-in user
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getGeneratedBankConnectUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneratedBankConnectUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank Connect URL for the provided borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneratedBankConnectUrlForBorrower(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneratedBankConnectUrlForBorrower(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank Connect URL for the provided borrower with the specified report type
         * @param {string} borrowerId 
         * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneratedBankConnectUrlForBorrowerAndReportType(borrowerId: string, reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneratedBankConnectUrlForBorrowerAndReportType(borrowerId, reportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank Connect URL for the logged-in borrower with the specified report type
         * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getGeneratedBankConnectUrlWithReportType(reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneratedBankConnectUrlWithReportType(reportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh and get bank customer accounts for the logged-in borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshBankCustomerAccounts(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankCustomerAccounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshBankCustomerAccounts(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankingApi - factory interface
 * @export
 */
export const BankingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankingApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate bank report
         * @param {string} borrowerId 
         * @param {GenerateBankReportRequest} generateBankReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBankReport(borrowerId: string, generateBankReportRequest: GenerateBankReportRequest, options?: any): AxiosPromise<GenerateBankReportResponse> {
            return localVarFp.generateBankReport(borrowerId, generateBankReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank customer accounts for the logged-in borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankCustomerAccounts(borrowerId: string, options?: any): AxiosPromise<BankCustomerAccounts> {
            return localVarFp.getBankCustomerAccounts(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank report by the bank\'s report id
         * @param {string} borrowerId 
         * @param {string} bankReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankReport(borrowerId: string, bankReportId: string, options?: any): AxiosPromise<BankReportDto> {
            return localVarFp.getBankReport(borrowerId, bankReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank report summaries for a given borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankReportSummaries(borrowerId: string, options?: any): AxiosPromise<BankReportSummariesDto> {
            return localVarFp.getBankReportSummaries(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank Connect URL for the logged-in user
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrl(options?: any): AxiosPromise<string> {
            return localVarFp.getGeneratedBankConnectUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank Connect URL for the provided borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrlForBorrower(borrowerId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getGeneratedBankConnectUrlForBorrower(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank Connect URL for the provided borrower with the specified report type
         * @param {string} borrowerId 
         * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrlForBorrowerAndReportType(borrowerId: string, reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options?: any): AxiosPromise<string> {
            return localVarFp.getGeneratedBankConnectUrlForBorrowerAndReportType(borrowerId, reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank Connect URL for the logged-in borrower with the specified report type
         * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGeneratedBankConnectUrlWithReportType(reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options?: any): AxiosPromise<string> {
            return localVarFp.getGeneratedBankConnectUrlWithReportType(reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh and get bank customer accounts for the logged-in borrower
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBankCustomerAccounts(borrowerId: string, options?: any): AxiosPromise<BankCustomerAccounts> {
            return localVarFp.refreshBankCustomerAccounts(borrowerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankingApi - object-oriented interface
 * @export
 * @class BankingApi
 * @extends {BaseAPI}
 */
export class BankingApi extends BaseAPI {
    /**
     * 
     * @summary Generate bank report
     * @param {string} borrowerId 
     * @param {GenerateBankReportRequest} generateBankReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public generateBankReport(borrowerId: string, generateBankReportRequest: GenerateBankReportRequest, options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).generateBankReport(borrowerId, generateBankReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank customer accounts for the logged-in borrower
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getBankCustomerAccounts(borrowerId: string, options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getBankCustomerAccounts(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank report by the bank\'s report id
     * @param {string} borrowerId 
     * @param {string} bankReportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getBankReport(borrowerId: string, bankReportId: string, options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getBankReport(borrowerId, bankReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank report summaries for a given borrower
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getBankReportSummaries(borrowerId: string, options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getBankReportSummaries(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank Connect URL for the logged-in user
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getGeneratedBankConnectUrl(options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getGeneratedBankConnectUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank Connect URL for the provided borrower
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getGeneratedBankConnectUrlForBorrower(borrowerId: string, options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getGeneratedBankConnectUrlForBorrower(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank Connect URL for the provided borrower with the specified report type
     * @param {string} borrowerId 
     * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getGeneratedBankConnectUrlForBorrowerAndReportType(borrowerId: string, reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getGeneratedBankConnectUrlForBorrowerAndReportType(borrowerId, reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank Connect URL for the logged-in borrower with the specified report type
     * @param {'VOA' | 'VOAI' | 'VOIE_TX'} reportType 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public getGeneratedBankConnectUrlWithReportType(reportType: 'VOA' | 'VOAI' | 'VOIE_TX', options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).getGeneratedBankConnectUrlWithReportType(reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh and get bank customer accounts for the logged-in borrower
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApi
     */
    public refreshBankCustomerAccounts(borrowerId: string, options?: AxiosRequestConfig) {
        return BankingApiFp(this.configuration).refreshBankCustomerAccounts(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BorrowersApi - axios parameter creator
 * @export
 */
export const BorrowersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks access to the provided borrowerId
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermissions: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('checkPermissions', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/check-permissions`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the borrower data.)
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedUsersForBorrower: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getAllAuthorizedUsersForBorrower', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/all-authorized-users`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklist items that are marked as required and assigned to the borrower
         * @param {string} borrowerId 
         * @param {boolean} required 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerAssignedChecklistTasks: async (borrowerId: string, required: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getBorrowerAssignedChecklistTasks', 'borrowerId', borrowerId)
            // verify required parameter 'required' is not null or undefined
            assertParamExists('getBorrowerAssignedChecklistTasks', 'required', required)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/checklist-tasks`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (required !== undefined) {
                localVarQueryParameter['required'] = required;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get checklist item for the borrower
         * @param {string} borrowerId 
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerChecklistTask: async (borrowerId: string, checklistItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getBorrowerChecklistTask', 'borrowerId', borrowerId)
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('getBorrowerChecklistTask', 'checklistItemId', checklistItemId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/checklist-tasks/{checklistItemId}`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)))
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the minimal details of a borrower needed for a yada comment and notification (to borrower, loan-officer, etc.)
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerForCommentDto: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getBorrowerForCommentDto', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/borrowers/{borrowerId}/for-comment`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register as a new borrower
         * @param {RegisterBorrower} registerBorrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBorrower: async (registerBorrower: RegisterBorrower, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerBorrower' is not null or undefined
            assertParamExists('registerBorrower', 'registerBorrower', registerBorrower)
            const localVarPath = `/api/v1/borrowers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerBorrower, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up as new borrower
         * @param {SignUpBorrower} signUpBorrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp1: async (signUpBorrower: SignUpBorrower, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpBorrower' is not null or undefined
            assertParamExists('signUp1', 'signUpBorrower', signUpBorrower)
            const localVarPath = `/api/v1/borrowers/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpBorrower, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BorrowersApi - functional programming interface
 * @export
 */
export const BorrowersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BorrowersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Checks access to the provided borrowerId
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPermissions(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPermissions(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the borrower data.)
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuthorizedUsersForBorrower(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuthorizedUsersForBorrower(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklist items that are marked as required and assigned to the borrower
         * @param {string} borrowerId 
         * @param {boolean} required 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerAssignedChecklistTasks(borrowerId: string, required: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChecklistTaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBorrowerAssignedChecklistTasks(borrowerId, required, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get checklist item for the borrower
         * @param {string} borrowerId 
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerChecklistTask(borrowerId: string, checklistItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBorrowerChecklistTask(borrowerId, checklistItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the minimal details of a borrower needed for a yada comment and notification (to borrower, loan-officer, etc.)
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBorrowerForCommentDto(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BorrowerForCommentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBorrowerForCommentDto(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register as a new borrower
         * @param {RegisterBorrower} registerBorrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerBorrower(registerBorrower: RegisterBorrower, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BorrowerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerBorrower(registerBorrower, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up as new borrower
         * @param {SignUpBorrower} signUpBorrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp1(signUpBorrower: SignUpBorrower, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BorrowerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp1(signUpBorrower, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BorrowersApi - factory interface
 * @export
 */
export const BorrowersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BorrowersApiFp(configuration)
    return {
        /**
         * 
         * @summary Checks access to the provided borrowerId
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermissions(borrowerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkPermissions(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the borrower data.)
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedUsersForBorrower(borrowerId: string, options?: any): AxiosPromise<UserIds> {
            return localVarFp.getAllAuthorizedUsersForBorrower(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklist items that are marked as required and assigned to the borrower
         * @param {string} borrowerId 
         * @param {boolean} required 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerAssignedChecklistTasks(borrowerId: string, required: boolean, options?: any): AxiosPromise<Array<ChecklistTaskResponse>> {
            return localVarFp.getBorrowerAssignedChecklistTasks(borrowerId, required, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get checklist item for the borrower
         * @param {string} borrowerId 
         * @param {string} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerChecklistTask(borrowerId: string, checklistItemId: string, options?: any): AxiosPromise<ChecklistTaskResponse> {
            return localVarFp.getBorrowerChecklistTask(borrowerId, checklistItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the minimal details of a borrower needed for a yada comment and notification (to borrower, loan-officer, etc.)
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBorrowerForCommentDto(borrowerId: string, options?: any): AxiosPromise<BorrowerForCommentDto> {
            return localVarFp.getBorrowerForCommentDto(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register as a new borrower
         * @param {RegisterBorrower} registerBorrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerBorrower(registerBorrower: RegisterBorrower, options?: any): AxiosPromise<BorrowerDto> {
            return localVarFp.registerBorrower(registerBorrower, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up as new borrower
         * @param {SignUpBorrower} signUpBorrower 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp1(signUpBorrower: SignUpBorrower, options?: any): AxiosPromise<BorrowerDto> {
            return localVarFp.signUp1(signUpBorrower, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BorrowersApi - object-oriented interface
 * @export
 * @class BorrowersApi
 * @extends {BaseAPI}
 */
export class BorrowersApi extends BaseAPI {
    /**
     * 
     * @summary Checks access to the provided borrowerId
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public checkPermissions(borrowerId: string, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).checkPermissions(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user ids of all users that can access the borrower data.)
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public getAllAuthorizedUsersForBorrower(borrowerId: string, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).getAllAuthorizedUsersForBorrower(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklist items that are marked as required and assigned to the borrower
     * @param {string} borrowerId 
     * @param {boolean} required 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public getBorrowerAssignedChecklistTasks(borrowerId: string, required: boolean, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).getBorrowerAssignedChecklistTasks(borrowerId, required, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get checklist item for the borrower
     * @param {string} borrowerId 
     * @param {string} checklistItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public getBorrowerChecklistTask(borrowerId: string, checklistItemId: string, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).getBorrowerChecklistTask(borrowerId, checklistItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the minimal details of a borrower needed for a yada comment and notification (to borrower, loan-officer, etc.)
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public getBorrowerForCommentDto(borrowerId: string, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).getBorrowerForCommentDto(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register as a new borrower
     * @param {RegisterBorrower} registerBorrower 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public registerBorrower(registerBorrower: RegisterBorrower, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).registerBorrower(registerBorrower, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up as new borrower
     * @param {SignUpBorrower} signUpBorrower 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BorrowersApi
     */
    public signUp1(signUpBorrower: SignUpBorrower, options?: AxiosRequestConfig) {
        return BorrowersApiFp(this.configuration).signUp1(signUpBorrower, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistDefApi - axios parameter creator
 * @export
 */
export const ChecklistDefApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineBorrowerApplicationFromFile: async (loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanType' is not null or undefined
            assertParamExists('defineBorrowerApplicationFromFile', 'loanType', loanType)
            const localVarPath = `/api/v1/loan-checklist-defs/application/borrower/loan-type/{loanType}/from-file`
                .replace(`{${"loanType"}}`, encodeURIComponent(String(loanType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defineChecklistFromFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineChecklistFromFile: async (loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanType' is not null or undefined
            assertParamExists('defineChecklistFromFile', 'loanType', loanType)
            const localVarPath = `/api/v1/loan-checklist-defs/checklist/loan-type/{loanType}/from-file`
                .replace(`{${"loanType"}}`, encodeURIComponent(String(loanType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defineChecklistFromFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineCoBorrowerApplicationFromFile: async (loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanType' is not null or undefined
            assertParamExists('defineCoBorrowerApplicationFromFile', 'loanType', loanType)
            const localVarPath = `/api/v1/loan-checklist-defs/application/co-borrower/loan-type/{loanType}/from-file`
                .replace(`{${"loanType"}}`, encodeURIComponent(String(loanType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defineChecklistFromFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineRoadToSuccess: async (loanType: 'PURCHASE' | 'REFINANCE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanType' is not null or undefined
            assertParamExists('defineRoadToSuccess', 'loanType', loanType)
            const localVarPath = `/api/v1/loan-checklist-defs/road-to-success/loan-type/{loanType}`
                .replace(`{${"loanType"}}`, encodeURIComponent(String(loanType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistDefApi - functional programming interface
 * @export
 */
export const ChecklistDefApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistDefApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async defineBorrowerApplicationFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.defineBorrowerApplicationFromFile(loanType, defineChecklistFromFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async defineChecklistFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.defineChecklistFromFile(loanType, defineChecklistFromFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async defineCoBorrowerApplicationFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.defineCoBorrowerApplicationFromFile(loanType, defineChecklistFromFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async defineRoadToSuccess(loanType: 'PURCHASE' | 'REFINANCE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoadToSuccessDefs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.defineRoadToSuccess(loanType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistDefApi - factory interface
 * @export
 */
export const ChecklistDefApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistDefApiFp(configuration)
    return {
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineBorrowerApplicationFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: any): AxiosPromise<string> {
            return localVarFp.defineBorrowerApplicationFromFile(loanType, defineChecklistFromFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineChecklistFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: any): AxiosPromise<string> {
            return localVarFp.defineChecklistFromFile(loanType, defineChecklistFromFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineCoBorrowerApplicationFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: any): AxiosPromise<string> {
            return localVarFp.defineCoBorrowerApplicationFromFile(loanType, defineChecklistFromFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'PURCHASE' | 'REFINANCE'} loanType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        defineRoadToSuccess(loanType: 'PURCHASE' | 'REFINANCE', options?: any): AxiosPromise<RoadToSuccessDefs> {
            return localVarFp.defineRoadToSuccess(loanType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistDefApi - object-oriented interface
 * @export
 * @class ChecklistDefApi
 * @extends {BaseAPI}
 */
export class ChecklistDefApi extends BaseAPI {
    /**
     * 
     * @param {'PURCHASE' | 'REFINANCE'} loanType 
     * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefApi
     */
    public defineBorrowerApplicationFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: AxiosRequestConfig) {
        return ChecklistDefApiFp(this.configuration).defineBorrowerApplicationFromFile(loanType, defineChecklistFromFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'PURCHASE' | 'REFINANCE'} loanType 
     * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefApi
     */
    public defineChecklistFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: AxiosRequestConfig) {
        return ChecklistDefApiFp(this.configuration).defineChecklistFromFile(loanType, defineChecklistFromFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'PURCHASE' | 'REFINANCE'} loanType 
     * @param {DefineChecklistFromFileRequest} [defineChecklistFromFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefApi
     */
    public defineCoBorrowerApplicationFromFile(loanType: 'PURCHASE' | 'REFINANCE', defineChecklistFromFileRequest?: DefineChecklistFromFileRequest, options?: AxiosRequestConfig) {
        return ChecklistDefApiFp(this.configuration).defineCoBorrowerApplicationFromFile(loanType, defineChecklistFromFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'PURCHASE' | 'REFINANCE'} loanType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistDefApi
     */
    public defineRoadToSuccess(loanType: 'PURCHASE' | 'REFINANCE', options?: AxiosRequestConfig) {
        return ChecklistDefApiFp(this.configuration).defineRoadToSuccess(loanType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsentApi - axios parameter creator
 * @export
 */
export const ConsentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search for captured consents by borrower for various consent types e.g. credit consent, terms and conditions, etc.
         * @param {string} [borrowerId] 
         * @param {string} [loanId] 
         * @param {string} [loanNumber] 
         * @param {string} [lastName] 
         * @param {string} [firstName] 
         * @param {number} [consentedAtGreaterThanOrEqualTo] 
         * @param {number} [consentedAtLessThanOrEqualTo] 
         * @param {Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>} [type] 
         * @param {Array<'CONSENTED_AT'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsents: async (borrowerId?: string, loanId?: string, loanNumber?: string, lastName?: string, firstName?: string, consentedAtGreaterThanOrEqualTo?: number, consentedAtLessThanOrEqualTo?: number, type?: Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>, sortBy?: Array<'CONSENTED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/borrowers/consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (borrowerId !== undefined) {
                localVarQueryParameter['borrowerId'] = borrowerId;
            }

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (loanNumber !== undefined) {
                localVarQueryParameter['loanNumber'] = loanNumber;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (consentedAtGreaterThanOrEqualTo !== undefined) {
                localVarQueryParameter['consentedAtGreaterThanOrEqualTo'] = consentedAtGreaterThanOrEqualTo;
            }

            if (consentedAtLessThanOrEqualTo !== undefined) {
                localVarQueryParameter['consentedAtLessThanOrEqualTo'] = consentedAtLessThanOrEqualTo;
            }

            if (type) {
                localVarQueryParameter['type'] = Array.from(type);
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsentApi - functional programming interface
 * @export
 */
export const ConsentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search for captured consents by borrower for various consent types e.g. credit consent, terms and conditions, etc.
         * @param {string} [borrowerId] 
         * @param {string} [loanId] 
         * @param {string} [loanNumber] 
         * @param {string} [lastName] 
         * @param {string} [firstName] 
         * @param {number} [consentedAtGreaterThanOrEqualTo] 
         * @param {number} [consentedAtLessThanOrEqualTo] 
         * @param {Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>} [type] 
         * @param {Array<'CONSENTED_AT'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConsents(borrowerId?: string, loanId?: string, loanNumber?: string, lastName?: string, firstName?: string, consentedAtGreaterThanOrEqualTo?: number, consentedAtLessThanOrEqualTo?: number, type?: Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>, sortBy?: Array<'CONSENTED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseConsentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConsents(borrowerId, loanId, loanNumber, lastName, firstName, consentedAtGreaterThanOrEqualTo, consentedAtLessThanOrEqualTo, type, sortBy, sortDirection, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsentApi - factory interface
 * @export
 */
export const ConsentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsentApiFp(configuration)
    return {
        /**
         * 
         * @summary Search for captured consents by borrower for various consent types e.g. credit consent, terms and conditions, etc.
         * @param {string} [borrowerId] 
         * @param {string} [loanId] 
         * @param {string} [loanNumber] 
         * @param {string} [lastName] 
         * @param {string} [firstName] 
         * @param {number} [consentedAtGreaterThanOrEqualTo] 
         * @param {number} [consentedAtLessThanOrEqualTo] 
         * @param {Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>} [type] 
         * @param {Array<'CONSENTED_AT'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConsents(borrowerId?: string, loanId?: string, loanNumber?: string, lastName?: string, firstName?: string, consentedAtGreaterThanOrEqualTo?: number, consentedAtLessThanOrEqualTo?: number, type?: Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>, sortBy?: Array<'CONSENTED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GenericSearchResponseConsentDto> {
            return localVarFp.getConsents(borrowerId, loanId, loanNumber, lastName, firstName, consentedAtGreaterThanOrEqualTo, consentedAtLessThanOrEqualTo, type, sortBy, sortDirection, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsentApi - object-oriented interface
 * @export
 * @class ConsentApi
 * @extends {BaseAPI}
 */
export class ConsentApi extends BaseAPI {
    /**
     * 
     * @summary Search for captured consents by borrower for various consent types e.g. credit consent, terms and conditions, etc.
     * @param {string} [borrowerId] 
     * @param {string} [loanId] 
     * @param {string} [loanNumber] 
     * @param {string} [lastName] 
     * @param {string} [firstName] 
     * @param {number} [consentedAtGreaterThanOrEqualTo] 
     * @param {number} [consentedAtLessThanOrEqualTo] 
     * @param {Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>} [type] 
     * @param {Array<'CONSENTED_AT'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public getConsents(borrowerId?: string, loanId?: string, loanNumber?: string, lastName?: string, firstName?: string, consentedAtGreaterThanOrEqualTo?: number, consentedAtLessThanOrEqualTo?: number, type?: Array<'TERMS_CONDITIONS' | 'CREDIT_INCOME_EMP_VERIFICATION'>, sortBy?: Array<'CONSENTED_AT'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).getConsents(borrowerId, loanId, loanNumber, lastName, firstName, consentedAtGreaterThanOrEqualTo, consentedAtLessThanOrEqualTo, type, sortBy, sortDirection, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoanOfficerApi - axios parameter creator
 * @export
 */
export const LoanOfficerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get loan officer by id
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOfficerById: async (loanOfficerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanOfficerId' is not null or undefined
            assertParamExists('getLoanOfficerById', 'loanOfficerId', loanOfficerId)
            const localVarPath = `/api/v1/loan-officers/{loanOfficerId}`
                .replace(`{${"loanOfficerId"}}`, encodeURIComponent(String(loanOfficerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get loan officer by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOfficerBySlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getLoanOfficerBySlug', 'slug', slug)
            const localVarPath = `/api/v1/loan-officers/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get loan officers
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [licensedInState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOfficers: async (licensedInState?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loan-officers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (licensedInState !== undefined) {
                localVarQueryParameter['licensedInState'] = licensedInState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch loan officer
         * @param {string} loanOfficerId 
         * @param {PatchLoanOfficerRequest} patchLoanOfficerRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchLoanOfficer: async (loanOfficerId: string, patchLoanOfficerRequest: PatchLoanOfficerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanOfficerId' is not null or undefined
            assertParamExists('patchLoanOfficer', 'loanOfficerId', loanOfficerId)
            // verify required parameter 'patchLoanOfficerRequest' is not null or undefined
            assertParamExists('patchLoanOfficer', 'patchLoanOfficerRequest', patchLoanOfficerRequest)
            const localVarPath = `/api/v1/loan-officers/{loanOfficerId}`
                .replace(`{${"loanOfficerId"}}`, encodeURIComponent(String(loanOfficerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchLoanOfficerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up as new loan officer
         * @param {SignUpLoanOfficer} signUpLoanOfficer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (signUpLoanOfficer: SignUpLoanOfficer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpLoanOfficer' is not null or undefined
            assertParamExists('signUp', 'signUpLoanOfficer', signUpLoanOfficer)
            const localVarPath = `/api/v1/loan-officers/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpLoanOfficer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update default loan officer
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultLoanOfficer: async (loanOfficerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanOfficerId' is not null or undefined
            assertParamExists('updateDefaultLoanOfficer', 'loanOfficerId', loanOfficerId)
            const localVarPath = `/api/v1/loan-officers/default/{loanOfficerId}`
                .replace(`{${"loanOfficerId"}}`, encodeURIComponent(String(loanOfficerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanOfficerApi - functional programming interface
 * @export
 */
export const LoanOfficerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanOfficerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get loan officer by id
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanOfficerById(loanOfficerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOfficerDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanOfficerById(loanOfficerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get loan officer by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanOfficerBySlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOfficerDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanOfficerBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get loan officers
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [licensedInState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanOfficers(licensedInState?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoanOfficerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanOfficers(licensedInState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch loan officer
         * @param {string} loanOfficerId 
         * @param {PatchLoanOfficerRequest} patchLoanOfficerRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async patchLoanOfficer(loanOfficerId: string, patchLoanOfficerRequest: PatchLoanOfficerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOfficerDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLoanOfficer(loanOfficerId, patchLoanOfficerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up as new loan officer
         * @param {SignUpLoanOfficer} signUpLoanOfficer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(signUpLoanOfficer: SignUpLoanOfficer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOfficerDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(signUpLoanOfficer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update default loan officer
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDefaultLoanOfficer(loanOfficerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultLoanOfficer(loanOfficerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanOfficerApi - factory interface
 * @export
 */
export const LoanOfficerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanOfficerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get loan officer by id
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOfficerById(loanOfficerId: string, options?: any): AxiosPromise<LoanOfficerDetailDto> {
            return localVarFp.getLoanOfficerById(loanOfficerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get loan officer by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOfficerBySlug(slug: string, options?: any): AxiosPromise<LoanOfficerDetailDto> {
            return localVarFp.getLoanOfficerBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get loan officers
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [licensedInState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanOfficers(licensedInState?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: any): AxiosPromise<Array<LoanOfficerDto>> {
            return localVarFp.getLoanOfficers(licensedInState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch loan officer
         * @param {string} loanOfficerId 
         * @param {PatchLoanOfficerRequest} patchLoanOfficerRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchLoanOfficer(loanOfficerId: string, patchLoanOfficerRequest: PatchLoanOfficerRequest, options?: any): AxiosPromise<LoanOfficerDetailDto> {
            return localVarFp.patchLoanOfficer(loanOfficerId, patchLoanOfficerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up as new loan officer
         * @param {SignUpLoanOfficer} signUpLoanOfficer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpLoanOfficer: SignUpLoanOfficer, options?: any): AxiosPromise<LoanOfficerDetailDto> {
            return localVarFp.signUp(signUpLoanOfficer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update default loan officer
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDefaultLoanOfficer(loanOfficerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateDefaultLoanOfficer(loanOfficerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanOfficerApi - object-oriented interface
 * @export
 * @class LoanOfficerApi
 * @extends {BaseAPI}
 */
export class LoanOfficerApi extends BaseAPI {
    /**
     * 
     * @summary Get loan officer by id
     * @param {string} loanOfficerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOfficerApi
     */
    public getLoanOfficerById(loanOfficerId: string, options?: AxiosRequestConfig) {
        return LoanOfficerApiFp(this.configuration).getLoanOfficerById(loanOfficerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get loan officer by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOfficerApi
     */
    public getLoanOfficerBySlug(slug: string, options?: AxiosRequestConfig) {
        return LoanOfficerApiFp(this.configuration).getLoanOfficerBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get loan officers
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [licensedInState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOfficerApi
     */
    public getLoanOfficers(licensedInState?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: AxiosRequestConfig) {
        return LoanOfficerApiFp(this.configuration).getLoanOfficers(licensedInState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch loan officer
     * @param {string} loanOfficerId 
     * @param {PatchLoanOfficerRequest} patchLoanOfficerRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LoanOfficerApi
     */
    public patchLoanOfficer(loanOfficerId: string, patchLoanOfficerRequest: PatchLoanOfficerRequest, options?: AxiosRequestConfig) {
        return LoanOfficerApiFp(this.configuration).patchLoanOfficer(loanOfficerId, patchLoanOfficerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up as new loan officer
     * @param {SignUpLoanOfficer} signUpLoanOfficer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOfficerApi
     */
    public signUp(signUpLoanOfficer: SignUpLoanOfficer, options?: AxiosRequestConfig) {
        return LoanOfficerApiFp(this.configuration).signUp(signUpLoanOfficer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update default loan officer
     * @param {string} loanOfficerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanOfficerApi
     */
    public updateDefaultLoanOfficer(loanOfficerId: string, options?: AxiosRequestConfig) {
        return LoanOfficerApiFp(this.configuration).updateDefaultLoanOfficer(loanOfficerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoansApi - axios parameter creator
 * @export
 */
export const LoansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new checklist question
         * @param {CreateChecklistFileUploadQuestion} createChecklistFileUploadQuestion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChecklistQuestion: async (createChecklistFileUploadQuestion: CreateChecklistFileUploadQuestion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChecklistFileUploadQuestion' is not null or undefined
            assertParamExists('addChecklistQuestion', 'createChecklistFileUploadQuestion', createChecklistFileUploadQuestion)
            const localVarPath = `/api/v1/loans/checklists/questions/file-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChecklistFileUploadQuestion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply for a new Loan
         * @param {ApplyLoan} applyLoan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForLoan: async (applyLoan: ApplyLoan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyLoan' is not null or undefined
            assertParamExists('applyForLoan', 'applyLoan', applyLoan)
            const localVarPath = `/api/v1/loans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyLoan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign an authorized user to a loan
         * @param {string} loanId 
         * @param {AuthorizedPersonIdRequest} authorizedPersonIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignAuthorizedPersonToLoan: async (loanId: string, authorizedPersonIdRequest: AuthorizedPersonIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('assignAuthorizedPersonToLoan', 'loanId', loanId)
            // verify required parameter 'authorizedPersonIdRequest' is not null or undefined
            assertParamExists('assignAuthorizedPersonToLoan', 'authorizedPersonIdRequest', authorizedPersonIdRequest)
            const localVarPath = `/api/v1/loans/{loanId}/authorized-users`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizedPersonIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a loan officer assistant of a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerAssistantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLoanOfficerAssistantToLoan: async (loanId: string, loanOfficerAssistantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('assignLoanOfficerAssistantToLoan', 'loanId', loanId)
            // verify required parameter 'loanOfficerAssistantId' is not null or undefined
            assertParamExists('assignLoanOfficerAssistantToLoan', 'loanOfficerAssistantId', loanOfficerAssistantId)
            const localVarPath = `/api/v1/loans/{loanId}/loan-officer-assistant/{loanOfficerAssistantId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"loanOfficerAssistantId"}}`, encodeURIComponent(String(loanOfficerAssistantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a loan processor of a given loan
         * @param {string} loanId 
         * @param {string} loanProcessorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLoanProcessorToLoan: async (loanId: string, loanProcessorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('assignLoanProcessorToLoan', 'loanId', loanId)
            // verify required parameter 'loanProcessorId' is not null or undefined
            assertParamExists('assignLoanProcessorToLoan', 'loanProcessorId', loanProcessorId)
            const localVarPath = `/api/v1/loans/{loanId}/loan-processor/{loanProcessorId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"loanProcessorId"}}`, encodeURIComponent(String(loanProcessorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a real originate mortgage advisor of a given loan
         * @param {string} loanId 
         * @param {string} realOriginateMortgageAdvisorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRealOriginateLoanOfficerToLoan: async (loanId: string, realOriginateMortgageAdvisorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('assignRealOriginateLoanOfficerToLoan', 'loanId', loanId)
            // verify required parameter 'realOriginateMortgageAdvisorId' is not null or undefined
            assertParamExists('assignRealOriginateLoanOfficerToLoan', 'realOriginateMortgageAdvisorId', realOriginateMortgageAdvisorId)
            const localVarPath = `/api/v1/loans/{loanId}/real-originate-mortgage-advisor/{realOriginateMortgageAdvisorId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"realOriginateMortgageAdvisorId"}}`, encodeURIComponent(String(realOriginateMortgageAdvisorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the loan data.)
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedUsersForLoan: async (loanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('getAllAuthorizedUsersForLoan', 'loanId', loanId)
            const localVarPath = `/api/v1/loans/{loanId}/all-authorized-users`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get leads and loans for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getLeadsAndLoansForAgent: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loans/for/agent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get leads and loans for authorized user
         * @param {string} [searchText] 
         * @param {Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>} [status] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {string} [loanNumber] 
         * @param {string} [borrowerName] 
         * @param {string} [email] 
         * @param {string} [loanOfficerName] 
         * @param {string} [loanProcessorName] 
         * @param {string} [realOriginateLoanOfficerName] 
         * @param {string} [loanOfficerAssistantName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadsAndLoansForAuthorizedUser: async (searchText?: string, status?: Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, loanNumber?: string, borrowerName?: string, email?: string, loanOfficerName?: string, loanProcessorName?: string, realOriginateLoanOfficerName?: string, loanOfficerAssistantName?: string, page?: number, size?: number, sortBy?: Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/loans/lead-and-loans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }

            if (state) {
                localVarQueryParameter['state'] = Array.from(state);
            }

            if (loanNumber !== undefined) {
                localVarQueryParameter['loanNumber'] = loanNumber;
            }

            if (borrowerName !== undefined) {
                localVarQueryParameter['borrowerName'] = borrowerName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (loanOfficerName !== undefined) {
                localVarQueryParameter['loanOfficerName'] = loanOfficerName;
            }

            if (loanProcessorName !== undefined) {
                localVarQueryParameter['loanProcessorName'] = loanProcessorName;
            }

            if (realOriginateLoanOfficerName !== undefined) {
                localVarQueryParameter['realOriginateLoanOfficerName'] = realOriginateLoanOfficerName;
            }

            if (loanOfficerAssistantName !== undefined) {
                localVarQueryParameter['loanOfficerAssistantName'] = loanOfficerAssistantName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Loan by Borrower ID
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanByBorrowerId: async (borrowerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'borrowerId' is not null or undefined
            assertParamExists('getLoanByBorrowerId', 'borrowerId', borrowerId)
            const localVarPath = `/api/v1/loans/borrower/{borrowerId}`
                .replace(`{${"borrowerId"}}`, encodeURIComponent(String(borrowerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Loan
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanByLoanId: async (loanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('getLoanByLoanId', 'loanId', loanId)
            const localVarPath = `/api/v1/loans/{loanId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Loan data
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanDataById: async (loanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('getLoanDataById', 'loanId', loanId)
            const localVarPath = `/api/v1/loans/{loanId}/data`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an authorized user from a loan
         * @param {string} loanId 
         * @param {string} authorizedPersonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuthorizedPersonFromLoan: async (loanId: string, authorizedPersonId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('removeAuthorizedPersonFromLoan', 'loanId', loanId)
            // verify required parameter 'authorizedPersonId' is not null or undefined
            assertParamExists('removeAuthorizedPersonFromLoan', 'authorizedPersonId', authorizedPersonId)
            const localVarPath = `/api/v1/loans/{loanId}/authorized-users/{authorizedPersonId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"authorizedPersonId"}}`, encodeURIComponent(String(authorizedPersonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a loan officer assistant from a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerAssistantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLoanOfficerAssistantFromLoan: async (loanId: string, loanOfficerAssistantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('removeLoanOfficerAssistantFromLoan', 'loanId', loanId)
            // verify required parameter 'loanOfficerAssistantId' is not null or undefined
            assertParamExists('removeLoanOfficerAssistantFromLoan', 'loanOfficerAssistantId', loanOfficerAssistantId)
            const localVarPath = `/api/v1/loans/{loanId}/loan-officer-assistant/{loanOfficerAssistantId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"loanOfficerAssistantId"}}`, encodeURIComponent(String(loanOfficerAssistantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a loan processor from a given loan
         * @param {string} loanId 
         * @param {string} loanProcessorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLoanProcessorToLoan: async (loanId: string, loanProcessorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('removeLoanProcessorToLoan', 'loanId', loanId)
            // verify required parameter 'loanProcessorId' is not null or undefined
            assertParamExists('removeLoanProcessorToLoan', 'loanProcessorId', loanProcessorId)
            const localVarPath = `/api/v1/loans/{loanId}/loan-processor/{loanProcessorId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"loanProcessorId"}}`, encodeURIComponent(String(loanProcessorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a real originate mortgage advisor from a given loan
         * @param {string} loanId 
         * @param {string} realOriginateMortgageAdvisorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRealOriginateLoanOfficerFromLoan: async (loanId: string, realOriginateMortgageAdvisorId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('removeRealOriginateLoanOfficerFromLoan', 'loanId', loanId)
            // verify required parameter 'realOriginateMortgageAdvisorId' is not null or undefined
            assertParamExists('removeRealOriginateLoanOfficerFromLoan', 'realOriginateMortgageAdvisorId', realOriginateMortgageAdvisorId)
            const localVarPath = `/api/v1/loans/{loanId}/real-originate-mortgage-advisor/{realOriginateMortgageAdvisorId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"realOriginateMortgageAdvisorId"}}`, encodeURIComponent(String(realOriginateMortgageAdvisorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent of a given loan
         * @param {string} loanId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (loanId: string, agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('updateAgent', 'loanId', loanId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/loans/{loanId}/agent/{agentId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan officer of a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanOfficer: async (loanId: string, loanOfficerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('updateLoanOfficer', 'loanId', loanId)
            // verify required parameter 'loanOfficerId' is not null or undefined
            assertParamExists('updateLoanOfficer', 'loanOfficerId', loanOfficerId)
            const localVarPath = `/api/v1/loans/{loanId}/loan-officer/{loanOfficerId}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"loanOfficerId"}}`, encodeURIComponent(String(loanOfficerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update status of a given loan
         * @param {string} loanId 
         * @param {'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED'} loanStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanStatus: async (loanId: string, loanStatus: 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('updateLoanStatus', 'loanId', loanId)
            // verify required parameter 'loanStatus' is not null or undefined
            assertParamExists('updateLoanStatus', 'loanStatus', loanStatus)
            const localVarPath = `/api/v1/loans/{loanId}/status/{loanStatus}`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)))
                .replace(`{${"loanStatus"}}`, encodeURIComponent(String(loanStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the pre-approval letter for the loan
         * @param {string} loanId 
         * @param {File} letter 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPreApprovalLetter: async (loanId: string, letter: File, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('uploadPreApprovalLetter', 'loanId', loanId)
            // verify required parameter 'letter' is not null or undefined
            assertParamExists('uploadPreApprovalLetter', 'letter', letter)
            const localVarPath = `/api/v1/loans/{loanId}/upload-pre-approval`
                .replace(`{${"loanId"}}`, encodeURIComponent(String(loanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (letter !== undefined) { 
                localVarFormParams.append('letter', letter as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoansApi - functional programming interface
 * @export
 */
export const LoansApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoansApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new checklist question
         * @param {CreateChecklistFileUploadQuestion} createChecklistFileUploadQuestion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChecklistQuestion(createChecklistFileUploadQuestion: CreateChecklistFileUploadQuestion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addChecklistQuestion(createChecklistFileUploadQuestion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply for a new Loan
         * @param {ApplyLoan} applyLoan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyForLoan(applyLoan: ApplyLoan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyForLoan(applyLoan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign an authorized user to a loan
         * @param {string} loanId 
         * @param {AuthorizedPersonIdRequest} authorizedPersonIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignAuthorizedPersonToLoan(loanId: string, authorizedPersonIdRequest: AuthorizedPersonIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignAuthorizedPersonToLoan(loanId, authorizedPersonIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a loan officer assistant of a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerAssistantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignLoanOfficerAssistantToLoan(loanId: string, loanOfficerAssistantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignLoanOfficerAssistantToLoan(loanId, loanOfficerAssistantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a loan processor of a given loan
         * @param {string} loanId 
         * @param {string} loanProcessorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignLoanProcessorToLoan(loanId: string, loanProcessorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignLoanProcessorToLoan(loanId, loanProcessorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a real originate mortgage advisor of a given loan
         * @param {string} loanId 
         * @param {string} realOriginateMortgageAdvisorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRealOriginateLoanOfficerToLoan(loanId: string, realOriginateMortgageAdvisorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRealOriginateLoanOfficerToLoan(loanId, realOriginateMortgageAdvisorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the loan data.)
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuthorizedUsersForLoan(loanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuthorizedUsersForLoan(loanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get leads and loans for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getLeadsAndLoansForAgent(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLeadLoanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeadsAndLoansForAgent(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get leads and loans for authorized user
         * @param {string} [searchText] 
         * @param {Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>} [status] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {string} [loanNumber] 
         * @param {string} [borrowerName] 
         * @param {string} [email] 
         * @param {string} [loanOfficerName] 
         * @param {string} [loanProcessorName] 
         * @param {string} [realOriginateLoanOfficerName] 
         * @param {string} [loanOfficerAssistantName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeadsAndLoansForAuthorizedUser(searchText?: string, status?: Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, loanNumber?: string, borrowerName?: string, email?: string, loanOfficerName?: string, loanProcessorName?: string, realOriginateLoanOfficerName?: string, loanOfficerAssistantName?: string, page?: number, size?: number, sortBy?: Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLeadLoanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeadsAndLoansForAuthorizedUser(searchText, status, state, loanNumber, borrowerName, email, loanOfficerName, loanProcessorName, realOriginateLoanOfficerName, loanOfficerAssistantName, page, size, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Loan by Borrower ID
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanByBorrowerId(borrowerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanByBorrowerId(borrowerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Loan
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanByLoanId(loanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanByLoanId(loanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Loan data
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanDataById(loanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanDataById(loanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove an authorized user from a loan
         * @param {string} loanId 
         * @param {string} authorizedPersonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAuthorizedPersonFromLoan(loanId: string, authorizedPersonId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeAuthorizedPersonFromLoan(loanId, authorizedPersonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a loan officer assistant from a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerAssistantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLoanOfficerAssistantFromLoan(loanId: string, loanOfficerAssistantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeLoanOfficerAssistantFromLoan(loanId, loanOfficerAssistantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a loan processor from a given loan
         * @param {string} loanId 
         * @param {string} loanProcessorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeLoanProcessorToLoan(loanId: string, loanProcessorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeLoanProcessorToLoan(loanId, loanProcessorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a real originate mortgage advisor from a given loan
         * @param {string} loanId 
         * @param {string} realOriginateMortgageAdvisorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRealOriginateLoanOfficerFromLoan(loanId: string, realOriginateMortgageAdvisorId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRealOriginateLoanOfficerFromLoan(loanId, realOriginateMortgageAdvisorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent of a given loan
         * @param {string} loanId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(loanId: string, agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgent(loanId, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update loan officer of a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoanOfficer(loanId: string, loanOfficerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoanOfficer(loanId, loanOfficerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update status of a given loan
         * @param {string} loanId 
         * @param {'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED'} loanStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoanStatus(loanId: string, loanStatus: 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoanStatus(loanId, loanStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads the pre-approval letter for the loan
         * @param {string} loanId 
         * @param {File} letter 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPreApprovalLetter(loanId: string, letter: File, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPreApprovalLetter(loanId, letter, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoansApi - factory interface
 * @export
 */
export const LoansApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoansApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new checklist question
         * @param {CreateChecklistFileUploadQuestion} createChecklistFileUploadQuestion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChecklistQuestion(createChecklistFileUploadQuestion: CreateChecklistFileUploadQuestion, options?: any): AxiosPromise<string> {
            return localVarFp.addChecklistQuestion(createChecklistFileUploadQuestion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply for a new Loan
         * @param {ApplyLoan} applyLoan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyForLoan(applyLoan: ApplyLoan, options?: any): AxiosPromise<LoanDto> {
            return localVarFp.applyForLoan(applyLoan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign an authorized user to a loan
         * @param {string} loanId 
         * @param {AuthorizedPersonIdRequest} authorizedPersonIdRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignAuthorizedPersonToLoan(loanId: string, authorizedPersonIdRequest: AuthorizedPersonIdRequest, options?: any): AxiosPromise<void> {
            return localVarFp.assignAuthorizedPersonToLoan(loanId, authorizedPersonIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a loan officer assistant of a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerAssistantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLoanOfficerAssistantToLoan(loanId: string, loanOfficerAssistantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignLoanOfficerAssistantToLoan(loanId, loanOfficerAssistantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a loan processor of a given loan
         * @param {string} loanId 
         * @param {string} loanProcessorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignLoanProcessorToLoan(loanId: string, loanProcessorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignLoanProcessorToLoan(loanId, loanProcessorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a real originate mortgage advisor of a given loan
         * @param {string} loanId 
         * @param {string} realOriginateMortgageAdvisorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRealOriginateLoanOfficerToLoan(loanId: string, realOriginateMortgageAdvisorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignRealOriginateLoanOfficerToLoan(loanId, realOriginateMortgageAdvisorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the loan data.)
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedUsersForLoan(loanId: string, options?: any): AxiosPromise<UserIds> {
            return localVarFp.getAllAuthorizedUsersForLoan(loanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get leads and loans for agent
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getLeadsAndLoansForAgent(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLeadLoanDto> {
            return localVarFp.getLeadsAndLoansForAgent(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get leads and loans for authorized user
         * @param {string} [searchText] 
         * @param {Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>} [status] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {string} [loanNumber] 
         * @param {string} [borrowerName] 
         * @param {string} [email] 
         * @param {string} [loanOfficerName] 
         * @param {string} [loanProcessorName] 
         * @param {string} [realOriginateLoanOfficerName] 
         * @param {string} [loanOfficerAssistantName] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadsAndLoansForAuthorizedUser(searchText?: string, status?: Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, loanNumber?: string, borrowerName?: string, email?: string, loanOfficerName?: string, loanProcessorName?: string, realOriginateLoanOfficerName?: string, loanOfficerAssistantName?: string, page?: number, size?: number, sortBy?: Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<PageLeadLoanDto> {
            return localVarFp.getLeadsAndLoansForAuthorizedUser(searchText, status, state, loanNumber, borrowerName, email, loanOfficerName, loanProcessorName, realOriginateLoanOfficerName, loanOfficerAssistantName, page, size, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Loan by Borrower ID
         * @param {string} borrowerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanByBorrowerId(borrowerId: string, options?: any): AxiosPromise<LoanDto> {
            return localVarFp.getLoanByBorrowerId(borrowerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Loan
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanByLoanId(loanId: string, options?: any): AxiosPromise<LoanDto> {
            return localVarFp.getLoanByLoanId(loanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Loan data
         * @param {string} loanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanDataById(loanId: string, options?: any): AxiosPromise<LoanDataDto> {
            return localVarFp.getLoanDataById(loanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an authorized user from a loan
         * @param {string} loanId 
         * @param {string} authorizedPersonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAuthorizedPersonFromLoan(loanId: string, authorizedPersonId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeAuthorizedPersonFromLoan(loanId, authorizedPersonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a loan officer assistant from a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerAssistantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLoanOfficerAssistantFromLoan(loanId: string, loanOfficerAssistantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeLoanOfficerAssistantFromLoan(loanId, loanOfficerAssistantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a loan processor from a given loan
         * @param {string} loanId 
         * @param {string} loanProcessorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeLoanProcessorToLoan(loanId: string, loanProcessorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeLoanProcessorToLoan(loanId, loanProcessorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a real originate mortgage advisor from a given loan
         * @param {string} loanId 
         * @param {string} realOriginateMortgageAdvisorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRealOriginateLoanOfficerFromLoan(loanId: string, realOriginateMortgageAdvisorId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeRealOriginateLoanOfficerFromLoan(loanId, realOriginateMortgageAdvisorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent of a given loan
         * @param {string} loanId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(loanId: string, agentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateAgent(loanId, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan officer of a given loan
         * @param {string} loanId 
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanOfficer(loanId: string, loanOfficerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateLoanOfficer(loanId, loanOfficerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update status of a given loan
         * @param {string} loanId 
         * @param {'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED'} loanStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanStatus(loanId: string, loanStatus: 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED', options?: any): AxiosPromise<void> {
            return localVarFp.updateLoanStatus(loanId, loanStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the pre-approval letter for the loan
         * @param {string} loanId 
         * @param {File} letter 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPreApprovalLetter(loanId: string, letter: File, description?: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadPreApprovalLetter(loanId, letter, description, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoansApi - object-oriented interface
 * @export
 * @class LoansApi
 * @extends {BaseAPI}
 */
export class LoansApi extends BaseAPI {
    /**
     * 
     * @summary Create a new checklist question
     * @param {CreateChecklistFileUploadQuestion} createChecklistFileUploadQuestion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public addChecklistQuestion(createChecklistFileUploadQuestion: CreateChecklistFileUploadQuestion, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).addChecklistQuestion(createChecklistFileUploadQuestion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply for a new Loan
     * @param {ApplyLoan} applyLoan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public applyForLoan(applyLoan: ApplyLoan, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).applyForLoan(applyLoan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign an authorized user to a loan
     * @param {string} loanId 
     * @param {AuthorizedPersonIdRequest} authorizedPersonIdRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public assignAuthorizedPersonToLoan(loanId: string, authorizedPersonIdRequest: AuthorizedPersonIdRequest, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).assignAuthorizedPersonToLoan(loanId, authorizedPersonIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a loan officer assistant of a given loan
     * @param {string} loanId 
     * @param {string} loanOfficerAssistantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public assignLoanOfficerAssistantToLoan(loanId: string, loanOfficerAssistantId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).assignLoanOfficerAssistantToLoan(loanId, loanOfficerAssistantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a loan processor of a given loan
     * @param {string} loanId 
     * @param {string} loanProcessorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public assignLoanProcessorToLoan(loanId: string, loanProcessorId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).assignLoanProcessorToLoan(loanId, loanProcessorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a real originate mortgage advisor of a given loan
     * @param {string} loanId 
     * @param {string} realOriginateMortgageAdvisorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public assignRealOriginateLoanOfficerToLoan(loanId: string, realOriginateMortgageAdvisorId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).assignRealOriginateLoanOfficerToLoan(loanId, realOriginateMortgageAdvisorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user ids of all users that can access the loan data.)
     * @param {string} loanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public getAllAuthorizedUsersForLoan(loanId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).getAllAuthorizedUsersForLoan(loanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get leads and loans for agent
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public getLeadsAndLoansForAgent(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).getLeadsAndLoansForAgent(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get leads and loans for authorized user
     * @param {string} [searchText] 
     * @param {Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>} [status] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
     * @param {string} [loanNumber] 
     * @param {string} [borrowerName] 
     * @param {string} [email] 
     * @param {string} [loanOfficerName] 
     * @param {string} [loanProcessorName] 
     * @param {string} [realOriginateLoanOfficerName] 
     * @param {string} [loanOfficerAssistantName] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public getLeadsAndLoansForAuthorizedUser(searchText?: string, status?: Array<'CREATED' | 'INVITATION_SENT' | 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING'>, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, loanNumber?: string, borrowerName?: string, email?: string, loanOfficerName?: string, loanProcessorName?: string, realOriginateLoanOfficerName?: string, loanOfficerAssistantName?: string, page?: number, size?: number, sortBy?: Array<'CREATED_AT' | 'BORROWER_FIRST_NAME' | 'BORROWER_LAST_NAME' | 'STATUS' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).getLeadsAndLoansForAuthorizedUser(searchText, status, state, loanNumber, borrowerName, email, loanOfficerName, loanProcessorName, realOriginateLoanOfficerName, loanOfficerAssistantName, page, size, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Loan by Borrower ID
     * @param {string} borrowerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public getLoanByBorrowerId(borrowerId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).getLoanByBorrowerId(borrowerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Loan
     * @param {string} loanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public getLoanByLoanId(loanId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).getLoanByLoanId(loanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Loan data
     * @param {string} loanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public getLoanDataById(loanId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).getLoanDataById(loanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an authorized user from a loan
     * @param {string} loanId 
     * @param {string} authorizedPersonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public removeAuthorizedPersonFromLoan(loanId: string, authorizedPersonId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).removeAuthorizedPersonFromLoan(loanId, authorizedPersonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a loan officer assistant from a given loan
     * @param {string} loanId 
     * @param {string} loanOfficerAssistantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public removeLoanOfficerAssistantFromLoan(loanId: string, loanOfficerAssistantId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).removeLoanOfficerAssistantFromLoan(loanId, loanOfficerAssistantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a loan processor from a given loan
     * @param {string} loanId 
     * @param {string} loanProcessorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public removeLoanProcessorToLoan(loanId: string, loanProcessorId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).removeLoanProcessorToLoan(loanId, loanProcessorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a real originate mortgage advisor from a given loan
     * @param {string} loanId 
     * @param {string} realOriginateMortgageAdvisorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public removeRealOriginateLoanOfficerFromLoan(loanId: string, realOriginateMortgageAdvisorId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).removeRealOriginateLoanOfficerFromLoan(loanId, realOriginateMortgageAdvisorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent of a given loan
     * @param {string} loanId 
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public updateAgent(loanId: string, agentId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).updateAgent(loanId, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan officer of a given loan
     * @param {string} loanId 
     * @param {string} loanOfficerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public updateLoanOfficer(loanId: string, loanOfficerId: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).updateLoanOfficer(loanId, loanOfficerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update status of a given loan
     * @param {string} loanId 
     * @param {'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED'} loanStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public updateLoanStatus(loanId: string, loanStatus: 'ACCOUNT_CREATED' | 'APPLICATION_IN_PROGRESS' | 'APPLICATION_SUBMITTED' | 'LOAN_PRE_APPROVED' | 'SUBMITTED_TO_LENDER' | 'UNDERWRITING' | 'CONDITIONAL_APPROVAL' | 'CLEAR_TO_CLOSE' | 'CLOSING' | 'FUNDING' | 'TERMINATED', options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).updateLoanStatus(loanId, loanStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the pre-approval letter for the loan
     * @param {string} loanId 
     * @param {File} letter 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansApi
     */
    public uploadPreApprovalLetter(loanId: string, letter: File, description?: string, options?: AxiosRequestConfig) {
        return LoansApiFp(this.configuration).uploadPreApprovalLetter(loanId, letter, description, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoansAuthorizedUsersApi - axios parameter creator
 * @export
 */
export const LoansAuthorizedUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all authorized users
         * @param {Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedPersons: async (type?: Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/authorized-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type) {
                localVarQueryParameter['type'] = Array.from(type);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get authorized user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedPerson: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAuthorizedPerson', 'id', id)
            const localVarPath = `/api/v1/authorized-users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get authorized user by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLAuthorizedPersonBySlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getLAuthorizedPersonBySlug', 'slug', slug)
            const localVarPath = `/api/v1/authorized-users/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update authorized user
         * @param {string} authorizedPersonId 
         * @param {PatchAuthorizedPersonRequest} patchAuthorizedPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAuthorizedPerson: async (authorizedPersonId: string, patchAuthorizedPersonRequest: PatchAuthorizedPersonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizedPersonId' is not null or undefined
            assertParamExists('patchAuthorizedPerson', 'authorizedPersonId', authorizedPersonId)
            // verify required parameter 'patchAuthorizedPersonRequest' is not null or undefined
            assertParamExists('patchAuthorizedPerson', 'patchAuthorizedPersonRequest', patchAuthorizedPersonRequest)
            const localVarPath = `/api/v1/authorized-users/{authorizedPersonId}`
                .replace(`{${"authorizedPersonId"}}`, encodeURIComponent(String(authorizedPersonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAuthorizedPersonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up an authorized user
         * @param {RegisterAuthorizedPerson} registerAuthorizedPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerAuthorizedPerson: RegisterAuthorizedPerson, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerAuthorizedPerson' is not null or undefined
            assertParamExists('register', 'registerAuthorizedPerson', registerAuthorizedPerson)
            const localVarPath = `/api/v1/authorized-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerAuthorizedPerson, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoansAuthorizedUsersApi - functional programming interface
 * @export
 */
export const LoansAuthorizedUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoansAuthorizedUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all authorized users
         * @param {Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuthorizedPersons(type?: Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorizedPersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuthorizedPersons(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get authorized user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizedPerson(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedPersonDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizedPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get authorized user by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLAuthorizedPersonBySlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedPersonDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLAuthorizedPersonBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update authorized user
         * @param {string} authorizedPersonId 
         * @param {PatchAuthorizedPersonRequest} patchAuthorizedPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAuthorizedPerson(authorizedPersonId: string, patchAuthorizedPersonRequest: PatchAuthorizedPersonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedPersonDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAuthorizedPerson(authorizedPersonId, patchAuthorizedPersonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up an authorized user
         * @param {RegisterAuthorizedPerson} registerAuthorizedPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerAuthorizedPerson: RegisterAuthorizedPerson, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedPersonDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerAuthorizedPerson, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoansAuthorizedUsersApi - factory interface
 * @export
 */
export const LoansAuthorizedUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoansAuthorizedUsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all authorized users
         * @param {Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedPersons(type?: Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>, options?: any): AxiosPromise<Array<AuthorizedPersonDto>> {
            return localVarFp.getAllAuthorizedPersons(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get authorized user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedPerson(id: string, options?: any): AxiosPromise<AuthorizedPersonDetailDto> {
            return localVarFp.getAuthorizedPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get authorized user by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLAuthorizedPersonBySlug(slug: string, options?: any): AxiosPromise<AuthorizedPersonDetailDto> {
            return localVarFp.getLAuthorizedPersonBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update authorized user
         * @param {string} authorizedPersonId 
         * @param {PatchAuthorizedPersonRequest} patchAuthorizedPersonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAuthorizedPerson(authorizedPersonId: string, patchAuthorizedPersonRequest: PatchAuthorizedPersonRequest, options?: any): AxiosPromise<AuthorizedPersonDetailDto> {
            return localVarFp.patchAuthorizedPerson(authorizedPersonId, patchAuthorizedPersonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up an authorized user
         * @param {RegisterAuthorizedPerson} registerAuthorizedPerson 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerAuthorizedPerson: RegisterAuthorizedPerson, options?: any): AxiosPromise<AuthorizedPersonDetailDto> {
            return localVarFp.register(registerAuthorizedPerson, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoansAuthorizedUsersApi - object-oriented interface
 * @export
 * @class LoansAuthorizedUsersApi
 * @extends {BaseAPI}
 */
export class LoansAuthorizedUsersApi extends BaseAPI {
    /**
     * 
     * @summary Get all authorized users
     * @param {Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansAuthorizedUsersApi
     */
    public getAllAuthorizedPersons(type?: Array<'LOAN_OFFICER' | 'LOAN_OFFICER_ASSISTANT' | 'LOAN_PROCESSOR' | 'REAL_ORIGINATE_LOAN_OFFICER'>, options?: AxiosRequestConfig) {
        return LoansAuthorizedUsersApiFp(this.configuration).getAllAuthorizedPersons(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get authorized user by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansAuthorizedUsersApi
     */
    public getAuthorizedPerson(id: string, options?: AxiosRequestConfig) {
        return LoansAuthorizedUsersApiFp(this.configuration).getAuthorizedPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get authorized user by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansAuthorizedUsersApi
     */
    public getLAuthorizedPersonBySlug(slug: string, options?: AxiosRequestConfig) {
        return LoansAuthorizedUsersApiFp(this.configuration).getLAuthorizedPersonBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update authorized user
     * @param {string} authorizedPersonId 
     * @param {PatchAuthorizedPersonRequest} patchAuthorizedPersonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansAuthorizedUsersApi
     */
    public patchAuthorizedPerson(authorizedPersonId: string, patchAuthorizedPersonRequest: PatchAuthorizedPersonRequest, options?: AxiosRequestConfig) {
        return LoansAuthorizedUsersApiFp(this.configuration).patchAuthorizedPerson(authorizedPersonId, patchAuthorizedPersonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up an authorized user
     * @param {RegisterAuthorizedPerson} registerAuthorizedPerson 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoansAuthorizedUsersApi
     */
    public register(registerAuthorizedPerson: RegisterAuthorizedPerson, options?: AxiosRequestConfig) {
        return LoansAuthorizedUsersApiFp(this.configuration).register(registerAuthorizedPerson, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MortgageLeadsApi - axios parameter creator
 * @export
 */
export const MortgageLeadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new mortgage lead
         * @param {CreateLead} createLead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMortgageLead: async (createLead: CreateLead, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLead' is not null or undefined
            assertParamExists('createMortgageLead', 'createLead', createLead)
            const localVarPath = `/api/v1/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLead, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the lead data.)
         * @param {string} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedUsersForLead: async (leadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadId' is not null or undefined
            assertParamExists('getAllAuthorizedUsersForLead', 'leadId', leadId)
            const localVarPath = `/api/v1/leads/{leadId}/all-authorized-users`
                .replace(`{${"leadId"}}`, encodeURIComponent(String(leadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get mortgage lead by id
         * @param {string} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMortgageLeadById: async (leadId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadId' is not null or undefined
            assertParamExists('getMortgageLeadById', 'leadId', leadId)
            const localVarPath = `/api/v1/leads/{leadId}`
                .replace(`{${"leadId"}}`, encodeURIComponent(String(leadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan officer of a given lead
         * @param {string} leadId 
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanOfficer1: async (leadId: string, loanOfficerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'leadId' is not null or undefined
            assertParamExists('updateLoanOfficer1', 'leadId', leadId)
            // verify required parameter 'loanOfficerId' is not null or undefined
            assertParamExists('updateLoanOfficer1', 'loanOfficerId', loanOfficerId)
            const localVarPath = `/api/v1/leads/{leadId}/loan-officer/{loanOfficerId}`
                .replace(`{${"leadId"}}`, encodeURIComponent(String(leadId)))
                .replace(`{${"loanOfficerId"}}`, encodeURIComponent(String(loanOfficerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortgageLeadsApi - functional programming interface
 * @export
 */
export const MortgageLeadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortgageLeadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new mortgage lead
         * @param {CreateLead} createLead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMortgageLead(createLead: CreateLead, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMortgageLead(createLead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the lead data.)
         * @param {string} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuthorizedUsersForLead(leadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIds>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuthorizedUsersForLead(leadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get mortgage lead by id
         * @param {string} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMortgageLeadById(leadId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMortgageLeadById(leadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update loan officer of a given lead
         * @param {string} leadId 
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoanOfficer1(leadId: string, loanOfficerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoanOfficer1(leadId, loanOfficerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortgageLeadsApi - factory interface
 * @export
 */
export const MortgageLeadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortgageLeadsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new mortgage lead
         * @param {CreateLead} createLead 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMortgageLead(createLead: CreateLead, options?: any): AxiosPromise<LeadDto> {
            return localVarFp.createMortgageLead(createLead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the user ids of all users that can access the lead data.)
         * @param {string} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorizedUsersForLead(leadId: string, options?: any): AxiosPromise<UserIds> {
            return localVarFp.getAllAuthorizedUsersForLead(leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get mortgage lead by id
         * @param {string} leadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMortgageLeadById(leadId: string, options?: any): AxiosPromise<LeadDto> {
            return localVarFp.getMortgageLeadById(leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan officer of a given lead
         * @param {string} leadId 
         * @param {string} loanOfficerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoanOfficer1(leadId: string, loanOfficerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateLoanOfficer1(leadId, loanOfficerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortgageLeadsApi - object-oriented interface
 * @export
 * @class MortgageLeadsApi
 * @extends {BaseAPI}
 */
export class MortgageLeadsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new mortgage lead
     * @param {CreateLead} createLead 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageLeadsApi
     */
    public createMortgageLead(createLead: CreateLead, options?: AxiosRequestConfig) {
        return MortgageLeadsApiFp(this.configuration).createMortgageLead(createLead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the user ids of all users that can access the lead data.)
     * @param {string} leadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageLeadsApi
     */
    public getAllAuthorizedUsersForLead(leadId: string, options?: AxiosRequestConfig) {
        return MortgageLeadsApiFp(this.configuration).getAllAuthorizedUsersForLead(leadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get mortgage lead by id
     * @param {string} leadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageLeadsApi
     */
    public getMortgageLeadById(leadId: string, options?: AxiosRequestConfig) {
        return MortgageLeadsApiFp(this.configuration).getMortgageLeadById(leadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan officer of a given lead
     * @param {string} leadId 
     * @param {string} loanOfficerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageLeadsApi
     */
    public updateLoanOfficer1(leadId: string, loanOfficerId: string, options?: AxiosRequestConfig) {
        return MortgageLeadsApiFp(this.configuration).updateLoanOfficer1(leadId, loanOfficerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MortgageServicesApi - axios parameter creator
 * @export
 */
export const MortgageServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMortgageServiceAvailabilities: async (state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('getMortgageServiceAvailabilities', 'state', state)
            const localVarPath = `/api/v1/mortgages/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state) {
                localVarQueryParameter['state'] = Array.from(state);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortgageServicesApi - functional programming interface
 * @export
 */
export const MortgageServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortgageServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMortgageServiceAvailabilities(state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortgageServiceAvailabilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMortgageServiceAvailabilities(state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortgageServicesApi - factory interface
 * @export
 */
export const MortgageServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortgageServicesApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMortgageServiceAvailabilities(state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: any): AxiosPromise<MortgageServiceAvailabilityDto> {
            return localVarFp.getMortgageServiceAvailabilities(state, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortgageServicesApi - object-oriented interface
 * @export
 * @class MortgageServicesApi
 * @extends {BaseAPI}
 */
export class MortgageServicesApi extends BaseAPI {
    /**
     * 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} state 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageServicesApi
     */
    public getMortgageServiceAvailabilities(state: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig) {
        return MortgageServicesApiFp(this.configuration).getMortgageServiceAvailabilities(state, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get info for current user e.g. borrower or loan-officer
         * @param {string} [leadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (leadId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get info for current user e.g. borrower or loan-officer
         * @param {string} [leadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(leadId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanParticipantDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(leadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get info for current user e.g. borrower or loan-officer
         * @param {string} [leadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(leadId?: string, options?: any): AxiosPromise<LoanParticipantDto> {
            return localVarFp.getMe(leadId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get info for current user e.g. borrower or loan-officer
     * @param {string} [leadId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(leadId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getMe(leadId, options).then((request) => request(this.axios, this.basePath));
    }
}


