export const getFullName = <
  T extends { firstName?: string; lastName?: string },
>(
  user: T,
): string => {
  if (!user) {
    return 'N/A';
  }

  return `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
};

export const getLoanOfficerFullName = <
  T extends { firstName?: string; lastName?: string },
>(
  user: T,
  loanOfficer: T,
): string => {
  if (user?.firstName === 'System' && user?.lastName === 'User') {
    return getFullName(loanOfficer);
  }

  return getFullName(user);
};
