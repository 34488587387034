import React, { useMemo } from 'react';

const MOBILE_MAX_WIDTH = 768;
const TABLET_MAX_WIDTH = 1024;

const useDeviceType = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const deviceType = useMemo(
    () => ({
      isMobile: windowWidth <= MOBILE_MAX_WIDTH,
      isTablet:
        windowWidth > MOBILE_MAX_WIDTH && windowWidth <= TABLET_MAX_WIDTH,
      isDesktop: windowWidth > TABLET_MAX_WIDTH,
    }),
    [windowWidth],
  );

  return deviceType;
};

export default useDeviceType;
