import {
  faArrowRightFromBracket,
  faFolder,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import RealLogoOriginal from '../../assets/img/logo.svg';
import { signOutUser } from '../../slices/AuthSlice';
import { useAppDispatch } from '../../slices/store';

type ConfigDrivenContainerLayoutMobileMenuProps = {
  toggleMenu: boolean;
  hideMyLoans: boolean;
  onClose: () => void;
};

const ConfigDrivenContainerLayoutMobileMenu: React.FC<
  ConfigDrivenContainerLayoutMobileMenuProps
> = ({ toggleMenu, hideMyLoans = false, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        'bg-white absolute top-0 h-48 rounded-b-3xl shadow-lg w-full p-4',
        toggleMenu ? 'block' : 'hidden',
      )}
    >
      <img
        src={RealLogoOriginal}
        alt='logo'
        width={80}
        height={20}
        className='absolute lg:top-10 top-5 md:left-10 left-5'
      />
      <div className='absolute top-5 right-5'>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onClose}
          fontSize={30}
          className='text-black'
        />
      </div>
      <div className='flex flex-col space-y-8 justify-center h-full items-start pt-14 pl-5'>
        {!hideMyLoans && (
          <button
            className='flex flex-row items-center'
            onClick={() => navigate('/loans')}
          >
            <FontAwesomeIcon icon={faFolder} fontSize={20} />
            <p className='font-inter-medium text-black text-xl ml-2'>
              My Loans
            </p>
          </button>
        )}
        <button
          className='flex flex-row items-center'
          onClick={async () => {
            await dispatch(signOutUser());
            navigate('/');
          }}
        >
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className='text-black'
            fontSize={20}
          />
          <p className='font-inter-medium text-black text-xl ml-2'>Logout</p>
        </button>
      </div>
    </div>
  );
};

export default ConfigDrivenContainerLayoutMobileMenu;
