import {
  faChevronRight,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoanBorrower } from '../../../hooks/useLoanBorrower';
import { LoanCardProps } from '../LoanCard';
import LoanStatus from '../LoanStatus';

const LoanCardReskin: React.FC<LoanCardProps> = ({ loan, onPress }) => {
  const borrower = useLoanBorrower(loan);
  const borrowerLoanStatus = borrower?.status!;

  return (
    <div
      onClick={() => onPress(loan)}
      className='bg-white cursor-pointer rounded-xl p-6 pt-4 border-[1.5px] border-reskin-platinum'
    >
      <div className='flex justify-between items-center pb-2 border-b border-reskin-platinum'>
        <div className='font-poppins-regular text-sm text-reskin-primary-gray font-normal'>
          {loan.loanType}
        </div>
        <LoanStatus status={borrowerLoanStatus} />
      </div>

      <div className='flex justify-between items-center mt-6'>
        {loan.propertyAddress ? (
          <div className='font-poppins-medium text-base'>
            {loan.propertyAddress}
          </div>
        ) : (
          <div className='flex flex-row items-center'>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size='lg'
              className='text-reskin-flint text-base font-normal'
            />
            <div className='text-reskin-primary-dark font-poppins-regular text-base font-normal ml-1'>
              The property address has not been entered yet.
            </div>
          </div>
        )}

        <div>
          <FontAwesomeIcon
            icon={faChevronRight}
            size='xl'
            className='text-reskin-mineshaft font-light'
          />
        </div>
      </div>
    </div>
  );
};

export default LoanCardReskin;
