import {
  faArrowRightFromBracket,
  faFolder,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOutUser } from '../../slices/AuthSlice';
import { useAppDispatch } from '../../slices/store';
import { cn } from '../../utils/classUtils';

interface ConfigDrivenContainerLayoutHeaderProps {
  hideMyLoans?: boolean;
}

const ConfigDrivenContainerLayoutHeader: FC<
  ConfigDrivenContainerLayoutHeaderProps
> = ({ hideMyLoans }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div className='lg:flex hidden flex-row p-4 bg-white border-b border-silver sticky top-0 right-0 z-50'>
      <div
        className={cn('flex flex-row flex-1 justify-between gap-x-5', {
          'flex-row-reverse': hideMyLoans,
        })}
      >
        {!hideMyLoans && (
          <button
            className='flex flex-row items-center'
            onClick={() => navigate('/loans')}
          >
            <FontAwesomeIcon icon={faFolder} size='lg' />
            <p className='font-inter-regular text-lg ml-2'>My Loans</p>
          </button>
        )}
        <button
          className='flex flex-row items-center'
          onClick={async () => {
            await dispatch(signOutUser());
            navigate('/');
          }}
        >
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className='text-black ml-4'
            size='lg'
          />
          <p className='font-inter-regular text-lg ml-2'>Logout</p>
        </button>
      </div>
    </div>
  );
};

export default ConfigDrivenContainerLayoutHeader;
