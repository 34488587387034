import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import BackgroundImagePlaceholder from '../../assets/img/holding-boxes.png';
import RealLogo from '../../assets/img/logo-white.svg';
import useDeviceType from '../../hooks/useDeviceType';
import { ApplicationStep } from '../../openapi/atlantis';
import { useAppSelector } from '../../slices/store';
import StepProgressContainer from '../StepProgress/StepProgressContainer';
import ConfigDrivenContainerLayoutChat from './ConfigDrivenContainerLayoutChat';
import ConfigDrivenContainerLayoutHeader from './ConfigDrivenContainerLayoutHeader';
import ConfigDrivenContainerLayoutMobileMenu from './ConfigDrivenContainerLayoutMobileMenu';

interface ConfigDrivenContainerLayoutProps {
  children: React.ReactNode;
  config?: ApplicationStep;
  onNavigateToQuestion?: (questionId: string) => void;
  hideMyLoans?: boolean;
  hideChat?: boolean;
}

const ConfigDrivenContainerLayout: React.FC<
  ConfigDrivenContainerLayoutProps
> = ({
  children,
  config,
  onNavigateToQuestion,
  hideMyLoans = false,
  hideChat = false,
}) => {
  const { isDesktop } = useDeviceType();
  const {
    loan: { toggleChat },
    application: { progress },
  } = useAppSelector((state) => state);
  const [isProgressExpand, setIsProgressExpand] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(toggleChat);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const backgroundImage =
    config?.content?.style?.backgroundImageDesktop ||
    BackgroundImagePlaceholder;

  useEffect(() => {
    // make body overflow hidden on mobile when chat is open
    // to prevent scrolling on body
    if (!isDesktop) {
      if (isChatOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isChatOpen, isDesktop]);

  return (
    <div className='grid lg:grid-flow-col lg:grid-cols-11 grid-cols-1'>
      {/* Left Sidebar */}
      <div className='relative lg:col-span-5 lg:h-screen lg:overflow-y-auto bg-reskin-primary-navy h-20'>
        <img
          src={RealLogo}
          alt='logo'
          width={!isDesktop ? 80 : 100}
          height={!isDesktop ? 20 : 34}
          className='absolute lg:top-10 top-8 md:left-10 left-5'
        />
        <img
          src={backgroundImage}
          alt='placeholder'
          className='lg:h-screen lg:block hidden object-cover h-52 w-full'
        />
        <FontAwesomeIcon
          icon={faBars}
          onClick={() => setToggleMenu(!toggleMenu)}
          fontSize={24}
          className='text-white absolute top-8 md:right-10 right-5 lg:hidden block'
        />

        {/* Desktop Step Progress  */}
        {onNavigateToQuestion && (
          <div className='lg:block hidden'>
            <StepProgressContainer
              progress={progress!}
              isProgressExpand={isProgressExpand}
              setIsProgressExpand={setIsProgressExpand}
              onNavigateToQuestion={onNavigateToQuestion}
            />
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className='lg:col-span-6 lg:h-screen lg:overflow-y-auto'>
        <ConfigDrivenContainerLayoutHeader hideMyLoans={hideMyLoans} />
        <div
          className={classNames(
            'flex items-center justify-center lg:p-8 px-4 pt-10 lg:pb-40',
            { 'pb-40': !isProgressExpand },
          )}
        >
          <div className='lg:w-4/6 w-full'>{children}</div>
        </div>
      </div>

      {/* Chat */}
      {!hideChat && (
        <ConfigDrivenContainerLayoutChat
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
          isStepProgress={!!onNavigateToQuestion}
        />
      )}

      {/* Mobile Step Progress  */}
      {onNavigateToQuestion && !isChatOpen && (
        <div className='lg:hidden block'>
          <StepProgressContainer
            progress={progress!}
            isProgressExpand={isProgressExpand}
            setIsProgressExpand={setIsProgressExpand}
            onNavigateToQuestion={onNavigateToQuestion}
          />
        </div>
      )}
      {/* Mobile Menu */}
      <ConfigDrivenContainerLayoutMobileMenu
        toggleMenu={toggleMenu}
        hideMyLoans={hideMyLoans}
        onClose={() => setToggleMenu(false)}
      />
    </div>
  );
};

export default ConfigDrivenContainerLayout;
