import React from 'react';
import { useAppSelector } from '../../slices/store';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { cn } from '../../utils/classUtils';
import Step, { StepVariant } from '../StepProgress/Step';

type ConfigDrivenContainerLayoutRoadToSuccessProps = {
  variant?: StepVariant;
};

export const variantToStepNameColorMap: EnumMap<StepVariant, string> = {
  [StepVariant.Default]: 'text-white',
  [StepVariant.Aqua]: 'text-[#282826]',
  [StepVariant.ReskinAqua]: 'text-white',
};

export const variantToStepDescriptionColorMap: EnumMap<StepVariant, string> = {
  [StepVariant.Default]: 'text-white',
  [StepVariant.Aqua]: 'text-reskin-flint',
  [StepVariant.ReskinAqua]: 'text-white',
};

const ConfigDrivenContainerLayoutRoadToSuccess: React.FC<
  ConfigDrivenContainerLayoutRoadToSuccessProps
> = ({ variant = StepVariant.Default }) => {
  const {
    application: { loanProgress },
  } = useAppSelector((state) => state);

  const steps = loanProgress?.items || [];

  const stepNameColor = safeEnumMapGet(
    variantToStepNameColorMap,
    variant,
    StepVariant.Default,
  );

  const stepDescriptionColor = safeEnumMapGet(
    variantToStepDescriptionColorMap,
    variant,
    StepVariant.Default,
  );

  return (
    <div className='p-0 lg:p-32 block'>
      <p className='font-poppins-medium text-[40px] leading-[48px] lg:text-white text-reskin-primary-dark mb-10'>
        Road to success
      </p>
      {steps.map((step, index) => {
        const isLastStep = index === steps.length - 1;
        const isStepCompleted = !!step.complete;

        return (
          <div key={[index].toString()} className='flex flex-row'>
            <div className='flex flex-col items-center'>
              <Step
                key={[index].toString()}
                inProgressPercentage={10}
                isFinal={isLastStep}
                isCompleted={isStepCompleted}
                isInProgress={false}
                isVertical
                variant={variant}
              />
            </div>

            <div className='flex flex-col pl-4'>
              <div className='flex flex-row items-center justify-between mb-2'>
                <div className='flex flex-row items-center'>
                  <p
                    className={cn(
                      'font-inter-regular text-base lg:font-poppins-regular lg:text-2xl',
                      stepNameColor,
                    )}
                  >
                    {step.name}
                  </p>
                </div>
              </div>
              <div className='flex'>
                <p
                  className={cn(
                    'font-inter-light text:sm lg:text-base mb-6',
                    stepDescriptionColor,
                  )}
                >
                  {step.description}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ConfigDrivenContainerLayoutRoadToSuccess;
