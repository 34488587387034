/**
 * Represents an Axios error that is forced to be reported.
 */
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * Represents an Axios error with force reporting capability.
 */
export default class ForceReportingAxiosError implements AxiosError {
  /**
   * The Axios request configuration.
   */
  config: AxiosRequestConfig;

  /**
   * The error code associated with the request.
   */
  code?: string | undefined;

  /**
   * The request object.
   */
  request?: any;

  /**
   * The response object.
   */
  response?: AxiosResponse<any> | undefined;

  /**
   * Indicates whether the error is an Axios error.
   */
  isAxiosError: boolean;

  /**
   * Returns a JSON representation of the error.
   */
  toJSON: () => object;

  /**
   * The name of the error.
   */
  name: string;

  /**
   * The error message.
   */
  message: string;

  /**
   * The stack trace of the error.
   */
  stack?: string | undefined;

  /**
   * The cause of the error.
   */
  cause?: unknown;

  /**
   * Constructs a new instance of the ForceReportingAxiosError class.
   * @param e - The original Axios error object.
   * @throws Error - Throws an error if the provided object is not an AxiosError.
   */
  constructor(e: any) {
    if (!e.isAxiosError) {
      throw new Error('This is not an AxiosError');
    }

    this.name = e.name;
    this.stack = e.stack;
    this.message = e.message;
    this.config = e.config;
    this.code = e.code;
    this.request = e.request;
    this.response = e.response;
    this.isAxiosError = e.isAxiosError;
    this.toJSON = e.toJSON;
    this.cause = e.cause;

    // Force reporting the error
    this.response!.data = {
      'com.real.commons.apierror.ApiError': {
        ...e.response.data['com.real.commons.apierror.ApiError'],
        reportable: true,
      },
    };
  }
}
