import { useForm } from 'react-hook-form';
import SimpleModal from './SimpleModal.tsx';
import ControlledConsentInput from './inputs/ControlledConsentInput.tsx';
import Button from './Button.tsx';

type NewApplicationModalProps = {
  isOpen: boolean;
  onClose(): void;
  handleStartApplication(): void;
};

export const NewApplicationConfirmationModal = ({
  isOpen,
  onClose,
  handleStartApplication,
}: NewApplicationModalProps) => {
  const { control, handleSubmit, formState } = useForm({});

  return (
    <SimpleModal
      size='large'
      isOpen={isOpen}
      onClose={onClose}
      closeIconColor='text-reskin-silver-400'
    >
      <div className='flex justify-center flex-col gap-10'>
        <div className='text-center px-14'>
          <h2 className='pb-4 font-poppins-medium text-2xl'>
            Ready for a new loan?
          </h2>
          <p className='text-base font-inter-light text-reskin-primary-dark'>
            You are about to start an application for a new loan that will be
            separate from any existing or previous loans. Are you sure you want
            to apply for an additional loan?
          </p>

          <div className='px-14 flex justify-center mt-6'>
            <ControlledConsentInput
              name='startapplication'
              control={control}
              labelComponent={
                <p className=' text-primary-graphite'>
                  Yes, I want to apply for a new loan
                </p>
              }
              rules={{
                required: 'Please provide your consent to continue',
              }}
            />
          </div>
        </div>

        <div className='flex justify-center pb-10 px-14'>
          <Button
            fullWidth
            label='Start Application'
            variant='primary'
            gradientVariant='mintyfresh'
            onPress={handleSubmit(() => {
              handleStartApplication();
            })}
            isDisabled={!formState.isValid}
          />
        </div>
      </div>
    </SimpleModal>
  );
};
