import {
  faChevronRight,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLoanBorrower } from '../../../hooks/useLoanBorrower.ts';
import { BorrowerDtoStatusEnum } from '../../../openapi/atlantis';
import { EnumMap } from '../../../types.ts';
import { cn } from '../../../utils/classUtils.ts';
import { LoanCardProps } from '../LoanCard';
import LoanStatus from '../LoanStatus';

const variantToClassNameMap: EnumMap<BorrowerDtoStatusEnum, string> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'border-r-[#4967FD]',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'border-r-[#C98B11]',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'border-r-[#7440CD]',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'border-r-[#439775]',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'border-r-[#4967FD]',
  [BorrowerDtoStatusEnum.Underwriting]: 'border-r-[#C98B11]',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'border-r-[#C98B11]',
  [BorrowerDtoStatusEnum.ClearToClose]: 'border-r-[#7440CD]',
  [BorrowerDtoStatusEnum.Closing]: 'border-r-[#439775]',
  [BorrowerDtoStatusEnum.Funding]: 'border-r-[#439775]',
  [BorrowerDtoStatusEnum.Terminated]: 'border-r-[#F84C6C]',
};

const LoanCardGemini: React.FC<LoanCardProps> = ({ loan, onPress }) => {
  const borrower = useLoanBorrower(loan);
  const borrowerLoanStatus = borrower?.status!;

  return (
    <div
      onClick={() => onPress(loan)}
      className='relative bg-white cursor-pointer rounded-xl p-5 border border-silver'
    >
      <div
        className={cn(
          'absolute top-1/2 left-0 h-1/2 -translate-y-1/2 border-r-4 rounded-r-full',
          variantToClassNameMap[borrowerLoanStatus],
        )}
      />
      <div>
        <div className='flex justify-between items-center'>
          <div className='font-inter-semibold text-xs text-primary-gray font-bold'>
            {loan.loanType}
          </div>
          <div className='font-inter-medium text-sm'>
            <LoanStatus status={borrowerLoanStatus} />
          </div>
        </div>
        <div className='border-b my-2 border-silver' />
        <div className='flex justify-between items-center'>
          {loan.propertyAddress ? (
            <div className='font-inter-medium text-base'>
              {loan.propertyAddress}
            </div>
          ) : (
            <div className='flex flex-row items-center'>
              <FontAwesomeIcon icon={faCircleExclamation} size='lg' />
              <div className='font-inter-light text-base ml-2'>
                The property address has not been entered yet.
              </div>
            </div>
          )}
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanCardGemini;
