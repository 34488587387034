import {
  faCircleCheck,
  faDollarSign,
  faFileCheck,
  faFileImport,
  faHourglassHalf,
  faListCheck,
  faSignature,
  faStamp,
  faTimer,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { BorrowerDtoStatusEnum } from '../../../openapi/atlantis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { LoanStatusProps } from '../LoanStatus';

export const variantToClassNameMap: EnumMap<BorrowerDtoStatusEnum, string> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'text-[#4967FD]',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'text-[#C98B11]',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'text-[#7440CD]',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'text-[#439775]',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'text-[#4967FD]',
  [BorrowerDtoStatusEnum.Underwriting]: 'text-[#C98B11]',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'text-[#C98B11]',
  [BorrowerDtoStatusEnum.ClearToClose]: 'text-[#7440CD]',
  [BorrowerDtoStatusEnum.Closing]: 'text-[#439775]',
  [BorrowerDtoStatusEnum.Funding]: 'text-[#439775]',
  [BorrowerDtoStatusEnum.Terminated]: 'text-[#F84C6C]',
};

export const statusToName: EnumMap<BorrowerDtoStatusEnum, string> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'Created',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'In progress',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'Submitted',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'Pre-approved',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'Submitted to lender',
  [BorrowerDtoStatusEnum.Underwriting]: 'Underwriting',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'Conditional approval',
  [BorrowerDtoStatusEnum.ClearToClose]: 'Clear to close',
  [BorrowerDtoStatusEnum.Closing]: 'Closing',
  [BorrowerDtoStatusEnum.Funding]: 'Funding',
  [BorrowerDtoStatusEnum.Terminated]: 'Terminated',
};

const LoanStatusReskin: React.FC<LoanStatusProps> = ({ status }) => {
  const LoanStatusIconMap: EnumMap<BorrowerDtoStatusEnum, React.ReactElement> =
    {
      [BorrowerDtoStatusEnum.AccountCreated]: (
        <FontAwesomeIcon icon={faFileImport} size='sm' />
      ),
      [BorrowerDtoStatusEnum.ApplicationInProgress]: (
        <FontAwesomeIcon icon={faHourglassHalf} size='sm' />
      ),
      [BorrowerDtoStatusEnum.ApplicationSubmitted]: (
        <FontAwesomeIcon
          icon={faFileCheck}
          size='sm'
          className='text-[#7440CD]'
        />
      ),
      [BorrowerDtoStatusEnum.SubmittedToLender]: (
        <FontAwesomeIcon
          icon={faFileImport}
          size='sm'
          className='text-[#4967FD]'
        />
      ),
      [BorrowerDtoStatusEnum.Underwriting]: (
        <FontAwesomeIcon
          icon={faListCheck}
          size='sm'
          className='text-[#C98B11]'
        />
      ),
      [BorrowerDtoStatusEnum.LoanPreApproved]: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          size='sm'
          className='text-[#439775]'
        />
      ),
      [BorrowerDtoStatusEnum.ConditionalApproval]: (
        <FontAwesomeIcon icon={faStamp} size='sm' className='text-[#E26811]' />
      ),
      [BorrowerDtoStatusEnum.ClearToClose]: (
        <FontAwesomeIcon icon={faTimer} size='sm' className='text-[#7440CD]' />
      ),
      [BorrowerDtoStatusEnum.Closing]: (
        <FontAwesomeIcon
          icon={faSignature}
          size='sm'
          className='text-[#439775]'
        />
      ),
      [BorrowerDtoStatusEnum.Funding]: (
        <FontAwesomeIcon
          icon={faDollarSign}
          size='sm'
          className='text-[#439775]'
        />
      ),
      [BorrowerDtoStatusEnum.Terminated]: (
        <FontAwesomeIcon icon={faXmark} size='sm' className='text-[#F84C6C]' />
      ),
    };

  const statusText = safeEnumMapGet(
    statusToName,
    status,
    BorrowerDtoStatusEnum.AccountCreated,
  );

  const styles = safeEnumMapGet(
    variantToClassNameMap,
    status,
    BorrowerDtoStatusEnum.AccountCreated,
  );

  const icon = safeEnumMapGet(
    LoanStatusIconMap,
    status,
    LoanStatusIconMap[BorrowerDtoStatusEnum.AccountCreated],
  );

  return (
    <div className='flex items-center justify-between'>
      <div
        className={classNames(
          'w-max flex items-center space-x-1.5 flex-shrink-0 py-1.5 px-2 mr-1 rounded-full md:whitespace-nowrap',
          styles,
        )}
      >
        {icon}
        <span className='font-poppins-light text-base leading-h3'>
          {statusText}
        </span>
      </div>
    </div>
  );
};

export default LoanStatusReskin;
