import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { cn } from '../utils/classUtils';

type ReadMoreLessProps = {
  text: string;
  textLength?: number;
  textClassName?: string;
};

const ReadMoreLess: React.FC<ReadMoreLessProps> = ({
  text,
  textLength = 180,
  textClassName,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  if (text.length < textLength) {
    return (
      <p
        className={cn(
          'font-inter-light text-rezen-primary-dark text-base',
          textClassName,
        )}
      >
        {text}
      </p>
    );
  }

  return (
    <p
      className={cn('font-inter-light text-[#1B251C] text-base', textClassName)}
    >
      {showMore ? text : `${text.slice(0, textLength)}...`}
      <button
        onClick={() => setShowMore(!showMore)}
        className='inline font-poppins-medium text-sm items-center ml-2 text-reskin-primary-blue leading-6 gap-1'
      >
        Show {showMore ? 'less' : 'more'}
        <FontAwesomeIcon
          fontSize={14}
          icon={showMore ? faChevronUp : faChevronDown}
          color='#4967FD'
          className='ml-1'
        />
      </button>
    </p>
  );
};

export default ReadMoreLess;
