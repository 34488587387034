import classNames from 'classnames';
import { FC, ReactNode } from 'react';

interface ConfigDrivenTitleProps {
  title: string;
  textStyle?: string;
}

const ConfigDrivenTitle: FC<ConfigDrivenTitleProps> = ({
  title,
  textStyle,
}) => {
  const commonStyle = [
    'font-poppins-semibold lg:text-5xl md:text-4xl text-2xl text-black',
    textStyle,
  ];

  const formatText = (str: string): ReactNode[] => {
    const regex = /<(\w+)>(.*?)<\/\1>/g;
    const parts = str.split(regex);

    return parts.reduce((acc: ReactNode[], part, index) => {
      const isTagContent = index % 3 === 2;

      if (isTagContent) {
        const tagName = parts[index - 1];
        acc.push(
          <span
            key={[index].toString()}
            className={classNames([commonStyle, `text-primary-${tagName}`])}
          >
            {part}
          </span>,
        );
      }

      if (index % 3 === 0) {
        acc.push(
          <span key={[index].toString()} className={classNames(commonStyle)}>
            {part}
          </span>,
        );
      }

      return acc;
    }, []);
  };

  return <span>{formatText(title)}</span>;
};

export default ConfigDrivenTitle;
