import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import RealLogo from '../../assets/img/logo-white.svg';
import useDeviceType from '../../hooks/useDeviceType';
import { useAppSelector } from '../../slices/store';
import { StepVariant } from '../StepProgress/Step';
import ConfigDrivenContainerLayoutChat from './ConfigDrivenContainerLayoutChat';
import ConfigDrivenContainerLayoutHeader from './ConfigDrivenContainerLayoutHeader';
import ConfigDrivenContainerLayoutMobileMenu from './ConfigDrivenContainerLayoutMobileMenu';
import ConfigDrivenContainerLayoutRoadToSuccess from './ConfigDrivenContainerLayoutRoadToSuccess';

interface ConfigDrivenChecklistContainerLayoutProps {
  children: React.ReactNode;
  BackButtonComponent?: React.ReactNode;
  hideMyLoans?: boolean;
  hideChat?: boolean;
}

const ConfigDrivenChecklistContainerLayout: React.FC<
  ConfigDrivenChecklistContainerLayoutProps
> = ({
  children,
  BackButtonComponent,
  hideMyLoans = false,
  hideChat = false,
}) => {
  const { isDesktop } = useDeviceType();
  const {
    loan: { toggleChat },
  } = useAppSelector((state) => state);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(toggleChat);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  useEffect(() => {
    // make body overflow hidden on mobile when chat is open
    // to prevent scrolling on body
    if (!isDesktop) {
      if (isChatOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isChatOpen, isDesktop]);

  return (
    <div className='grid lg:grid-flow-col lg:grid-cols-11 grid-cols-1'>
      {/* Left Sidebar */}
      <div className='relative lg:col-span-5 lg:h-screen lg:overflow-y-auto bg-reskin-primary-navy h-20 '>
        <img
          src={RealLogo}
          alt='logo'
          width={!isDesktop ? 80 : 100}
          height={!isDesktop ? 20 : 34}
          className='absolute lg:top-10 top-8 md:left-10 left-5'
        />

        <FontAwesomeIcon
          icon={faBars}
          onClick={() => setToggleMenu(!toggleMenu)}
          fontSize={24}
          className='text-white absolute top-8 md:right-10 right-5 lg:hidden block'
        />

        <div className='hidden lg:block lg:overflow-y-auto'>
          <ConfigDrivenContainerLayoutRoadToSuccess
            variant={StepVariant.ReskinAqua}
          />
        </div>
      </div>

      {/* Main Content */}
      <div className='lg:col-span-6 lg:h-screen lg:overflow-y-auto'>
        <ConfigDrivenContainerLayoutHeader hideMyLoans={hideMyLoans} />
        <div className='flex flex-row items-center justify-center lg:p-8 px-4 pt-6 pb-40'>
          <div className='w-full lg:w-4/6'>
            <div>{BackButtonComponent}</div>
            {children}
          </div>
        </div>
      </div>

      {/* Chat */}
      {!hideChat && (
        <ConfigDrivenContainerLayoutChat
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
        />
      )}

      {/* Mobile Menu */}
      <ConfigDrivenContainerLayoutMobileMenu
        toggleMenu={toggleMenu}
        hideMyLoans={hideMyLoans}
        onClose={() => setToggleMenu(false)}
      />
    </div>
  );
};

export default ConfigDrivenChecklistContainerLayout;
