import { faPaperclipVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { ChecklistTaskResponse, Question } from '../../openapi/atlantis';
import { RootState } from '../../types';
import { getISOFormattedDate } from '../../utils/DateUtils';
import { getLoanOfficerFullName } from '../../utils/commonUtils';
import ChecklistStatus from './ChecklistStatus';

type ChecklistCardProps = {
  checklist: ChecklistTaskResponse;
};

const ChecklistCard: React.FC<ChecklistCardProps> = ({ checklist }) => {
  const { loanDetail } = useSelector((state: RootState) => ({
    loanDetail: state.loan.loanDetail,
  }));

  const checklistContent: Question = checklist?.content || {};

  return (
    <div className='border border-silver rounded-[10px] lg:p-5 md:p-5 p-3 flex flex-col justify-between gap-3 lg:mb-8 md:mb-8 mb-4 cursor-pointer'>
      <div className='flex justify-between items-start border-b border-b-silver pb-4 w-full'>
        <div className='w-80'>
          <p className='font-inter-medium lg:text-lg md:text-lg text-sm leading-6'>
            {checklistContent?.title}
          </p>
        </div>
        <ChecklistStatus status={checklist?.status!} />
      </div>
      <div className='grid grid-cols-12 gap-2 justify-between items-center'>
        <div className='col-span-6 flex flex-col items-start gap-1'>
          <div className='flex items-center'>
            <p className='font-inter-light text-xs md:text-sm text-primary-gray'>
              Requested By
            </p>
          </div>
          <p className='font-inter-light text-reskin-primary-dark md:text-lg text-base'>
            {!!checklist?.assignor
              ? getLoanOfficerFullName(
                  checklist.assignor,
                  loanDetail?.loanOfficer!,
                )
              : '--'}
          </p>
        </div>
        <div className='col-span-5 flex flex-col items-start gap-1'>
          <div className='flex items-center'>
            <p className='font-inter-light text-xs md:text-sm text-primary-gray'>
              Due Date
            </p>
          </div>
          <p className='font-inter-light text-reskin-primary-dark md:text-lg text-base'>
            {!!checklist?.dueDate
              ? getISOFormattedDate(`${checklist.dueDate}`)
              : '--'}
          </p>
        </div>
        <div className='col-span-1 flex flex-col items-end gap-1'>
          <FontAwesomeIcon
            icon={faPaperclipVertical}
            className='font-inter-light text-base text-primary-gray'
          />
          <p className='font-inter-light text-reskin-primary-dark md:text-lg text-base'>
            {checklist?.fileReferences?.references?.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChecklistCard;
