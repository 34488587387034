import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChecklistTaskResponse } from '../../openapi/atlantis';
import ChecklistCard from './ChecklistCard';

type ChecklistsTabProps = {
  checklists: ChecklistTaskResponse[];
  EmptyComponent: React.ReactNode;
};

const ChecklistsTab: React.FC<ChecklistsTabProps> = ({
  checklists = [],
  EmptyComponent,
}) => {
  const navigate = useNavigate();

  if (checklists.length === 0) {
    return EmptyComponent;
  }

  return (
    <div>
      {checklists.map((checklist) => (
        <div
          key={checklist.id}
          className='mb-5'
          onClick={() => navigate(`${checklist?.id}`)}
        >
          <ChecklistCard checklist={checklist} />
        </div>
      ))}
    </div>
  );
};

export default ChecklistsTab;
