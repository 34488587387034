import { faArrowLeftLong, faListCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChecklistsTab from '../components/Checklists/ChecklistsTab';
import EmptyChecklistsCard from '../components/Checklists/EmptyChecklistsCard';
import ConfigDrivenTitle from '../components/ConfigDriven/Misc/ConfigDrivenTitle';
import DefaultSplash from '../components/DefaultSplash';
import ConfigDrivenChecklistContainerLayout from '../components/Layout/ConfigDrivenChecklistContainerLayout';
import ConfigDrivenContainerLayoutRoadToSuccess from '../components/Layout/ConfigDrivenContainerLayoutRoadToSuccess';
import LoanOfficerCard from '../components/Loans/LoanOfficerCard';
import { StepVariant } from '../components/StepProgress/Step';
import Tabs from '../components/Tabs';
import useDeviceType from '../hooks/useDeviceType';
import {
  ChecklistTaskResponse,
  ChecklistTaskResponseStatusEnum,
} from '../openapi/atlantis';
import { fetchLoanChecklists } from '../slices/LoanSlice';
import { useAppDispatch, useAppSelector } from '../slices/store';

const PreApprovalFormTasksRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const { borrowerId } = useParams();
  const { isDesktop } = useDeviceType();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<string>();
  const [isRoadToSuccessVisible, setIsRoadToSuccessVisible] =
    useState<boolean>(false);

  const { checklists } = useAppSelector((state) => ({
    checklists: state.loan.checklists,
  }));

  const { activeChecklists, completedChecklists } = useMemo(() => {
    return (checklists || []).reduce(
      (acc, item) => {
        if (item.status !== ChecklistTaskResponseStatusEnum.Accepted) {
          acc.activeChecklists.push(item);
        } else {
          acc.completedChecklists.push(item);
        }
        return acc;
      },
      {
        activeChecklists: [] as ChecklistTaskResponse[],
        completedChecklists: [] as ChecklistTaskResponse[],
      },
    );
  }, [checklists]);

  const fetchChecklists = useCallback(async () => {
    if (borrowerId) {
      try {
        await dispatch(fetchLoanChecklists(borrowerId, true));
      } finally {
        setIsLoading(false);
      }
    }
  }, [borrowerId, dispatch]);

  useEffect(() => {
    fetchChecklists();
  }, [fetchChecklists]);

  if (isLoading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  if (isRoadToSuccessVisible && !isDesktop) {
    return (
      <ConfigDrivenChecklistContainerLayout
        BackButtonComponent={
          <div
            className='inline-flex flex-row items-center cursor-pointer gap-2.5 border border-reskin-silver-400 rounded-md py-1 px-2 mb-8'
            onClick={() => setIsRoadToSuccessVisible(false)}
          >
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              className='text-black text-sm lg:text-lg'
            />
            <div className='font-poppins-regular text-sm lg:text-lg text-black'>
              Back to Tasks
            </div>
          </div>
        }
      >
        <div className='block lg:hidden'>
          <ConfigDrivenContainerLayoutRoadToSuccess
            variant={StepVariant.Aqua}
          />
        </div>
      </ConfigDrivenChecklistContainerLayout>
    );
  }

  return (
    <ConfigDrivenChecklistContainerLayout>
      <LoanOfficerCard />
      <div className='lg:my-10 mb-10 flex flex-row items-center justify-between'>
        <ConfigDrivenTitle
          title='My tasks'
          textStyle='!font-poppins-medium select-none'
        />
        <div className='block lg:hidden'>
          <button
            onClick={() => setIsRoadToSuccessVisible(true)}
            className='font-poppins-medium text-reskin-primary-blue text-sm'
          >
            <FontAwesomeIcon icon={faListCheck} />{' '}
            <span className='ml-1'>Road To Success</span>
          </button>
        </div>
      </div>
      <div className='lg:my-10 mb-10'>
        <Tabs
          tabs={[
            {
              name: `Active Tasks (${activeChecklists.length})`,
              TabComponent: (
                <ChecklistsTab
                  checklists={activeChecklists}
                  EmptyComponent={
                    <EmptyChecklistsCard
                      heading='Good news!'
                      subheading="You don't have any active tasks."
                    />
                  }
                />
              ),
            },
            {
              name: `Completed Tasks  (${completedChecklists.length})`,
              TabComponent: (
                <ChecklistsTab
                  checklists={completedChecklists}
                  EmptyComponent={
                    <EmptyChecklistsCard
                      heading=''
                      subheading="You don't have any completed tasks."
                    />
                  }
                />
              ),
            },
          ]}
          selected={selected}
          onChange={setSelected}
        />
      </div>
    </ConfigDrivenChecklistContainerLayout>
  );
};

export default PreApprovalFormTasksRoute;
