import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { LegacyRef, useRef, useState } from 'react';
import { MAX_FILE_SIZE } from '../../constants/FileConstants';
import { uploadFileToChecklist } from '../../slices/DropboxSlice';
import { fetchLoanChecklistDetail } from '../../slices/LoanSlice';
import { showErrorToast } from '../../slices/ToastNotificationSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import { getDropboxFilesFromFileReferences } from '../../utils/DropboxUtils';
import { bytesToSize, isDocSizeInvalid } from '../../utils/FileUtils';
import ChecklistFile from '../Checklists/ChecklistFile';
import DefaultLoader from '../DefaultLoader';

type ControlledUploadChecklistFileInputProps = {
  checklistId: string;
};

const ControlledUploadChecklistFileInput: React.FC<
  ControlledUploadChecklistFileInputProps
> = ({ checklistId }) => {
  const dispatch = useAppDispatch();
  const [uploading, setUploading] = useState<boolean>(false);

  const {
    authUserDetail,
    borrowerDetail,
    checklistDetail,
    dropboxByBorrowerId,
  } = useAppSelector((state) => ({
    authUserDetail: state.auth.authUserDetail,
    borrowerDetail: state.loan.borrowerDetail,
    checklistDetail: state.loan.checklistDetail,
    dropboxByBorrowerId: state.dropbox.dropboxByBorrowerId,
  }));

  const fileInputRef = useRef<HTMLDivElement>();
  const borrowerId = borrowerDetail?.id!;
  const dropbox = dropboxByBorrowerId[borrowerId];
  const fileReferences = checklistDetail?.fileReferences?.references || [];

  const uploadFile = async (file: File) => {
    setUploading(true);

    const fileResponse = await dispatch(
      uploadFileToChecklist(
        authUserDetail?.id!,
        borrowerDetail!,
        checklistDetail!,
        file,
      ),
    );

    if (fileResponse) {
      await dispatch(fetchLoanChecklistDetail(borrowerId, checklistId));
    }

    setUploading(false);
  };

  const files = getDropboxFilesFromFileReferences(
    fileReferences || [],
    dropbox!,
  );

  const isFileAvailable = files.length > 0;

  return (
    <div>
      <div className='flex flex-col bg-whitesmoke p-6 border rounded-[10px] border-silver items-center justify-center'>
        <div className='flex flex-row cursor-pointer'>
          <div
            className='flex flex-col items-center justify-center px-5 py-3'
            onClick={() => fileInputRef.current?.click()}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={async (e) => {
              e.preventDefault();
              if (isDocSizeInvalid(e.dataTransfer.files![0], MAX_FILE_SIZE)) {
                dispatch(
                  showErrorToast(
                    `File size exceeds maximum limit of ${bytesToSize(
                      MAX_FILE_SIZE,
                    )}.`,
                  ),
                );
              } else {
                uploadFile(e.dataTransfer.files![0]);
              }
            }}
          >
            <input
              name='upload-files'
              ref={fileInputRef as unknown as LegacyRef<HTMLInputElement>}
              className='hidden w-full h-full'
              type='file'
              accept='.xlsx, .xls, .doc, .docx, .pdf, image/*'
              onChange={(e) => {
                if (isDocSizeInvalid(e.target.files![0], MAX_FILE_SIZE)) {
                  dispatch(
                    showErrorToast(
                      `File size exceeds maximum limit of ${bytesToSize(
                        MAX_FILE_SIZE,
                      )}.`,
                    ),
                  );
                } else {
                  uploadFile(e.target.files![0]);
                }
                e.target.value = '';
              }}
              disabled={uploading}
            />
            <FontAwesomeIcon
              icon={faArrowUpFromBracket}
              fontSize={18}
              className='text-reskin-primary-dark'
            />
            <p className='font-inter-medium text-base leading-6 text-reskin-primary-dark mt-1.5'>
              Upload
            </p>
            {uploading && (
              <div>
                <DefaultLoader iconSize={10} noPadding />
              </div>
            )}
            <p className='font-inter-light text-base leading-6 text-reskin-primary-dark mt-0.5'>
              Max file size: {bytesToSize(MAX_FILE_SIZE)}.
            </p>
          </div>
        </div>
      </div>
      {isFileAvailable && (
        <div>
          {files.map((file) => {
            return <ChecklistFile key={file.id} file={file} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ControlledUploadChecklistFileInput;
