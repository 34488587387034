import { tab } from '@testing-library/user-event/dist/cjs/convenience/tab.js';
import React, { Fragment } from 'react';
import { cn } from '../utils/classUtils';

type TabItemProps = {
  name: string;
  TabComponent: React.ReactElement;
};

type TabsProps = {
  tabs: TabItemProps[];
  onChange(name: string): void;
  selected?: string;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  selected = tabs[0]?.name,
  onChange,
}) => {
  const tabSelected = tabs.find((tab) => tab.name === selected);

  return (
    <div>
      <div className='bg-[#F2F2F4] flex justify-between items-center py-1.5 px-2 rounded-lg gap-3 mb-8'>
        {tabs.map(({ name }, index) => {
          const isActiveTab = name === selected;

          return (
            <div
              key={[tab.name, index].join('-')}
              onClick={() => onChange(name)}
              className={cn(
                'rounded-lg h-8 w-full cursor-pointer text-[#7B7A7A] font-inter-light md:text-base text-xs flex justify-center items-center',
                isActiveTab &&
                  'bg-white border border-[#7A8496] text-reskin-primary-dark font-inter-regular',
              )}
            >
              <p>{name}</p>
            </div>
          );
        })}
      </div>
      {!!tabSelected && (
        <Fragment key={tabSelected.name}>{tabSelected.TabComponent}</Fragment>
      )}
    </div>
  );
};

export default Tabs;
