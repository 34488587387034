import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import DefaultSplash from '../components/DefaultSplash';
import ErrorService from '../services/ErrorService';
import { fetchLoanProgress } from '../slices/ApplicationSlice';
import { fetchLoanByBorrowerId, toggleChat } from '../slices/LoanSlice';
import { showErrorToast } from '../slices/ToastNotificationSlice';
import { useAppDispatch } from '../slices/store';

const PreApprovalFormRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { borrowerId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchBorrowerLoanDetail = useCallback(
    async (borrowerId: string) => {
      const borrower = await dispatch(fetchLoanByBorrowerId(borrowerId));

      if (!borrower) {
        navigate('/');
        return null;
      }

      return borrower;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const fetchData = useCallback(async () => {
    try {
      const borrower = await fetchBorrowerLoanDetail(borrowerId!);
      if (borrower && borrower.roadToSuccessId) {
        await dispatch(fetchLoanProgress(borrower.roadToSuccessId));
      }
    } catch (e: any) {
      ErrorService.notify('Unable to fetch loan detail', e);
      dispatch(showErrorToast('Unable to fetch loan detail'));
    } finally {
      setIsLoading(false);
    }
  }, [borrowerId, dispatch, fetchBorrowerLoanDetail]);

  useEffect(() => {
    fetchData();

    return () => {
      dispatch(toggleChat(false));
    };
  }, [dispatch, fetchData]);

  if (isLoading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  return <Outlet />;
};

export default PreApprovalFormRoutes;
