import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FileResponse } from '../../openapi/dropbox';
import { deleteFileFromChecklist } from '../../slices/DropboxSlice';
import { fetchLoanChecklistDetail } from '../../slices/LoanSlice';
import { useAppDispatch, useAppSelector } from '../../slices/store';
import DefaultLoader from '../DefaultLoader';

type ChecklistFileProps = {
  file: FileResponse;
};

const ChecklistFile: React.FC<ChecklistFileProps> = ({ file }) => {
  const dispatch = useAppDispatch();
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>();
  const { borrowerDetail, checklistDetail } = useAppSelector((state) => ({
    borrowerDetail: state.loan.borrowerDetail,
    checklistDetail: state.loan.checklistDetail,
  }));

  const onDeleteFile = async () => {
    setIsDeletingFile(true);

    const isFileDeleted = await dispatch(
      deleteFileFromChecklist(borrowerDetail!, checklistDetail!, file),
    );

    if (isFileDeleted) {
      await dispatch(
        fetchLoanChecklistDetail(borrowerDetail?.id!, checklistDetail?.id!),
      );
    }

    setIsDeletingFile(false);
  };

  return (
    <div className='flex flex-row items-center justify-between mt-3 md:mt-4 p-4 border border-silver rounded-xl'>
      <p className='font-inter-medium text-base text-reskin-primary-dark w-11/12 ml-3'>
        {file.filename}
      </p>
      <button onClick={onDeleteFile} disabled={!!isDeletingFile}>
        {isDeletingFile ? (
          <DefaultLoader iconSize={10} noPadding />
        ) : (
          <FontAwesomeIcon
            icon={faXmark}
            fontSize={20}
            fontWeight={300}
            className='text-reskin-primary-dark'
          />
        )}
      </button>
    </div>
  );
};

export default ChecklistFile;
