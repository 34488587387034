import { faCircle, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { EnumMap } from '../../types';
import { safeEnumMapGet } from '../../utils/EnumHelper';
import { cn } from '../../utils/classUtils';

export enum StepVariant {
  Default = 'default',
  Aqua = 'aqua',
  ReskinAqua = 'reskinAqua',
}

interface StepProps {
  isInProgress: boolean;
  isCompleted: boolean;
  isFinal: boolean;
  inProgressPercentage?: number;
  isVertical?: boolean;
  variant?: StepVariant;
}

export const variantToCompletedColorMap: EnumMap<StepVariant, string> = {
  [StepVariant.Default]: 'text-aqua',
  [StepVariant.Aqua]: 'text-aqua',
  [StepVariant.ReskinAqua]: 'text-reskin-aqua',
};

export const variantToCompletedBorderColorMap: EnumMap<StepVariant, string> = {
  [StepVariant.Default]: 'border-aqua',
  [StepVariant.Aqua]: 'border-aqua',
  [StepVariant.ReskinAqua]: 'border-reskin-aqua',
};

export const variantToColorMap: EnumMap<StepVariant, string> = {
  [StepVariant.Default]: 'text-primary-graphite',
  [StepVariant.Aqua]: 'text-silver',
  [StepVariant.ReskinAqua]: 'text-[#7A8496]',
};

export const variantToBorderColorMap: EnumMap<StepVariant, string> = {
  [StepVariant.Default]: 'border-primary-graphite',
  [StepVariant.Aqua]: 'border-silver',
  [StepVariant.ReskinAqua]: 'border-[#7A8496]',
};

const Step: React.FC<StepProps> = ({
  isInProgress,
  isCompleted,
  isFinal,
  inProgressPercentage,
  isVertical = false,
  variant = StepVariant.Default,
}) => {
  const stepCompletedColor = safeEnumMapGet(
    variantToCompletedColorMap,
    variant,
    StepVariant.Default,
  );

  const stepCompletedBorderColor = safeEnumMapGet(
    variantToCompletedBorderColorMap,
    variant,
    StepVariant.Default,
  );

  const stepColor = safeEnumMapGet(
    variantToColorMap,
    variant,
    StepVariant.Default,
  );

  const stepBorderColor = safeEnumMapGet(
    variantToBorderColorMap,
    variant,
    StepVariant.Default,
  );

  return (
    <Fragment>
      <div
        className={cn('flex flex-row items-center justify-center', {
          'm-1': variant === StepVariant.Default,
        })}
      >
        {isCompleted ? (
          <FontAwesomeIcon
            icon={faCircleCheck}
            fontSize={24}
            className={stepCompletedColor}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={24}
            className={classNames(
              isInProgress ? stepCompletedColor : stepColor,
            )}
          />
        )}
      </div>
      <div className='flex flex-1 relative'>
        {!isFinal && (
          <div
            className={classNames([
              'flex flex-1 items-center',
              isVertical ? 'flex-col' : 'flex-row',
            ])}
          >
            <div
              className={classNames([
                'flex',
                'flex-1',
                'border',
                isCompleted ? stepCompletedBorderColor : stepBorderColor,
              ])}
            />
          </div>
        )}
        {!isFinal && isInProgress && (
          <div
            className='absolute top-0 border border-primary-skyblue'
            style={
              isVertical
                ? {
                    height: `${inProgressPercentage}%`,
                  }
                : {
                    width: `${inProgressPercentage}%`,
                  }
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default Step;
