import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';
import useComments from '../hooks/useComments';
import ChatFooter from './Chats/ChatFooter';
import ChatHeader from './Chats/ChatHeader';
import ChatMessage from './Chats/ChatMessage';
import DefaultLoader from './DefaultLoader.tsx';

interface ChatModalProps {}

const ChatModal: React.FC<ChatModalProps> = () => {
  const { borrowerId } = useParams();
  const { ref, comments, onSubmit, handleFetchNext, hasMore } = useComments({
    id: borrowerId!,
  });

  return (
    <div className='w-[100vw] lg:w-[25vw] lg:pt-3 pt-2 bg-gray-1 shadow-lg lg:rounded-2xl relative mb-3 z-50'>
      <div className='px-3'>
        <ChatHeader />
      </div>
      <div className='flex'>
        <div
          ref={ref}
          className='flex-grow lg:pb-10 pb-28 overflow-scroll lg:h-[50vh] h-chat-window bg-gray-1'
        >
          <InfiniteScroll
            loadMore={handleFetchNext}
            hasMore={hasMore}
            loader={<DefaultLoader key={0} />}
            useWindow={false}
            isReverse
            className='px-3 lg:px-6'
          >
            {comments.map((comment) => (
              <ChatMessage key={comment.id} comment={comment} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <ChatFooter onSubmit={onSubmit} />
    </div>
  );
};

export default ChatModal;
