import { FileReference } from '../openapi/atlantis';
import { DropboxResponse, FileResponse } from '../openapi/dropbox';

export const getDropboxFilesFromFileReferences = (
  fileReferences: FileReference[],
  dropbox: DropboxResponse,
) => {
  const allDropboxFiles = dropbox?.files;
  let dropboxFiles: FileResponse[] = [];
  fileReferences?.forEach((fileReference) => {
    const dropboxFile = allDropboxFiles?.find(
      (file) => file.id === fileReference.fileId,
    );
    if (dropboxFile) {
      dropboxFiles.push(dropboxFile);
    }
  });

  return dropboxFiles;
};
