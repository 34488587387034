import { datadogRum } from '@datadog/browser-rum';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App.tsx';
import ErrorBoundary from './components/Errors/ErrorBoundary.tsx';
import config from './config.ts';
import './index.css';
import Route500 from './routes/Route500.tsx';
import AnalyticsService from './services/AnalyticsService.ts';
import store from './slices/store.ts';
import { isDatadogErrorEventValid } from './utils/DatadogHelper.ts';
import Logger from './utils/Logger.ts';
import { getTracingUrls } from './utils/datadog/datadogUtils.ts';

AnalyticsService.instance().initialize();

const enabledReleaseStages = ['production', 'stage', 'play'];
const isErrorTrackingEnabled = enabledReleaseStages.includes(
  config.datadog.releaseStage,
);

Logger.debug(
  'Running version ',
  config.datadog.deployVersion,
  ' in ',
  config.datadog.releaseStage,
  ' environment.',
);

datadogRum.init({
  applicationId: config.datadog.applicationId,
  clientToken: config.datadog.clientToken,
  site: 'datadoghq.com',
  service: 'onereal-mortgage-web',
  env: config.datadog.releaseStage,
  version: config.datadog.deployVersion,
  sessionSampleRate: config.datadog.sessionSampleRate,
  sessionReplaySampleRate: config.datadog.sessionReplaySampleRate,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: getTracingUrls(),

  beforeSend: (event) => {
    const canTriggerError =
      isErrorTrackingEnabled && isDatadogErrorEventValid(event);

    if (canTriggerError) {
      return true;
    }

    return false;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ErrorBoundary FallbackComponent={Route500}>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
);
