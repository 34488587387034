import { useEffect } from 'react';

const useRegisterWindowHeightEffect = () => {
  const handleWindowChange = () => {
    document.documentElement.style.setProperty(
      '--wh',
      `${window.innerHeight}px`,
    );
  };

  useEffect(() => {
    handleWindowChange();

    window.addEventListener('resize', handleWindowChange);
    return () => {
      window.removeEventListener('resize', handleWindowChange);
    };
  }, []);
};

export default useRegisterWindowHeightEffect;
