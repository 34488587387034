import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import DefaultSplash from './components/DefaultSplash';
import GuestRoute from './components/RestrictedRoute/GuestRoute.tsx';
import ProtectedRoute from './components/RestrictedRoute/ProtectedRoute.tsx';
import ToastNotificationContainer from './components/ToastNotificationContainer';
import useRegisterWindowHeightEffect from './hooks/useRegisterWindowHeightEffect.tsx';
import ChangePasswordRoute from './routes/ChangePasswordRoute';
import ChatRoute from './routes/ChatRoute.tsx';
import EmailSentRoute from './routes/EmailSentRoute';
import ForgotPasswordRoute from './routes/ForgotPasswordRoute';
import LoanAdvisorRoute from './routes/LoanAdvisorRoute.tsx';
import LoanTypeRoute from './routes/LoanTypeRoute.tsx';
import LoansRoute from './routes/LoansRoute.tsx';
import PreApprovalFormRoute from './routes/PreApprovalFormRoute';
import PreApprovalFormRoutes from './routes/PreApprovalFormRoutes.tsx';
import PreApprovalFormTaskDetailRoute from './routes/PreApprovalFormTaskDetailRoute.tsx';
import PreApprovalFormTasksRoute from './routes/PreApprovalFormTasksRoute.tsx';
import Route404 from './routes/Route404.tsx';
import SignIn2FARoute from './routes/SignIn2FARoute';
import SignInRoute from './routes/SignInRoute';
import SignUpRoute from './routes/SignUpRoute';
import ErrorIndex from './routes/secret/ErrorsRoute.tsx';
import { fetchAuthUserDetail } from './slices/AuthSlice';
import { useAppDispatch } from './slices/store';
import { RootState } from './types';
import { getAuthCookie } from './utils/AuthUtils';

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    auth: { authUserDetail },
    loan: { loans },
  } = useSelector((state: RootState) => state);

  const fetchAuth = useCallback(async () => {
    if (getAuthCookie()) {
      await dispatch(fetchAuthUserDetail());
    }
    setLoading(false);
  }, [dispatch]);

  // const fetchTheme = useCallback(() => {
  //   const theme = ThemeService.getTheme();

  //   if (theme) {
  //     dispatch(setTheme(theme as themeEnum));
  //   }
  // }, [dispatch]);

  const getHomeRoutePath = () => {
    if (authUserDetail) {
      if ((loans || []).length === 0) {
        return `/loan-type`;
      }

      return `/loans`;
    } else {
      return '/login';
    }
  };

  useEffect(() => {
    fetchAuth();
    // fetchTheme();
  }, [fetchAuth]);

  useRegisterWindowHeightEffect();

  if (loading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={<Navigate to={getHomeRoutePath()} replace />}
          />
          <Route
            path='/signup'
            element={
              <GuestRoute>
                <SignUpRoute />
              </GuestRoute>
            }
          />
          <Route
            path='/login'
            element={
              <GuestRoute>
                <SignInRoute />
              </GuestRoute>
            }
          />
          <Route
            path='/2fa'
            element={
              <GuestRoute>
                <SignIn2FARoute />
              </GuestRoute>
            }
          />
          <Route
            path='/forgot-password'
            element={
              <GuestRoute>
                <ForgotPasswordRoute />
              </GuestRoute>
            }
          />
          <Route
            path='/change-password'
            element={
              <GuestRoute>
                <ChangePasswordRoute />
              </GuestRoute>
            }
          />
          <Route
            path='/email-sent'
            element={
              <GuestRoute>
                <EmailSentRoute />
              </GuestRoute>
            }
          />
          <Route path='/secret/errors' Component={ErrorIndex} />
          <Route
            path='/loan-advisor/:slug'
            element={
              <GuestRoute>
                <LoanAdvisorRoute />
              </GuestRoute>
            }
          />
          <Route
            path='/mortgage-advisor/:slug'
            element={
              <GuestRoute>
                <LoanAdvisorRoute />
              </GuestRoute>
            }
          />
          <Route
            path='/loan-type'
            element={
              <ProtectedRoute>
                <LoanTypeRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path='/loans'
            element={
              <ProtectedRoute>
                <LoansRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path='/pre-approval-form'
            element={
              <ProtectedRoute>
                <PreApprovalFormRoutes />
              </ProtectedRoute>
            }
          >
            <Route
              path=':borrowerId/tasks/:taskId'
              element={<PreApprovalFormTaskDetailRoute />}
            />
            <Route
              path=':borrowerId/tasks'
              element={<PreApprovalFormTasksRoute />}
            />
            <Route path=':borrowerId/chat' element={<ChatRoute />} />
            <Route path=':borrowerId' element={<PreApprovalFormRoute />} />
          </Route>
          <Route path='*' Component={Route404} />
        </Routes>
      </BrowserRouter>
      <ToastNotificationContainer />
    </div>
  );
};

export default App;
