import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApplyLoanLoanTypeEnum,
  BorrowerDto,
  BorrowersApi,
  ChecklistTaskResponse,
  LoanDto,
  LoansApi,
  UserApi,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService';
import { AppThunk, LoanState } from '../types';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils';
import { fetchDropboxById } from './DropboxSlice';
import { showErrorToast } from './ToastNotificationSlice';

export const initialState: LoanState = {
  userId: undefined,
  loans: undefined,
  loanDetail: undefined,
  borrowerDetail: undefined,
  checklists: undefined,
  checklistDetail: undefined,
  toggleChat: false,
};

const LoanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    saveUserId(state, action: PayloadAction<string | undefined>) {
      state.userId = action.payload;
    },
    saveLoans(state, action: PayloadAction<LoanDto[] | undefined>) {
      state.loans = action.payload;
    },
    saveLoanDetail(state, action: PayloadAction<LoanDto | undefined>) {
      state.loanDetail = action.payload;
    },
    saveBorrowerDetail(state, action: PayloadAction<BorrowerDto | undefined>) {
      state.borrowerDetail = action.payload;
    },
    addLoan(state, action: PayloadAction<LoanDto>) {
      state.loans?.push(action.payload);
    },
    saveChecklists(state, action: PayloadAction<ChecklistTaskResponse[]>) {
      state.checklists = action.payload;
    },
    saveChecklistDetail(state, action: PayloadAction<ChecklistTaskResponse>) {
      state.checklistDetail = action.payload;
    },
    toggleChat(state, action: PayloadAction<boolean>) {
      state.toggleChat = action.payload;
    },
  },
});

export const {
  saveUserId,
  saveLoans,
  saveLoanDetail,
  saveBorrowerDetail,
  addLoan,
  saveChecklists,
  saveChecklistDetail,
  toggleChat,
} = LoanSlice.actions;

export const fetchLoanByBorrowerId =
  (borrowerId: string): AppThunk<Promise<BorrowerDto | undefined>> =>
  async (dispatch) => {
    try {
      const { data } = await new LoansApi(
        await getAtlantisConfiguration(),
      ).getLoanByBorrowerId(borrowerId);

      const borrower = data.borrowers.find((b) => b.id === borrowerId);

      if (borrower) {
        dispatch(saveLoanDetail(data));
        dispatch(saveBorrowerDetail(borrower));
        dispatch(fetchDropboxById(borrower));
        return borrower;
      }
    } catch (e: any) {
      ErrorService.notify('Unable to fetch borrower loan detail', e);
      dispatch(showErrorToast('Unable to fetch borrower loan detail'));
    }
    return undefined;
  };

export const fetchLoans =
  (leadId?: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      const { data } = await new UserApi(
        await getAtlantisConfiguration(),
      ).getMe(leadId);
      dispatch(saveUserId(data.userId));
      dispatch(saveLoans(data.loans || []));
      return true;
    } catch (e: any) {
      ErrorService.notify('Unable to fetch current user detail', e);
      dispatch(showErrorToast('Unable to fetch current user detail'));
    }
    return false;
  };

export const applyForLoan =
  (loanType: ApplyLoanLoanTypeEnum): AppThunk<Promise<LoanDto | undefined>> =>
  async (dispatch) => {
    try {
      const { data } = await new LoansApi(
        await getAtlantisConfiguration(),
      ).applyForLoan({
        loanType,
      });
      dispatch(addLoan(data));
      return data;
    } catch (e: any) {
      ErrorService.notify('Unable to create loan', e);
      dispatch(showErrorToast('Unable to create loan'));
    }
    return undefined;
  };

export const fetchLoanChecklists =
  (borrowerId: string, required: boolean): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const { data } = await new BorrowersApi(
        await getAtlantisConfiguration(),
      ).getBorrowerAssignedChecklistTasks(borrowerId!, required);
      dispatch(saveChecklists(data));
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We were unable to fetch borrower assigned checklist tasks',
          'Please try again in after sometime',
        ),
      );
      ErrorService.notify(
        'Unable to fetch borrower assigned checklist tasks',
        e,
        {
          borrower: { id: borrowerId },
        },
      );
    }
  };

export const fetchLoanChecklistDetail =
  (borrowerId: string, checklistId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const { data } = await new BorrowersApi(
        await getAtlantisConfiguration(),
      ).getBorrowerChecklistTask(borrowerId, checklistId!);
      dispatch(saveChecklistDetail(data));
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We were unable to fetch borrower checklist task',
          'Please try again in after sometime.',
        ),
      );
      ErrorService.notify('Unable to fetch borrower checklist task', e, {
        checklist: { id: checklistId },
      });
    }
  };

export const getLoanBorrowerId =
  (loan?: LoanDto): AppThunk<Promise<string | undefined>> =>
  async (_, getState) => {
    const { userId } = getState().loan;

    if (!loan || !userId) {
      return undefined;
    }

    const borrower = (loan.borrowers || []).find((b) => b.userId === userId);

    return borrower?.id;
  };

export default LoanSlice.reducer;
