import { useEffect, useState } from 'react';
import { BorrowerDto, LoanDto } from '../openapi/atlantis';
import { getLoanBorrowerId } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';
import Logger from '../utils/Logger';

export const useLoanBorrower = (loan: LoanDto) => {
  const dispatch = useAppDispatch();
  const [borrower, setBorrower] = useState<BorrowerDto>();

  useEffect(() => {
    const fetchBorrower = async () => {
      try {
        const borrowerId = await dispatch(getLoanBorrowerId(loan));
        const borrower = loan.borrowers.find((b) => b.id === borrowerId);
        setBorrower(borrower);
      } catch (e) {
        Logger.error('Unable to get loan borrower', e);
      }
    };

    fetchBorrower();
  }, [dispatch, loan]);

  return borrower;
};
