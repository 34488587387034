import {
  faCircleCheck,
  faDollarSign,
  faFileCheck,
  faFileImport,
  faHourglassHalf,
  faListCheck,
  faSignature,
  faStamp,
  faTimer,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { BorrowerDtoStatusEnum } from '../../../openapi/atlantis';
import { EnumMap } from '../../../types';
import { safeEnumMapGet } from '../../../utils/EnumHelper';
import { LoanStatusProps } from '../LoanStatus';

type LoanStatusVariantType =
  | 'default'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'secondary-warning'
  | 'purple';

export const variantToClassNameMap: EnumMap<BorrowerDtoStatusEnum, string> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'text-[#4967FD]',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'text-[#C98B11]',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'text-[#7440CD]',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'text-[#439775]',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'text-[#4967FD]',
  [BorrowerDtoStatusEnum.Underwriting]: 'text-[#C98B11]',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'text-[#C98B11]',
  [BorrowerDtoStatusEnum.ClearToClose]: 'text-[#7440CD]',
  [BorrowerDtoStatusEnum.Closing]: 'text-[#439775]',
  [BorrowerDtoStatusEnum.Funding]: 'text-[#439775]',
  [BorrowerDtoStatusEnum.Terminated]: 'text-[#F84C6C]',
};

export const statusToName: EnumMap<BorrowerDtoStatusEnum, string> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'Created',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'In progress',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'Submitted',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'Pre-approved',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'Submitted to lender',
  [BorrowerDtoStatusEnum.Underwriting]: 'Underwriting',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'Conditional approval',
  [BorrowerDtoStatusEnum.ClearToClose]: 'Clear to close',
  [BorrowerDtoStatusEnum.Closing]: 'Closing',
  [BorrowerDtoStatusEnum.Funding]: 'Funding',
  [BorrowerDtoStatusEnum.Terminated]: 'Terminated',
};

export const statusToVariant: EnumMap<
  BorrowerDtoStatusEnum,
  LoanStatusVariantType
> = {
  [BorrowerDtoStatusEnum.AccountCreated]: 'warning',
  [BorrowerDtoStatusEnum.ApplicationInProgress]: 'secondary-warning',
  [BorrowerDtoStatusEnum.ApplicationSubmitted]: 'purple',
  [BorrowerDtoStatusEnum.SubmittedToLender]: 'primary',
  [BorrowerDtoStatusEnum.Underwriting]: 'warning',
  [BorrowerDtoStatusEnum.ConditionalApproval]: 'secondary-warning',
  [BorrowerDtoStatusEnum.ClearToClose]: 'purple',
  [BorrowerDtoStatusEnum.Closing]: 'success',
  [BorrowerDtoStatusEnum.LoanPreApproved]: 'success',
  [BorrowerDtoStatusEnum.Funding]: 'success',
  [BorrowerDtoStatusEnum.Terminated]: 'danger',
};

const LoanStatusGemini: React.FC<LoanStatusProps> = ({ status }) => {
  const LoanStatusIconMap: EnumMap<BorrowerDtoStatusEnum, React.ReactElement> =
    {
      [BorrowerDtoStatusEnum.AccountCreated]: (
        <FontAwesomeIcon icon={faFileImport} />
      ),
      [BorrowerDtoStatusEnum.ApplicationInProgress]: (
        <FontAwesomeIcon icon={faHourglassHalf} />
      ),
      [BorrowerDtoStatusEnum.ApplicationSubmitted]: (
        <FontAwesomeIcon
          icon={faFileCheck}
          size='lg'
          className='text-[#7440CD]'
        />
      ),
      [BorrowerDtoStatusEnum.SubmittedToLender]: (
        <FontAwesomeIcon
          icon={faFileImport}
          size='lg'
          className='text-[#4967FD]'
        />
      ),
      [BorrowerDtoStatusEnum.Underwriting]: (
        <FontAwesomeIcon
          icon={faListCheck}
          size='lg'
          className='text-[#C98B11]'
        />
      ),
      [BorrowerDtoStatusEnum.LoanPreApproved]: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          size='lg'
          className='text-[#439775]'
        />
      ),

      [BorrowerDtoStatusEnum.ConditionalApproval]: (
        <FontAwesomeIcon icon={faStamp} size='lg' className='text-[#E26811]' />
      ),
      [BorrowerDtoStatusEnum.ClearToClose]: (
        <FontAwesomeIcon icon={faTimer} size='lg' className='text-[#7440CD]' />
      ),
      [BorrowerDtoStatusEnum.Closing]: (
        <FontAwesomeIcon
          icon={faSignature}
          size='lg'
          className='text-[#439775]'
        />
      ),
      [BorrowerDtoStatusEnum.Funding]: (
        <FontAwesomeIcon
          icon={faDollarSign}
          size='lg'
          className='text-[#439775]'
        />
      ),
      [BorrowerDtoStatusEnum.Terminated]: (
        <FontAwesomeIcon icon={faXmark} size='lg' className='text-[#F84C6C]' />
      ),
    };

  const statusText = safeEnumMapGet(
    statusToName,
    status,
    BorrowerDtoStatusEnum.AccountCreated,
  );

  const styles = safeEnumMapGet(
    variantToClassNameMap,
    status,
    BorrowerDtoStatusEnum.AccountCreated,
  );

  const icon = safeEnumMapGet(
    LoanStatusIconMap,
    status,
    LoanStatusIconMap[BorrowerDtoStatusEnum.AccountCreated],
  );

  return (
    <div className='flex items-center justify-between'>
      <div
        className={classNames(
          'w-max flex items-center space-x-1.5 flex-shrink-0 py-1.5 px-2 mr-1 rounded-full md:whitespace-nowrap',
          styles,
        )}
      >
        {icon}
        <span className='-mt-0.5 font-zen-body text-sm font-inter-medium'>
          {statusText}
        </span>
      </div>
    </div>
  );
};

export default LoanStatusGemini;
