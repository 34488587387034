import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BorrowerDto, ChecklistTaskResponse } from '../openapi/atlantis';
import {
  DropboxApi,
  DropboxResponse,
  FileApi,
  FileResponse,
} from '../openapi/dropbox';
import { ItemApi } from '../openapi/sherlock';
import ErrorService from '../services/ErrorService';
import { AppThunk, DropboxState } from '../types';
import {
  getDropboxConfiguration,
  getSherlockConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';

export const initialState: DropboxState = {
  dropboxByBorrowerId: {},
};

const DropboxSlice = createSlice({
  name: 'dropbox',
  initialState,
  reducers: {
    saveDropboxById(
      state,
      action: PayloadAction<{ borrowerId: string; dropbox: DropboxResponse }>,
    ) {
      const { borrowerId, dropbox } = action.payload;
      state.dropboxByBorrowerId[borrowerId] = dropbox;
    },
    addFileToDropbox(
      state,
      action: PayloadAction<{ borrowerId: string; file: FileResponse }>,
    ) {
      const { borrowerId, file } = action.payload;
      const dropbox = state.dropboxByBorrowerId[borrowerId];
      if (dropbox) {
        state.dropboxByBorrowerId[borrowerId] = {
          ...dropbox,
          files: [...(dropbox.files || []), file],
        };
      }
    },
    removeFileFromDropbox(
      state,
      action: PayloadAction<{ borrowerId: string; fileId: string }>,
    ) {
      const { borrowerId, fileId } = action.payload;
      const dropbox = state.dropboxByBorrowerId[borrowerId];
      if (dropbox) {
        state.dropboxByBorrowerId[borrowerId] = {
          ...dropbox,
          files: dropbox.files?.filter((file) => file.id !== fileId),
        };
      }
    },
  },
});

export const { saveDropboxById, addFileToDropbox, removeFileFromDropbox } =
  DropboxSlice.actions;

export const fetchDropboxById =
  (borrower: BorrowerDto): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const dropboxId = borrower?.dropboxId!;
    const borrowerId = borrower.id;
    try {
      const { data } = await new DropboxApi(
        await getDropboxConfiguration(),
      ).getDropboxById(dropboxId);
      dispatch(saveDropboxById({ borrowerId, dropbox: data }));
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We had a problem fetching dropbox files. Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to fetch dropbox files', e, {
        dropbox: { dropboxId },
      });
    }
  };

export const uploadFileToDropbox =
  (
    userId: string,
    borrower: BorrowerDto,
    file: File,
  ): AppThunk<Promise<FileResponse | undefined>> =>
  async (dispatch) => {
    const dropboxId = borrower?.dropboxId!;
    const borrowerId = borrower.id;
    try {
      const { data } = await new DropboxApi(
        await getDropboxConfiguration(),
      ).uploadFile(dropboxId, file, userId);
      await dispatch(addFileToDropbox({ borrowerId, file: data }));
      dispatch(showSuccessToast('File uploaded successfully'));
      return data;
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We had a problem uploading the file.',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to upload file to dropbox', e, {
        dropbox: { dropboxId },
      });
    }

    return undefined;
  };

export const deleteFileFromDropbox =
  (borrower: BorrowerDto, fileId: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    const borrowerId = borrower.id;
    try {
      await new FileApi(await getDropboxConfiguration()).moveFileToTrash(
        fileId,
      );
      dispatch(removeFileFromDropbox({ borrowerId, fileId }));
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We had a problem deleting the file. Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to delete a file', e, {
        file: { id: fileId },
      });
      return false;
    }

    return true;
  };

export const uploadFileToChecklist =
  (
    authUserId: string,
    borrower: BorrowerDto,
    checklistDetail: ChecklistTaskResponse,
    file: File,
  ): AppThunk<Promise<FileResponse | undefined>> =>
  async (dispatch) => {
    const dropboxId = borrower?.dropboxId!;
    const borrowerId = borrower.id;

    try {
      const { data } = await new DropboxApi(
        await getDropboxConfiguration(),
      ).uploadFile(dropboxId, file, authUserId);

      if (data) {
        const references = [
          {
            fileId: data?.id!,
            filename: data?.filename!,
          },
        ];

        await new ItemApi(await getSherlockConfiguration()).addFileReferences(
          checklistDetail?.id!,
          {
            references,
          },
        );

        await dispatch(addFileToDropbox({ borrowerId, file: data }));
      }

      dispatch(showSuccessToast('Document uploaded successfully'));
      return data;
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We had a problem uploading the document.',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to upload document to dropbox', e, {
        auth: { id: authUserId },
        borrower,
        checklistDetail,
      });
      return undefined;
    }
  };

export const deleteFileFromChecklist =
  (
    borrower: BorrowerDto,
    checklistDetail: ChecklistTaskResponse,
    file: FileResponse,
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    const borrowerId = borrower.id;

    try {
      const fileReferences = [
        {
          fileId: file?.id!,
          filename: file?.filename!,
        },
      ];

      await new FileApi(await getDropboxConfiguration()).moveFileToTrash(
        file?.id!,
      );

      await new ItemApi(await getSherlockConfiguration()).removeFileReferences(
        checklistDetail?.id!,
        {
          references: fileReferences,
        },
      );

      dispatch(removeFileFromDropbox({ borrowerId, fileId: file?.id! }));

      dispatch(showSuccessToast('Document deleted successfully'));

      return true;
    } catch (e: any) {
      dispatch(
        showErrorToast(
          'We had a problem deleting the document.',
          'Please try again in a few moments.',
        ),
      );
      ErrorService.notify('Unable to delete a document', e, {
        borrower,
        file,
        checklistDetail,
      });
      return false;
    }
  };

export default DropboxSlice.reducer;
