import classNames from 'classnames';
import React, { useState } from 'react';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export const VALID_VARIANTS = ['default'] as const;
type ControlledTextInputVariantTuple = typeof VALID_VARIANTS;
type ControlledTextInputVariant = ControlledTextInputVariantTuple[number];

export interface ControlledChatInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  placeholder?: string;
  readonly?: boolean;
  disabled?: boolean;
  variant?: ControlledTextInputVariant;
  numberOfLines?: number;
  endAdornment?: React.ReactElement;
  scrollEnabled?: boolean;
  maxLength?: number;
  keyDownHandler?(): void;
}

const ControlledChatInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  readonly = false,
  disabled = false,
  variant = 'default',
  placeholder,
  numberOfLines,
  shouldUnregister = true,
  endAdornment,
  scrollEnabled = true,
  maxLength,
  keyDownHandler,
  ...controllerProps
}: ControlledChatInputProps<TFieldValues, TName>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const {
    field: { value, onChange, name, onBlur },
  } = useController<TFieldValues, TName>({
    ...controllerProps,
    shouldUnregister,
  });

  const variantBorderStylesMap = {
    default: classNames([
      'flex flex-row flex-shrink items-stretch justify-between overflow-hidden rounded-full',
      isFocused ? 'border' : 'border-0',
      readonly || disabled ? 'border-gray-3' : 'border-black',
      'text-base leading-5',
    ]),
  };

  const variantViewStylesMap = {
    default:
      'flex flex-row justify-between items-center flex-1 py-1 text-sm leading-5 bg-gray-1 pl-4 pr-1',
  };

  const textInputStylesMap = {
    default: classNames([
      'items-center justify-center flex-grow flex-shrink font-inter-medium text-sm py-1 leading-5',
      readonly || disabled ? 'text-gray-3' : 'text-black',
    ]),
  };

  const handleOnBlur = () => {
    if (typeof value === 'string') {
      onChange(value.trim());
    }
    onBlur();
    setIsFocused(false);
  };

  return (
    <div className='bg-white'>
      <div className={variantBorderStylesMap[variant]}>
        <div className={variantViewStylesMap[variant]}>
          <textarea
            name={name}
            rows={1}
            className={classNames([
              'appearance-none focus:outline-none focus:ring-0 resize-none bg-gray-1',
              textInputStylesMap[variant],
            ])}
            onFocus={() => setIsFocused(true)}
            onChange={onChange}
            onBlur={handleOnBlur}
            onKeyDown={(event) => {
              if (
                (event.metaKey || event.ctrlKey) &&
                event.key === 'Enter' &&
                keyDownHandler
              ) {
                keyDownHandler();
              }
            }}
            disabled={disabled}
            placeholder={placeholder!}
            value={value}
            maxLength={maxLength}
          />
          <div>{endAdornment}</div>
        </div>
      </div>
    </div>
  );
};

export default ControlledChatInput;
