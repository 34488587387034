export enum MaskTypeEnum {
  SSN = 'SSN',
}

export const getTextMaskRegexFromDisplayType = (
  displayType: string,
): string | undefined => {
  switch (displayType) {
    case MaskTypeEnum.SSN:
      return '999-99-9999';
    default:
      return undefined;
  }
};
