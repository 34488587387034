import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfigDrivenContainerLayout from '../components/Layout/ConfigDrivenContainerLayout';
import LoanCard from '../components/Loans/LoanCard';
import LoansTitle from '../components/Loans/LoansTitle';
import { LoanDto } from '../openapi/atlantis';
import { fetchLoans, getLoanBorrowerId } from '../slices/LoanSlice';
import { useAppDispatch } from '../slices/store';
import { RootState } from '../types';
import { NewApplicationConfirmationModal } from '../components/NewApplicationConfirmationModal.tsx';

interface LoansRouteProps {}

const LoansRoute: React.FC<LoansRouteProps> = () => {
  const {
    loan: { loans },
  } = useSelector((state: RootState) => state);
  const [showStartApplicationModal, setShowStartApplicationModal] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onNewApplication = () => {
    navigate('/loan-type');
  };

  useEffect(() => {
    dispatch(fetchLoans());
  }, [dispatch]);

  const onLoanClick = async (loan: LoanDto) => {
    const borrowerId = await dispatch(getLoanBorrowerId(loan));
    if (borrowerId) {
      navigate(`/pre-approval-form/${borrowerId}`);
    }
  };

  return (
    <ConfigDrivenContainerLayout hideMyLoans hideChat>
      <div>
        <div className='lg:my-10 mb-10'>
          <LoansTitle />
        </div>
        {(loans || []).map((loan) => (
          <div key={loan.id} className='mb-5'>
            <LoanCard loan={loan} onPress={onLoanClick} />
          </div>
        ))}
        <div className='mt-10'>
          <p className='text-reskin-primary-dark text-xl font-poppins-regular mb-4'>
            Ready for a new loan?
          </p>
          <div
            onClick={() => setShowStartApplicationModal(true)}
            className='py-3 px-5 border border-silver w-full rounded-lg flex justify-between items-center cursor-pointer'
          >
            <p className='text-lg font-poppins-light text-primary-graphite'>
              Start new loan application
            </p>
            <FontAwesomeIcon
              icon={faPlus}
              size='sm'
              className='bg-[#EDEFF3] p-[6px] rounded-full'
            />
          </div>
        </div>
      </div>
      <NewApplicationConfirmationModal
        isOpen={showStartApplicationModal}
        onClose={() => setShowStartApplicationModal(false)}
        handleStartApplication={onNewApplication}
      />
    </ConfigDrivenContainerLayout>
  );
};
export default LoansRoute;
