import React from 'react';
import EmptyChecklistCard from '../../assets/icons/empty-task.png';

type EmptyChecklistsCardProps = {
  heading: string;
  subheading: string;
};

const EmptyChecklistsCard: React.FC<EmptyChecklistsCardProps> = ({
  heading,
  subheading,
}) => {
  return (
    <div className='flex justify-center items-center flex-col gap-y-6 mt-10'>
      <img src={EmptyChecklistCard} alt='empty-img' className='w-16 h-16' />
      <div className='text-center'>
        <p className='font-poppins-regular text-lg text-[#282826]'>{heading}</p>
        <p className='font-inter-light text-base text-reskin-primary-dark'>
          {subheading}
        </p>
      </div>
    </div>
  );
};

export default EmptyChecklistsCard;
