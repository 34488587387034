import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import NotFoundImg from '../assets/img/404.svg';
import LOBanner from '../assets/img/lo-banner.svg';
import Logo from '../assets/img/one-real-mortgage-logo.svg';
import Avatar from '../components/Avatar.tsx';
import Button from '../components/Button.tsx';
import DefaultSplash from '../components/DefaultSplash.tsx';
import FieldErrorMessage from '../components/inputs/FieldErrorMessage.tsx';
import {
  AuthorizedPersonDetailDto,
  LoansAuthorizedUsersApi,
} from '../openapi/atlantis';
import ErrorService from '../services/ErrorService.ts';
import Logger from '../utils/Logger.ts';
import { getAtlantisConfiguration } from '../utils/OpenapiConfigurationUtils.tsx';
import { formatPhoneNumber } from '../utils/StringUtils.ts';
import { EMAIL_VALIDATIONS } from '../utils/Validations.tsx';

interface FormData {
  emailAddress: string;
}

interface LoanAdvisorRouteProps {}

const LoanAdvisorRoute: React.FC<LoanAdvisorRouteProps> = () => {
  const navigate = useNavigate();
  const { slug }: any = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [authorizedPerson, setAuthorizedPerson] =
    useState<AuthorizedPersonDetailDto>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const fetchAuthorizedPersonBySlug = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await new LoansAuthorizedUsersApi(
        await getAtlantisConfiguration(),
      ).getLAuthorizedPersonBySlug(slug);
      setAuthorizedPerson(data);
    } catch (e: any) {
      Logger.error('Unable to fetch authorized person:', e);
      ErrorService.notify('Unable to fetch authorized person', e);
    } finally {
      setLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    fetchAuthorizedPersonBySlug();
  }, [fetchAuthorizedPersonBySlug]);

  const onSubmit = (values: FormData) => {
    const url = `/signup?${qs.stringify({
      emailAddress: values.emailAddress,
      inviterAuthorizedPersonId: authorizedPerson?.id,
    })}`;

    navigate(url);
  };

  if (loading) {
    return (
      <div className='h-screen w-screen'>
        <DefaultSplash />
      </div>
    );
  }

  if (!authorizedPerson) {
    return (
      <div className='flex flex-col bg-[#0F1231] h-screen w-screen overflow-scroll'>
        <div className='flex flex-row items-center justify-between lg:px-40 p-4'>
          <img src={Logo} alt='Logo' className='cursor-pointer' />
        </div>
        <div className='flex flex-col justify-center items-center flex-1  p-4'>
          <img src={NotFoundImg} alt='404' width={400} height={400} />
          <p className='text-center font-inter-medium text-white text-2xl mt-5'>
            Hmm...we can&apos;t seem to find that.
          </p>
          <div className='mt-5'>
            <Button
              label='Go Home'
              fullWidth={isMobile}
              gradientVariant='mintyfresh'
              onPress={() => navigate('/')}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='bg-[#0F1231] h-screen w-screen overflow-scroll'>
      <div className='flex flex-row items-center justify-between lg:mx-40 lg:my-10 m-4'>
        <img src={Logo} alt='Logo' className='cursor-pointer' />
      </div>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 lg:pl-40 my-10'>
        <div className='lg:mx-0 lg:mt-10 m-5 lg:max-w-xl'>
          <div className='flex lg:flex-row flex-col items-center gap-8'>
            <Avatar
              name={`${authorizedPerson?.firstName} ${authorizedPerson?.lastName}`}
              imageUrl={authorizedPerson?.avatar}
              variant='circle'
              size='2xl'
              containerStyle='w-40 h-40 flex-shrink-0'
              textStyle='text-6xl'
            />
            <div className='lg:text-left text-center'>
              <p className='font-inter-medium text-2xl text-white mt-1.5'>
                {authorizedPerson?.firstName} {authorizedPerson?.lastName}
              </p>
              <p className='text-sm font-inter-semibold text-[#919191] mt-1.5'>
                {authorizedPerson?.title} - NMLS: #
                {authorizedPerson?.nmlsNumber}
              </p>
              <div className='pt-4'>
                <p
                  className='flex flex-row items-center lg:justify-start justify-center cursor-pointer'
                  onClick={() => {
                    window.open(`mailto:${authorizedPerson?.emailAddress}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    fontSize={16}
                    className='text-white'
                  />
                  <span className='font-inter-regular text-white text-lg break-all ml-2'>
                    {authorizedPerson?.emailAddress}
                  </span>
                </p>
                <p
                  className='flex flex-row items-center lg:justify-start justify-center cursor-pointer mt-1.5'
                  onClick={() => {
                    window.open(`tel:${authorizedPerson?.phoneNumber}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    fontSize={16}
                    className='text-white'
                  />
                  <span className='font-inter-regular text-white text-lg ml-2'>
                    {formatPhoneNumber(authorizedPerson?.phoneNumber)}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className='lg:mt-24 mt-10'>
            <p className='font-inter-medium text-4xl text-[#00FBF0] mt-10'>
              Find the Mortgage That’s Right for you!
            </p>
            <p className='font-inter-medium text-white text-lg mt-6'>
              You&apos;ve been invited to apply for a new loan. Enter your email
              address to get started.
            </p>
          </div>
          <div className='mt-8 '>
            <div className='flex flex-row'>
              <input
                type='email'
                placeholder='Your email address'
                className='py-4 px-3 font-inter-regular text-base lg:w-1/3 rounded-l-md flex-grow'
                {...register('emailAddress', {
                  required: 'Please enter email address',
                  ...EMAIL_VALIDATIONS,
                })}
              />
              <button
                className='px-8 py-4 bg-primary-graphite rounded-r-md'
                onClick={handleSubmit(onSubmit)}
              >
                <span className='font-inter-regular text-white text-base'>
                  Continue
                </span>
              </button>
            </div>
            {!!errors.emailAddress?.message && (
              <div className='mt-2'>
                <FieldErrorMessage
                  message={errors.emailAddress.message as string}
                />
              </div>
            )}
          </div>
          <div className='mt-4'>
            <p className='font-inter-regular text-sm text-white'>
              Already have an account?
              <span
                onClick={() => navigate('/login')}
                className='text-[#00FBF0] font-normal text-sm cursor-pointer ml-1'
              >
                Sign in
              </span>
            </p>
          </div>
        </div>
        <div className='flex flex-row justify-end ml-5'>
          <img src={LOBanner} alt='logo' className='object-contain' />
        </div>
      </div>
    </div>
  );
};
export default LoanAdvisorRoute;
