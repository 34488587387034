import React, { useEffect } from 'react';
import AnalyticsService from '../../services/AnalyticsService';
import { AnalyticsEventEnum } from '../../types';

interface AnalyticsEventOnLoadProps {
  eventName: AnalyticsEventEnum;
  eventData?: Record<string, unknown>;
}

const AnalyticsEventOnLoad: React.FC<AnalyticsEventOnLoadProps> = ({
  eventName,
  eventData = {},
}) => {
  useEffect(() => {
    AnalyticsService.instance().logEvent(eventName, eventData);
    // eslint-disable-next-line
  }, []);

  return null;
};

export default AnalyticsEventOnLoad;
