import { useCallback } from 'react';

const useScroll = () => {
  const scrollToTop = useCallback((top: number = 0) => {
    window.scrollTo({
      top: top,
      behavior: 'smooth',
    });
  }, []);

  return { scrollToTop };
};

export default useScroll;
